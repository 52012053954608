import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./schduleManagement.Reponsive.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SchduleManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const formRef = useRef();
  const params = PagesIndex.useParams();
  const { page } = params;
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [selectedScheduleData, setSelectedScheduleData] = useState();
  const [loading, setLoading] = useState(true);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const initialValues = {
    slotType: id ? selectedScheduleData?.slotId?.type : page,
    slotId: id ? selectedScheduleData?.slotId?._id : "",
    startDate: id ? PagesIndex.dayjs(selectedScheduleData?.startDate) : null,
    endDate: id ? PagesIndex.dayjs(selectedScheduleData?.endDate) : null,
  };
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [slotData, setSlotData] = useState([]);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const handleGetSchedule = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SCHEDULES).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data?.filter((data) => data?.slotId?.type === page));
        setFilteredData(
          res.data?.filter((data) => data?.slotId?.type === page)
        );
      }
    });
  }, [page]);
  const handleGetSlots = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SLOTS).then((res) => {
      if (res.status === 200) {
        const activeSlots = res?.data?.filter((slot) => slot?.isActive);
        setSlotData(activeSlots);
      }
    });
  }, []);
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    urlEncoded.append("type", page);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ISACTIVE_SCHEDULE,
      urlEncoded
    ).then((res) => {
      setStatusButtonSpinner(false);
      if (res.status === 200) {
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
        handleGetSchedule();
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    handleGetSlots();
    handleGetSchedule();
  }, [handleGetSchedule]);
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectedScheduleData(data);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleAddEditSchedule = (values) => {
    setButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("slotId", values?.slotId);
    urlEncoded.append("type", values?.slotType);
    urlEncoded.append(
      "startDate",
      PagesIndex.moment(new Date(values?.startDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append(
      "endDate",
      PagesIndex.moment(new Date(values?.endDate)).format("YYYY-MM-DD")
    );

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_SCHEDULE, urlEncoded)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetSchedule();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteSchedule = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_SCHEDULE, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetSchedule();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        `${data?.slotId?.slotNo} ${data?.slotId?.slotName}`
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.slotId?.slotNo
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.slotId?.slotName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        `${PagesIndex.moment(data?.startDate).format(
          "DD/MM/YYYY"
        )} ${PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}`
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(data?.startDate)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(data?.endDate)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.updatedBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                <span className="news-id-text">{data?.slotId?.slotNo}</span>
                {data?.slotId?.slotName}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${PagesIndex.moment(data?.startDate).format(
                  "DD/MM/YYYY"
                )} to ${PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {PagesIndex.moment(data?.startDate).format("DD/MM/YYYY")} to{" "}
                  {PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${data?.updatedBy?.name} - ${PagesIndex.moment(
                  data?.updatedAt
                ).format("DD/MM/YYYY hh:mm A")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.createdBy?.name} -{" "}
                  {PagesIndex.moment(data?.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${data?.updatedBy?.name} - ${PagesIndex.moment(
                  data?.updatedAt
                ).format("DD/MM/YYYY hh:mm A")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.updatedBy?.name} -{" "}
                  {PagesIndex.moment(data?.updatedAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            {adminDetails?.roleId?.permissions?.includes(
              "slot_schedule_edit"
            ) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-switch-main">
                  <Index.FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    className="admin-switch-lable"
                    checked={data?.isActive}
                    disabled={data?.isActive}
                    onChange={() => {
                      handleOpenStatusModal(data?._id);
                    }}
                  />
                </Index.Box>
              </Index.TableCell>
            )}
            {(adminDetails?.roleId?.permissions?.includes(
              "slot_schedule_edit"
            ) ||
              adminDetails?.roleId?.permissions?.includes(
                "slot_schedule_delete"
              )) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  {adminDetails?.roleId?.permissions?.includes(
                    "slot_schedule_edit"
                  ) && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          handleOpen("Edit", data?._id, data);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                  <Index.Tooltip
                    title="Manage Shedule"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() =>
                        navigate(
                          `/admin/add-slot-schedule/${page}/${data?._id}`,
                          {
                            state: data,
                          }
                        )
                      }
                    >
                      <img
                        src={PagesIndex.Svg.CalenderColorIcon}
                        className="admin-icon"
                        alt="Manage Shedule"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                  <Index.Tooltip
                    title="View"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        navigate(
                          `/admin/view-slot-schedule/${page}/${data?._id}`,
                          {
                            state: data,
                          }
                        );
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.yelloweye}
                        className="admin-icon"
                        alt="View"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                  {adminDetails?.roleId?.permissions?.includes(
                    "slot_schedule_delete"
                  ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            {page === "FCT" ? "FCT" : "Non FCT"} Schedule List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {adminDetails?.roleId?.permissions?.includes(
              "slot_schedule_add"
            ) && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      setId("");
                      handleOpen("Add");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Schedule</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box
            className={`admin-distric-table-main ${
              loading ? "" : "page-table-main"
            }`}
          >
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Slot
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Schedule Dates
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Created By
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Updated By
                      </Index.TableCell>
                      {adminDetails?.roleId?.permissions?.includes(
                        "slot_schedule_edit"
                      ) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="6%"
                          align="right"
                        >
                          Status
                        </Index.TableCell>
                      )}
                      {(adminDetails?.roleId?.permissions?.includes(
                        "slot_schedule_edit"
                      ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "slot_schedule_delete"
                        )) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                          align="right"
                        >
                          Action
                        </Index.TableCell>
                      )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {renderContent()}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditSchedule}
          initialValues={initialValues}
          validationSchema={PagesIndex.scheduleAddEditSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Schedule
                  </Index.Typography>
                </Index.Box>

                <Index.Box
                  component={"form"}
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Slot Type<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="admin-dropdown-box">
                        <Index.FormControl className="admin-form-control">
                          <Index.Select
                            name="slotType"
                            className="admin-dropdown-select "
                            value={values?.slotType}
                            disabled
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("slotId", "");
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={
                              values?.slotType
                                ? undefined
                                : () => "Select Slot type"
                            }
                          >
                            <Index.MenuItem
                              value="FCT"
                              className="admin-menuitem"
                            >
                              FCT
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="NonFCT"
                              className="admin-menuitem"
                            >
                              Non FCT
                            </Index.MenuItem>
                            {/* <Index.MenuItem
                                  value="Program"
                                  className="admin-menuitem"
                                  disabled
                                >
                                  Program
                                </Index.MenuItem> */}
                          </Index.Select>
                        </Index.FormControl>
                        {touched.slotType && errors.slotType && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.slotType}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Slot<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={
                          slotData.filter(
                            (data) => data?.type === values?.slotType
                          ) || []
                        }
                        id="slotId"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.slotId
                            ? slotData?.find((o) => o._id === values?.slotId)
                            : values?.slotId || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("slotId", value?._id);
                        }}
                        getOptionLabel={(option) =>
                          `${option?.slotNo} - ${option?.slotName}`
                        }
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.slotNo} - {item?.slotName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="slotId"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Slot"
                          />
                        )}
                      />
                      {touched.slotId && errors.slotId && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.slotId}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-date-input-main ">
                    <Index.FormHelperText className="admin-form-lable">
                      <span>Start Date</span>
                      <span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <PagesIndex.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <PagesIndex.DatePicker
                          open={openCalenderStart}
                          onClose={() => setOpenCalenderStart(false)}
                          className="admin-form-control admin-date-picker-control"
                          value={values.startDate} // Use '01/01/2000' as the default value
                          onChange={(date) => {
                            setFieldValue("startDate", date);
                            setFieldValue("endDate", null);
                          }}
                          minDate={PagesIndex.dayjs().add(0, "days")}
                          slotProps={{
                            popper: {
                              className: "date-picker-body",
                            },
                            textField: {
                              readOnly: true,
                              onClick: () => setOpenCalenderStart(true),
                            },
                            inputAdornment: {
                              onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                            },
                          }}
                          format="DD/MM/YYYY"
                        />
                      </PagesIndex.LocalizationProvider>
                      <Index.FormHelperText className="admin-error-text">
                        {errors.startDate &&
                          touched.startDate &&
                          errors.startDate}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-date-input-main ">
                    <Index.FormHelperText className="admin-form-lable">
                      <span>End Date</span>
                      <span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <PagesIndex.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <PagesIndex.DatePicker
                          open={openCalenderEnd}
                          onClose={() => setOpenCalenderEnd(false)}
                          className="admin-form-control admin-date-picker-control"
                          value={values.endDate} // Use '01/01/2000' as the default value
                          onChange={(date) => {
                            setFieldValue("endDate", date);
                          }}
                          minDate={
                            values.startDate
                              ? PagesIndex.dayjs(values.startDate).add(0, "day")
                              : PagesIndex.dayjs().add(0, "day")
                          }
                          slotProps={{
                            popper: {
                              className: "date-picker-body",
                            },
                            textField: {
                              readOnly: true,
                              onClick: () => setOpenCalenderEnd(true),
                            },
                            inputAdornment: {
                              onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                            },
                          }}
                          format="DD/MM/YYYY"
                        />
                      </PagesIndex.LocalizationProvider>
                      <Index.FormHelperText className="admin-error-text">
                        {errors.endDate && touched.endDate && errors.endDate}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="border-btn-main">
                      <Index.Button
                        className="border-btn"
                        onClick={() => handleClose()}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className={`${
                          id ? "update-btn" : "save-btn"
                        } admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this schedule? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteSchedule}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"schedule"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./advertiser.css";
import dayjs from "dayjs";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TableData = ({
  filteredData,
  handleOpenDelete,
  handleChangeStatusAd,
}) => {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  return filteredData?.length ? (
    filteredData?.map((data, index) => {
      return (
        <Index.TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          key={data?._id}
        >
          <Index.TableCell
            component="td"
            variant="td"
            className="table-td"
            onClick={() => {
              navigate(`/admin/view-client/${data?._id}`);
            }}
          >
            <Index.Tooltip
              title={data?.compName}
              arrow
              placement="top"
              className="admin-tooltip"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                <span className="news-id-text"> {data?.advNo}</span>{" "}
                {data?.compName}
              </Index.Typography>
            </Index.Tooltip>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Tooltip
              title={
                data?.firstName && data?.lastName
                  ? `${data?.firstName} ${data?.lastName}`
                  : "-"
              }
              arrow
              placement="top"
              className="admin-tooltip"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.firstName && data?.lastName
                  ? `${data?.firstName} ${data?.lastName}`
                  : "-"}
              </Index.Typography>
            </Index.Tooltip>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.contact || "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.email || "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              <Index.Tooltip
                title={data?.category?.categoryName}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                {data?.category?.categoryName || "-"}
              </Index.Tooltip>
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              <Index.Tooltip
                title={data?.subCategory?.subCategoryName}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                {data?.subCategory?.subCategoryName || "-"}
              </Index.Tooltip>
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {PagesIndex.moment(data?.createdAt)?.format("DD/MM/YYYY hh:mm A")}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell
            component="td"
            variant="td"
            className="table-td"
            align="right"
          >
            <Index.Tooltip
              title={data?.isActive ? "Active" : "Deactive"}
              arrow
              placement="bottom"
              className="admin-tooltip"
            >
              <Index.Box className="admin-switch-main">
                <Index.FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  className="admin-switch-lable"
                  checked={data?.isActive}
                  onChange={() => handleChangeStatusAd(data?._id)}
                />
              </Index.Box>
            </Index.Tooltip>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Box className="admin-table-data-btn-flex ">
              {adminDetails?.roleId?.permissions?.includes("clients_edit") && (
                <Index.Tooltip
                  title="Edit"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => {
                      navigate(`/admin/edit-client/${data?._id}`, {
                        state: {
                          data: data,
                        },
                      });
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.blueedit}
                      className="admin-icon"
                      alt="Edit"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              )}
              <Index.Tooltip
                title="View"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() => {
                    navigate(`/admin/view-client/${data?._id}`);
                  }}
                >
                  <img
                    src={PagesIndex.Svg.yelloweye}
                    className="admin-icon"
                    alt="View"
                  ></img>
                </Index.Button>
              </Index.Tooltip>
              {adminDetails?.roleId?.permissions?.includes(
                "clients_delete"
              ) && (
                <Index.Tooltip
                  title="Delete"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenDelete(data?._id)}
                  >
                    <img
                      src={PagesIndex.Svg.trash}
                      className="admin-icon"
                      alt="Trash"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              )}
            </Index.Box>
          </Index.TableCell>
        </Index.TableRow>
      );
    })
  ) : (
    <PagesIndex.DataNotFound />
  );
};

export default function Advertiser() {
  const formRef = useRef();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const initialValues = {
    startDate: null,
    endDate: null,
    status: "",
    category: null,
    subCategory: null,
    isSubmit: "",
  };
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [prerequisiteData, setPrerequisiteData] = useState({});
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };

  useEffect(() => {
    handleGetAd();
  }, [searchValue, currentPage]);

  useEffect(() => {
    if (formRef?.current?.values?.isSubmit == true) {
      setCurrentPage(1);
    }
  }, [formRef?.current?.values]);
  const handleGetAd = () => {
    const urlEncoded = new URLSearchParams();
    setLoading(true);
    urlEncoded.append("search", searchValue);
    if (formRef?.current?.values?.isSubmit == true) {
      if (formRef?.current?.values?.startDate) {
        urlEncoded.append(
          "from",
          PagesIndex.moment(
            new Date(formRef?.current?.values?.startDate)
          ).format("YYYY-MM-DD")
        );
      }
      if (formRef?.current?.values?.endDate) {
        urlEncoded.append(
          "to",
          PagesIndex.moment(new Date(formRef?.current?.values?.endDate)).format(
            "YYYY-MM-DD"
          )
        );
      }
      if (formRef?.current?.values?.status) {
        urlEncoded.append("status", formRef?.current?.values?.status);
      }
      if (formRef?.current?.values?.category) {
        urlEncoded.append("category", formRef?.current?.values?.category);
      }
      if (formRef?.current?.values?.subCategory) {
        urlEncoded.append("subCategory", formRef?.current?.values?.subCategory);
      }
    }
    urlEncoded.append("pageNumber", currentPage);
    urlEncoded.append("pageSize", 10);
    PagesIndex.apiPostHandler(PagesIndex.Api.GET_ADVERTISER, urlEncoded).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setData(res?.data);
          setFilteredData(res?.data?.advertisers);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.ISACTIVE_ADVERTISER + "/" + id
    ).then((res) => {
      setStatusButtonSpinner(false);
      if (res.status === 200) {
        handleGetAd();
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  // delete modal
  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleDeleteAd = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.DELETE_ADVERTISER,
      urlEncoded
    ).then((res) => {
      setDeleteButtonSpinner(false);
      if (res.status === 200) {
        handleCloseDelete();
        handleGetAd();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers = [
      "Sr. No.",
      "Client No.",
      "Account Director",
      "Group Head",
      "Manager",
      "Company Name",
      "Address 1",
      "Address 2",
      "State",
      "Zone",
      "City",
      "Brand Name",
      "Brand Slogan/Tagline",
      "Brand Ambassador",
      "Category",
      "Sub Category",
      "Normal Advertising Period",
      "Media Platform Use",
      "First Name",
      "Last Name",
      "Designation",
      "Agency Name",
      "Contact Number",
      "Email Id",
      "FCT",
      "NFCT",
      "Favourite Celebrities",
      "Passions",
      "Social Causes",
      "Impact Or Show Partnership",
      "Family Details",
    ];

    let modifiedData = filteredData?.map((data, index) => {
      const names = data?.mediaPlatform?.length
        ? data?.mediaPlatform?.map((item) => item?.name)
        : "";
      const mediaPlatform = names ? `${names?.join(", ")}` : "";
      let objData = [
        index + 1,
        data?.advNo ? data?.advNo?.replaceAll(",", "") : "-",
        data?.accDirector?.name
          ? data?.accDirector?.name?.replaceAll(",", "|")
          : "-",
        data?.grpHead?.name ? data?.grpHead?.name?.replaceAll(",", "|") : "-",
        data?.manager?.name ? data?.manager?.name?.replaceAll(",", "|") : "-",
        data?.compName ? data?.compName?.replaceAll(",", "|") : "-",
        data?.add1 ? data?.add1?.replaceAll(",", "|") : "-",
        data?.add2 ? data?.add2?.replaceAll(",", "|") : "-",
        data?.state?.stateName
          ? data?.state?.stateName?.replaceAll(",", "|")
          : "-",
        data?.zone?.name ? data?.zone?.name?.replaceAll(",", "|") : "-",
        data?.city ? data?.city?.replaceAll(",", "|") : "-",
        data?.brand ? data?.brand?.replaceAll(",", "|") : "-",
        data?.brandSlogan ? data?.brandSlogan?.replaceAll(",", "|") : "-",
        data?.brandAmbassador
          ? data?.brandAmbassador?.replaceAll(",", "|")
          : "-",
        data?.category?.name ? data?.category?.name?.replaceAll(",", "|") : "-",
        data?.subCategory?.name
          ? data?.subCategory?.name?.replaceAll(",", "|")
          : "-",
        data?.advPeriod?.length
          ? data?.advPeriod.toString()?.replaceAll(",", "|")
          : "-",
        mediaPlatform ? mediaPlatform.toString()?.replaceAll(",", "|") : "-",
        data?.firstName ? data?.firstName?.replaceAll(",", "|") : "-",
        data?.lastName ? data?.lastName?.replaceAll(",", "|") : "-",
        data?.designation ? data?.designation?.replaceAll(",", "|") : "-",
        data?.agency ? data?.agency?.replaceAll(",", "|") : "-",
        data?.contact ? data?.contact?.replaceAll(",", "|") : "-",
        data?.email ? data?.email?.replaceAll(",", "|") : "-",
        data?.fct ? JSON.stringify(data?.fct) : "-",
        data?.nfct ? JSON.stringify(data?.nfct) : "-",
        data?.celebrities
          ? data?.celebrities.toString()?.replaceAll(",", "|")
          : "-",
        data?.passionate
          ? data?.passionate.toString()?.replaceAll(",", "|")
          : "-",
        data?.socialCause
          ? data?.socialCause.toString()?.replaceAll(",", "|")
          : "-",
        typeof data?.partenership === "string"
          ? data?.partenership?.replaceAll(",", "|")
          : "-",
        typeof data?.familyDetails === "string"
          ? data?.familyDetails?.replaceAll(",", "|")
          : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [headers.join(",") + "\n" + modifiedData.join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_Clients_list.csv`,
      "text/csv"
    );
  };

  const handlePrerequisiteData = useCallback(() => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_ADVERTISER_PREREQUISITE_DATA
    ).then((res) => {
      if (res.status === 200) {
        setPrerequisiteData(res?.data);
      }
    });
  }, []);
  useEffect(() => {
    handlePrerequisiteData();
  }, [handlePrerequisiteData]);
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Clients List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setSearchValue(e.target.value);
                    }}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-userlist-inner-btn-flex">
              {adminDetails?.roleId?.permissions?.includes("clients_add") && (
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      navigate("/admin/add-client");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Client</span>
                  </Index.Button>
                </Index.Box>
              )}
              {filteredData?.length > 0 && (
                <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                  <Index.Button
                    className="admin-adduser-btn btn-secondary"
                    onClick={generateCSV}
                  >
                    <img
                      src={PagesIndex.Svg.exporticon}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Export CSV</span>
                  </Index.Button>
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="filter-card common-card">
          <Index.Box className="filter-flex">
            <Index.Box className="grid-row">
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleGetAd}
                initialValues={initialValues}
                // validationSchema={PagesIndex.advertiserFilterSchema}
                innerRef={formRef}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} noValidate="novalidate">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>From Date</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <PagesIndex.LocalizationProvider
                                dateAdapter={PagesIndex.AdapterDayjs}
                                className="date-localization-main"
                              >
                                <PagesIndex.DatePicker
                                  open={openCalenderStart}
                                  onClose={() => setOpenCalenderStart(false)}
                                  className="admin-form-control admin-date-picker-control"
                                  value={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                    setFieldValue("endDate", null);
                                  }}
                                  slotProps={{
                                    popper: {
                                      className: "date-picker-body",
                                    },
                                    textField: {
                                      readOnly: true,
                                      onClick: () => setOpenCalenderStart(true),
                                    },
                                    inputAdornment: {
                                      onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                />
                              </PagesIndex.LocalizationProvider>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.startDate && touched.startDate
                                ? errors.startDate
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>To Date</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <PagesIndex.LocalizationProvider
                                dateAdapter={PagesIndex.AdapterDayjs}
                                className="date-localization-main"
                              >
                                <PagesIndex.DatePicker
                                  open={openCalenderEnd}
                                  onClose={() => setOpenCalenderEnd(false)}
                                  className="admin-form-control admin-date-picker-control"
                                  value={values.endDate}
                                  minDate={dayjs(values.startDate).add(
                                    0,
                                    "day"
                                  )}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  slotProps={{
                                    popper: {
                                      className: "date-picker-body",
                                    },
                                    textField: {
                                      readOnly: true,
                                      onClick: () => setOpenCalenderEnd(true),
                                    },
                                    inputAdornment: {
                                      onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                />
                              </PagesIndex.LocalizationProvider>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.endDate && touched.endDate
                                ? errors.endDate
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input ">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>Status</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.Box className="admin-dropdown-box">
                                <Index.FormControl className="admin-form-control">
                                  {/* <Index.Select
                                        name="status"
                                        className="admin-dropdown-select "
                                        value={values?.status}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        renderValue={
                                          values?.status
                                            ? undefined
                                            : () => "Select Status"
                                        }
                                      >
                                        <Index.MenuItem
                                          value={"active"}
                                          className="admin-menuitem"
                                        >
                                          Active
                                        </Index.MenuItem>
                                        <Index.MenuItem
                                          value={"deactive"}
                                          className="admin-menuitem"
                                        >
                                          Deactive
                                        </Index.MenuItem>
                                      </Index.Select> */}

                                  <Index.Select
                                    className="admin-dropdown-select admin-drop-select"
                                    id="status"
                                    name="status"
                                    value={values?.status}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    renderValue={
                                      values?.status
                                        ? undefined
                                        : () => "Select Status"
                                    }
                                  >
                                    <Index.MenuItem
                                      value="active"
                                      className="admin-drop-menuitem"
                                    >
                                      Active
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={"deactive"}
                                      className="admin-drop-menuitem"
                                    >
                                      Deactive
                                    </Index.MenuItem>
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.status && touched.status
                                ? errors.status
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 3",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>Category</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed admin-dropdown-select admin-check-dropdown-select"
                                  options={prerequisiteData?.category || []}
                                  name="category"
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  disableClearable
                                  value={
                                    values?.category &&
                                    prerequisiteData?.category &&
                                    prerequisiteData?.category?.find(
                                      (o) => o._id === values?.category
                                    )
                                      ? prerequisiteData?.category?.find(
                                          (o) => o._id === values?.category
                                        )
                                      : values?.category
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("category", value?._id);
                                    setFieldValue("subCategory", null);
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.categoryName
                                  }
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.categoryName}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      placeholder={"Enter Category"}
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.category && touched.category
                                ? errors.category
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 3",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>Sub Category</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed admin-dropdown-select admin-check-dropdown-select"
                                  options={
                                    prerequisiteData?.subCategory?.filter(
                                      (data) =>
                                        data?.categoryId === values?.category
                                    ) || []
                                  }
                                  name="subCategory"
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  disableClearable
                                  value={
                                    values?.subCategory &&
                                    prerequisiteData?.subCategory &&
                                    prerequisiteData?.subCategory?.find(
                                      (o) => o._id === values?.subCategory
                                    )
                                      ? prerequisiteData?.subCategory?.find(
                                          (o) => o._id === values?.subCategory
                                        )
                                      : values?.subCategory
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("subCategory", value?._id);
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.subCategoryName
                                  }
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.subCategoryName}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      placeholder={"Enter Sub Category"}
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.subCategory && touched.subCategory
                                ? errors.subCategory
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        className="filter-row"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="filter-btns">
                            <Index.Tooltip
                              title="Search"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  type="submit"
                                  disabled={
                                    (formRef?.current?.values?.category ||
                                      formRef?.current?.values?.endDate ||
                                      formRef?.current?.values?.startDate ||
                                      formRef?.current?.values?.status ||
                                      formRef?.current?.values?.subCategory) ==
                                    null
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    setFieldValue("isSubmit", true)
                                  }
                                >
                                  <img
                                    src={PagesIndex.Svg.searchwhite}
                                    className="admin-icons"
                                    alt="Save"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                            <Index.Tooltip
                              title="Reset"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  onClick={() => {
                                    formRef?.current?.resetForm();
                                    setCurrentPage(1);
                                    setTimeout(() => {
                                      handleGetAd();
                                    }, 1000);
                                  }}
                                >
                                  <img
                                    src={PagesIndex.Svg.reset}
                                    className="admin-icons"
                                    alt="Reset"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className={`admin-advertiser-table-main page-table-main`}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Company Name
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Name
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="8%"
                      >
                        Contact No.
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Email Id
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="14%"
                      >
                        Category
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Sub Category
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Created Date & Time
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                        align="right"
                      >
                        Status
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                        align="right"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <TableData
                        adDataList={data}
                        filteredData={filteredData}
                        currentPage={currentPage}
                        handleOpenDelete={handleOpenDelete}
                        handleChangeStatusAd={handleOpenStatusModal}
                      />
                    )}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {data.pageCount ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(data.pageCount / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this client? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteAd}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"client"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation, useParams } from "react-router-dom";
import { FieldArray, getIn } from "formik";
import { SchedulingModalInner } from "./SchedulingModalInner";
import { SchedulingModalInnerView } from "./SchedulingModalInnerView";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function AddSalesSubOrder() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  const formRef = useRef();
  const formRefOrder = useRef();
  const params = useParams();
  const location = useLocation();
  const { id } = params;
  const orderList = location?.state?.orderList;
  const navigate = PagesIndex.useNavigate();
  const [data, setData] = useState([...orderList.orderDetails]);
  const [docData, setDocData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [prevBookingsData, setPrevBookingsData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [scheduleData, setScheduleData] = useState([]);
  const [creativeList, setCreativeList] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [weekArray, setWeekArray] = useState([]);

  const totalAmount = data?.reduce((total, item) => {
    if (item?.unit == "seconds") {
      return (
        total + parseFloat((item?.units * item?.discountrate) / 10 || 0, 10)
      );
    } else {
      return total + parseFloat(item?.units * item?.discountrate || 0, 10);
    }
  }, 0);
  useEffect(() => {
    handleGetSalesOrder();
    handleGetSchedule();
    handleGetCreative();
    handleGetProgram();
  }, []);
  const handleGetSchedule = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SCHEDULES).then((res) => {
      if (res.status === 200) {
        const activeSlots = res?.data?.filter((slot) => slot?.isActive);
        setScheduleData(activeSlots);
      }
    });
  };
  const handleGetCreative = () => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_CREATIVES_BY_CLIENT +
        "/" +
        location?.state?.orderList?.client?._id
    ).then((res) => {
      if (res?.status === 200) {
        setCreativeList(
          res?.data?.getCreative?.filter(
            (data) => data?.isActive && !data?.isDeleted
          )
        );
      }
    });
  };
  const handleGetProgram = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM).then((res) => {
      if (res.status === 200) {
        const program = res?.data?.filter((program) => program?.isActive);
        setProgramData(program);
      }
    });
  };
  const handleOpenUploadDocument = () => {
    setOpenUploadDocument(true);
  };
  const handleCloseUploadDocument = () => {
    formRef.current.resetForm();
    setOpenUploadDocument(false);
  };

  const renderImage = (values) => {
    const isUploadImg = values.image;
    const isImageBlob = values.image instanceof Blob;
    let src;
    let image = values.image.type?.includes("image");
    let video = values.image.type?.includes("video");
    let xmlimage = values.image.type?.includes("xml");
    if (image == false && video == false) {
      src = PagesIndex.Svg.pdfIcon;
    } else if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else {
      src = PagesIndex.Svg.fileupload;
    }
    return (
      <img
        className={
          image && !xmlimage && isUploadImg
            ? "upload-img"
            : "upload-profile-img"
        }
        src={src}
        alt="document"
      />
    );
  };

  const clientInitialValues = {
    name: orderList?.subOrder?.name,
    startDate: orderList?.subOrder?.startDate
      ? PagesIndex.dayjs(orderList?.subOrder?.startDate)
      : null,
    endDate: orderList?.subOrder?.endDate
      ? PagesIndex.dayjs(orderList?.subOrder?.endDate)
      : null,
  };

  const initialValuesDocumentUpload = {
    name: "",
    image: "",
  };
  const initialValuesOrder = {
    scheduleDetails: selectedData?.slotAddData?.length
      ? selectedData?.slotAddData
      : [
          {
            type: selectedData?.type ? selectedData?.type : "dateRange",
            startDate: formRef?.current?.values?.startDate,
            endDate: formRef?.current?.values?.endDate,
            scheduleType: "program",
            creative: undefined,
            creativeDuration: 1,
            selectedDates: [],
            program: undefined,
            scheduleId: selectedData?.schedule,
            fromTime: null,
            toTime: null,
            slots: [],
            weekDays: weekArray?.length ? weekArray : [],
          },
        ],
  };
  const handleDownloadVideo = async (data) => {
    const a = document.createElement("a");
    if (data?.file?.name) {
      const file = URL.createObjectURL(data?.file);
      a.setAttribute("download", data?.file?.name); // Replace with the desired filename for the downloaded file
      a.href = file;
      a.click();
      window.URL.revokeObjectURL(file);
    } else {
      a.setAttribute("download", data?.documentsName); // Replace with the desired filename for the downloaded file
      a.href = process.env.REACT_APP_IMAGE_ENDPOINT + data?.file;
      a.click();
      window.URL.revokeObjectURL(
        process.env.REACT_APP_IMAGE_ENDPOINT + data?.file
      );
    }
  };
  const handleImageDelete = (indexId) => {
    const updatedData = docData.filter((item, index) => index !== indexId);
    setDocData(updatedData);
  };
  const handleAddDocument = (values) => {
    // Add new item
    let obj = {
      name: values?.name,
      file: values?.image,
    };
    setDocData((prev) => [...prev, obj]);
    handleCloseUploadDocument();
  };
  const handleAddData = (values) => {
    setButtonSpinner(true);
    const formData = new FormData();
    formData.append("orderId", id);
    formData.append("orderNo", orderList?.orderNo);
    formData.append("name", values?.name);
    formData.append(
      "startDate",
      PagesIndex.dayjs(values?.startDate).format("YYYY-MM-DD")
    );
    formData.append(
      "endDate",
      PagesIndex.dayjs(values?.endDate).format("YYYY-MM-DD")
    );
    formData.append("totalAmount", totalAmount);
    formData.append(
      "orderDetails",
      JSON.stringify(
        data.map((data) => {
          return { ...data };
        })
      )
    );
    docData?.forEach((data) => {
      formData.append(data?.name, data?.file);
    });

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_BOOKING, formData)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          navigate("/admin/sales-order-list");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleGetSalesOrder = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_BOOKING + "/" + id).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setPrevBookingsData(res.data);
        }
      }
    );
  };
  const [openScheduleView, setOpenScheduleView] = useState(false);
  const handleOpenScheduleView = (data, index) => {
    setOpenScheduleView(true);
    setSelectedData(data);
    setActiveIndex(index);
  };
  const handleCloseScheduleView = () => {
    setOpenScheduleView(false);
    setSelectedData({});
    setActiveIndex(0);
  };
  // add message modal
  const [openSchedule, setOpenSchedule] = useState(false);
  const handleOpenSchedule = (data, index) => {
    setOpenSchedule(true);
    setSelectedData(data);
  };
  const handleCloseSchedule = () => {
    setOpenSchedule(false);
    setSelectedData({});
  };
  const handleAddModalData = (values) => {
    const weekData = values?.scheduleDetails.map((data) =>
      data?.type === "dateRange"
        ? data?.weekDays?.reduce((acc, curr) => acc + curr?.total, 0) *
          data?.creativeDuration
        : data?.selectedDates?.reduce(
            (acc, curr) => acc + parseInt(curr?.value),
            0
          ) * data?.creativeDuration
    );
    const counts = weekData?.reduce((acc, curr) => acc + curr, 0);
    if (counts > selectedData?.remainingUnits) {
      PagesIndex.toasterError(
        "Value of units exceeds amount of remaining units."
      );
    } else {
      setData((prev) => {
        const data = [...prev];
        data[activeIndex].remainingUnits =
          data[activeIndex]?.remainingUnits - counts;
        data[activeIndex].units = counts;
        data[activeIndex].slotAddData = values?.scheduleDetails;
        return data;
      });
      handleCloseSchedule();
    }
  };
  const setWeekDays = (selectedStartDate, selectedEndDate) => {
    var date = PagesIndex.moment(new Date(selectedStartDate));
    var start = PagesIndex.moment(date).startOf("week");
    var endDate = PagesIndex.moment(new Date(selectedEndDate));
    var end = PagesIndex.moment(endDate).endOf("week");
    var weekArray = [];
    for (var j = 0; j < Math.ceil(end.diff(date, "days") / 7); j) {
      weekArray.push({
        dates: [],
        name: `Week ${j + 1}`,
        total: 0,
      });
      for (var k = 0; k < 7; k++) {
        weekArray[j].dates.push({
          fullDate: start.format("DD-MM-YYYY"),
          date: start.format("DD MMM"),
          value: 0,
          disabled: !(start >= selectedStartDate && start <= selectedEndDate),
        });
        start.add("days", 1);
        if (k == 6) {
          j++;
        }
      }
    }
    if (selectedStartDate && selectedEndDate) {
      setWeekArray(weekArray);
    } else {
      setWeekArray([]);
    }
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Add Booking
        </Index.Typography>
      </Index.Box>

      <>
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddData}
          initialValues={clientInitialValues}
          validationSchema={PagesIndex.salesSubOrder}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box className="common-card add-sales-card">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 3",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-event-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Booking Name
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="name"
                          fullWidth
                          id="name"
                          className="admin-form-control"
                          placeholder="Enter Booking Name"
                          onChange={handleChange}
                          value={values?.name}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Index.Box>
                      {errors.name && touched.name && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.name}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 3",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>Start Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderStart}
                            onClose={() => setOpenCalenderStart(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.startDate} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldTouched("startDate", false);
                              setFieldValue("startDate", date);
                              setFieldValue("endDate", null);
                              setFieldValue("selectedDates", []);
                              setWeekDays(date, values?.endDate);
                            }}
                            minDate={PagesIndex.dayjs(orderList?.startDate)}
                            maxDate={PagesIndex.dayjs(orderList?.endDate)}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderStart(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 3",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>End Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderEnd}
                            onClose={() => setOpenCalenderEnd(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.endDate} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("endDate", date);
                              setWeekDays(values?.startDate, date);
                            }}
                            minDate={
                              values.startDate
                                ? PagesIndex.dayjs(values.startDate).add(
                                    0,
                                    "day"
                                  )
                                : PagesIndex.dayjs().add(0, "day")
                            }
                            maxDate={PagesIndex.dayjs(orderList?.endDate)}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderEnd(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.endDate && touched.endDate && errors.endDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="other-flex">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Order Details
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="common-card">
                  <Index.Box
                    className={`admin-client-table-main page-table-main`}
                  >
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      {loading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="2%"
                              >
                                Sr. No.
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Platform
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Element
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Total
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Selling Rate
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Remaining Units
                              </Index.TableCell>

                              {/* <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Amount
                              </Index.TableCell> */}

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="5%"
                              >
                                Schedule
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {loading ? (
                              <PagesIndex.Loader />
                            ) : (
                              <TableData
                                data={[...data]}
                                currentPage={1}
                                setData={setData}
                                totalAmount={totalAmount}
                                handleOpenSchedule={handleOpenSchedule}
                                handleOpenScheduleView={handleOpenScheduleView}
                              />
                            )}

                            {/* <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              ></Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              ></Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              ></Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box className="total-text">
                                  {data?.reduce(
                                    (acc, curr) =>
                                      acc + parseFloat(curr.totalSeconds),
                                    0
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="right"
                              >
                                <Index.Box className="total-text">
                                  Total Ramainig Units:
                                </Index.Box>
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box className="total-text">
                                  {data?.reduce(
                                    (acc, curr) =>
                                      acc + parseFloat(curr.remainingUnits),
                                    0
                                  )}
                                </Index.Box>
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              ></Index.TableCell>
                            </Index.TableRow> */}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>

                  <Index.Box className="upload-document-row">
                    {adminDetails?.roleId?.permissions?.includes(
                      "sales_order_add"
                    ) && (
                      <>
                        <Index.Box className="admin-userlist-inner-btn-flex upload-document-btn-end">
                          <Index.Box className="admin-sub-title-main">
                            <Index.Typography
                              className="admin-sub-title"
                              component="h2"
                              variant="h2"
                            >
                              List of Attachment
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="admin-adduser-btn-main btn-main-primary document-upload-btn-main">
                            <Index.Button
                              className="admin-adduser-btn document-upload-btn"
                              onClick={() => {
                                handleOpenUploadDocument();
                              }}
                            >
                              <img
                                src={PagesIndex.Svg.darkplus}
                                className="admin-plus-icon"
                                alt="plus"
                              />
                              <span>Upload Documents</span>
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                        {docData?.map((url, index) => {
                          return (
                            <Index.Box
                              className="document-data-flex"
                              key={url?.file?.name}
                            >
                              <Index.Box className="document-content-main">
                                <Index.Box className="document-lable-flex">
                                  <Index.Typography className="document-id-text">
                                    {url?.name}
                                  </Index.Typography>
                                  <Index.Typography className="document-lable-text">
                                    {url?.file?.name}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="document-content-main">
                                <Index.Box className="document-delete-main">
                                  <Index.Button
                                    className="admin-table-data-btn"
                                    onClick={() => handleDownloadVideo(url)}
                                  >
                                    <img
                                      src={PagesIndex.Svg.download}
                                      className="admin-icon"
                                      alt="Download"
                                    ></img>
                                  </Index.Button>
                                  <Index.Button
                                    className="document-delete-btn"
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <img
                                      src={PagesIndex.Svg.trash}
                                      className="admin-icon"
                                      alt="Delete"
                                    ></img>
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          );
                        })}
                      </>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-user-btn-flex">
                <Index.Box className="border-btn-main">
                  <Index.Button
                    className="border-btn"
                    onClick={() => navigate("/admin/sales-order-list")}
                    disabled={buttonSpinner}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="btn-main-primary">
                  <Index.Button
                    className="btn-primary"
                    type="submit"
                    disabled={buttonSpinner}
                  >
                    {buttonSpinner ? (
                      <PagesIndex.Spinner />
                    ) : (
                      <>
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Submit"
                        />
                        <span>Add</span>
                      </>
                    )}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              {prevBookingsData?.length ? (
                <>
                  <Index.Box className="other-flex">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h2"
                        variant="h2"
                      >
                        Previous Booking Details
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="common-card">
                    <Index.Box
                      className={`admin-client-table-main page-table-main`}
                    >
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        {loading ? (
                          <PagesIndex.Loader />
                        ) : (
                          prevBookingsData?.map((dataOuter, index) => {
                            return (
                              <>
                                <Index.Box className="name-highlight-box-flex">
                                  <Index.Box className="name-highlight-box">
                                    <Index.Typography className="name-highlight-text">
                                      {dataOuter?.name} -{" "}
                                      <span className="name-highlight-id-text">
                                        {dataOuter?.subOrderNo}
                                      </span>
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="date-box-flex">
                                    <Index.Typography className="date-text">
                                      Start Date:
                                      <span className="date-text-span">
                                        {PagesIndex.moment(
                                          dataOuter?.startDate
                                        ).format("DD/MM/YYYY")}
                                      </span>
                                    </Index.Typography>
                                    <Index.Typography className="date-text">
                                      End Date:
                                      <span className="date-text-span">
                                        {PagesIndex.moment(
                                          dataOuter?.endDate
                                        ).format("DD/MM/YYYY")}
                                      </span>
                                    </Index.Typography>
                                    <Index.Typography className="date-text">
                                      Created Date & Time:
                                      <span className="date-text-span">
                                        {PagesIndex.moment(
                                          dataOuter?.createdAt
                                        ).format("DD/MM/YYYY hh:mm:ss A")}
                                      </span>
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Table
                                  aria-label="simple table"
                                  className="table"
                                  key={data?._id}
                                >
                                  <Index.TableHead className="table-head">
                                    {/* <Index.TableRow className="table-row">
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="10%"
                                      >
                                        <p className="news-id-text">
                                          {dataOuter?.name}
                                        </p>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="10%"
                                      >
                                        <p className="news-id-text">
                                          {dataOuter?.subOrderNo}
                                        </p>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th-trasparent"
                                        width="5%"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th-trasparent"
                                        width="5%"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th-trasparent"
                                        width="5%"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th-trasparent"
                                        width="5%"
                                      ></Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th-trasparent"
                                        width="10%"
                                        align="right"
                                        colSpan={2}
                                      >
                                        <Index.Box className="date-box-flex">
                                          <Index.Typography className="date-text">
                                            Start Date:
                                            <span className="date-text-span">
                                              {PagesIndex.moment(
                                                dataOuter?.startDate
                                              ).format("DD/MM/YYYY")}
                                            </span>
                                          </Index.Typography>
                                          <Index.Typography className="date-text">
                                            End Date:
                                            <span className="date-text-span">
                                              {PagesIndex.moment(
                                                dataOuter?.endDate
                                              ).format("DD/MM/YYYY")}
                                            </span>
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow> */}
                                    <Index.TableRow className="table-row">
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="2%"
                                      >
                                        Sr. No.
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Platform
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Element
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Total
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Selling Rate
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Remaining Units
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Used Units
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        width="5%"
                                      >
                                        Action
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>
                                  <Index.TableBody className="table-body">
                                    {dataOuter?.orderDetails?.map(
                                      (data, index) => {
                                        return (
                                          <Index.TableRow
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                            key={data?._id}
                                          >
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {index + 1}
                                              </Index.Typography>
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {data?.platform
                                                  ? data?.platform
                                                  : "-"}
                                              </Index.Typography>
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {data?.elements
                                                  ? data?.elements
                                                  : "-"}
                                              </Index.Typography>
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {data?.totalSeconds
                                                  ? data?.totalSeconds
                                                  : 0}{" "}
                                                ({data?.unit})
                                              </Index.Typography>
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {" "}
                                                {data?.discountrate
                                                  ? data?.discountrate
                                                  : 0}
                                              </Index.Typography>
                                            </Index.TableCell>

                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {" "}
                                                {parseFloat(
                                                  data?.remainingUnits || 0
                                                )}
                                              </Index.Typography>
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              className="table-td"
                                            >
                                              <Index.Typography className="admin-table-data-text">
                                                {" "}
                                                {parseFloat(data?.units || 0)}
                                              </Index.Typography>
                                            </Index.TableCell>
                                            <Index.TableCell
                                              component="td"
                                              variant="td"
                                              scope="row"
                                              className="table-td"
                                            >
                                              <Index.Box className="input-cus-flex">
                                                <Index.Tooltip
                                                  title={"View"}
                                                  arrow
                                                  placement="bottom"
                                                  className="admin-tooltip"
                                                >
                                                  <Index.Button
                                                    className="schedule-btn border-btn"
                                                    onClick={() =>
                                                      handleOpenScheduleView(
                                                        data,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        PagesIndex.Svg.yelloweye
                                                      }
                                                      className="admin-icons"
                                                      alt="discard"
                                                    />
                                                  </Index.Button>
                                                </Index.Tooltip>
                                              </Index.Box>
                                            </Index.TableCell>
                                          </Index.TableRow>
                                        );
                                      }
                                    )}
                                    {/* <Index.TableRow>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      ></Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      ></Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="right"
                                      >
                                        <Index.Box className="total-text">
                                          Total Amount:
                                        </Index.Box>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Box className="total-text">
                                          {" "}
                                          {parseFloat(
                                            dataOuter?.totalAmount || 0
                                          )?.toLocaleString("en-US")}
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow> */}
                                  </Index.TableBody>
                                </Index.Table>

                                {dataOuter?.document?.length ? (
                                  <Index.Box>
                                    {dataOuter?.document?.map((data) => {
                                      return (
                                        <Index.Box className="document-lable-flex doucument-show-flex">
                                          <Index.Box className="document-download-main">
                                            <Index.Button className="document-download-btn">
                                              <img
                                                src={PagesIndex.Svg.download}
                                                className="admin-icon"
                                                alt="Download"
                                              ></img>
                                            </Index.Button>
                                          </Index.Box>
                                          <Index.Typography className="document-id-text">
                                            {data?.name}
                                          </Index.Typography>
                                          <Index.Typography className="document-lable-text">
                                            {data?.file}
                                          </Index.Typography>
                                        </Index.Box>
                                      );
                                    })}
                                  </Index.Box>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })
                        )}
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </>
              ) : (
                ""
              )}
            </form>
          )}
        </PagesIndex.Formik>

        <Index.Modal
          open={openUploadDocument}
          onClose={handleCloseUploadDocument}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="admin-modal"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddDocument}
            initialValues={initialValuesDocumentUpload}
            validationSchema={PagesIndex.uploadSalesDocumentSchema}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Upload Document
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    className="admin-modal-hgt-scroll cus-scrollbar"
                    id="scrollDiv"
                  >
                    {/* <ScrollIntoView /> */}

                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Document Type<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Box className="admin-dropdown-box">
                          <Index.FormControl className="admin-form-control">
                            <Index.Select
                              name="name"
                              className="admin-dropdown-select "
                              value={values?.name}
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              renderValue={
                                values?.name
                                  ? undefined
                                  : () => "Select Document Type"
                              }
                            >
                              <Index.MenuItem
                                value="ro"
                                className="admin-menuitem"
                              >
                                RO
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="to"
                                className="admin-menuitem"
                              >
                                TO
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="others"
                                className="admin-menuitem"
                              >
                                Other
                              </Index.MenuItem>
                            </Index.Select>
                          </Index.FormControl>
                          {touched.name && errors.name && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.name}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Document<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      {values?.image ? (
                        <Index.Box className="file-upload-main thumbnail-box admin-file-browse-btn-main ">
                          <Index.Box className="upload-news-img-box">
                            {renderImage(values)}
                          </Index.Box>
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="admin-file-browse-btn"
                          >
                            <img
                              src={PagesIndex.Svg.whiteedit}
                              className="admin-browse-icon-img"
                              alt="upload img"
                            ></img>
                            <input
                              hidden
                              accept="image/*, .pdf"
                              id="image"
                              name="image"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue("image", e.target.files[0]);
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      ) : (
                        <Index.Box className="file-upload-main thumbnail-box">
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-button"
                          >
                            {renderImage(values)}
                            <input
                              hidden
                              accept="image/*, .pdf"
                              id="image"
                              name="image"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue("image", e.target.files[0]);
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      )}
                      {touched.image && errors.image && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.image}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="admin-discard-btn-main border-btn-main">
                        <Index.Button
                          className="admin-discard-user-btn border-btn"
                          onClick={handleCloseUploadDocument}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-save-user-btn btn-primary"
                          type="submit"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Upload"
                          />
                          <span>Upload</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Modal>

        <Index.Modal
          open={openSchedule}
          onClose={handleCloseSchedule}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="admin-modal"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddModalData}
            initialValues={initialValuesOrder}
            validationSchema={PagesIndex.salesSubOrderModal}
            innerRef={formRefOrder}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner order-details-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {" "}
                      View Order Details
                    </Index.Typography>
                    <Index.Button
                      className="modal-close-btn"
                      onClick={handleCloseSchedule}
                    >
                      <img
                        src={PagesIndex.Svg.closeblack}
                        className="admin-modal-close-icon"
                        alt="Close"
                      />
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                    <Index.Box className="order-show-box">
                      <Index.Box className="grid-row view-order-row">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="order-inner-box">
                              <Index.Typography className="order-inner-lable-text">
                                Platform Name:{" "}
                              </Index.Typography>
                              <Index.Typography className="order-inner-value-text">
                                {selectedData?.platform}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="order-inner-box">
                              <Index.Typography className="order-inner-lable-text">
                                Element:{" "}
                              </Index.Typography>
                              <Index.Typography className="order-inner-value-text">
                                {selectedData?.elements}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="order-inner-box">
                              <Index.Typography className="order-inner-lable-text">
                                Total Remaining Units:{" "}
                              </Index.Typography>
                              <Index.Typography className="order-inner-value-text">
                                {selectedData?.remainingUnits}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <FieldArray name="scheduleDetails">
                        {({ push, remove }) =>
                          values?.scheduleDetails?.map((data, index) => {
                            return (
                              <div key={index}>
                                <SchedulingModalInner
                                  index={index}
                                  values={data}
                                  errors={errors}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  setWeekDays={setWeekDays}
                                  touched={touched}
                                  selectedData={selectedData}
                                  orderList={orderList}
                                  creativeList={creativeList}
                                  programData={programData}
                                  slotDataArr={
                                    scheduleData?.find(
                                      (data) =>
                                        data?._id === selectedData?.schedule
                                    )?.slotId?.slot
                                  }
                                />
                                <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                  {index ===
                                  values?.scheduleDetails?.length - 1 ? (
                                    <Index.Box className="admin-add-creative-btn-row btn-main-primary">
                                      <Index.Button
                                        className="admin-modal-user-btn btn-primary"
                                        onClick={() => {
                                          push({
                                            type: "dateRange",
                                            startDate:
                                              formRef?.current?.values
                                                ?.startDate ?? null,
                                            endDate:
                                              formRef?.current?.values
                                                ?.endDate ?? null,
                                            scheduleType: "program",
                                            creative: undefined,
                                            selectedDates: [],
                                            scheduleId: selectedData?.schedule,
                                            program: undefined,
                                            slots: [],
                                            weekDays: weekArray?.length
                                              ? weekArray
                                              : [],
                                          });
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.plus}
                                          className="admin-user-save-icon"
                                          alt="Save"
                                        />
                                        <span>Add New Creative</span>
                                      </Index.Button>
                                    </Index.Box>
                                  ) : (
                                    ""
                                  )}
                                  {values?.scheduleDetails?.length > 1 ? (
                                    <Index.Box className="admin-add-creative-btn-row btn-main-primary">
                                      <Index.Button
                                        className="btn-primary add-event-btn "
                                        onClick={() => remove(index)}
                                      >
                                        <img
                                          src={PagesIndex.Svg.deleteicon}
                                          className="admin-incress-dicress-icon"
                                          alt="Save"
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  ) : (
                                    ""
                                  )}
                                </Index.Box>
                              </div>
                            );
                          })
                        }
                      </FieldArray>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-save-user-btn btn-primary"
                          type="submit"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          ></img>
                          <span>Save</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Modal>
        <Index.Modal
          open={openScheduleView}
          onClose={handleCloseScheduleView}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="admin-modal"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={() => {}}
            initialValues={initialValuesOrder}
            validationSchema={PagesIndex.salesSubOrderModal}
          >
            {({ values }) => (
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner order-details-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    View Order Details
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseScheduleView}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="order-show-box">
                    <Index.Box className="grid-row view-order-row">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="order-inner-box">
                            <Index.Typography className="order-inner-lable-text">
                              Platform Name:{" "}
                            </Index.Typography>
                            <Index.Typography className="order-inner-value-text">
                              {selectedData?.platform}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="order-inner-box">
                            <Index.Typography className="order-inner-lable-text">
                              Element:{" "}
                            </Index.Typography>
                            <Index.Typography className="order-inner-value-text">
                              {selectedData?.elements}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 8",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="order-inner-box">
                            <Index.Typography className="order-inner-lable-text">
                              Total Remaining Units:{" "}
                            </Index.Typography>
                            <Index.Typography className="order-inner-value-text">
                              {selectedData?.remainingUnits}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <FieldArray name="scheduleDetails">
                      {() =>
                        values?.scheduleDetails?.map((data, index) => {
                          return (
                            <div key={index}>
                              <SchedulingModalInnerView
                                index={index}
                                values={data}
                                selectedData={selectedData}
                                orderList={orderList}
                                creativeList={creativeList}
                                programData={programData}
                              />
                            </div>
                          );
                        })
                      }
                    </FieldArray>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-save-user-btn btn-primary"
                        onClick={handleCloseScheduleView}
                      >
                        <span>Close</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Modal>
      </>
    </Index.Box>
  );
}

const TableData = ({ data, handleOpenSchedule, handleOpenScheduleView }) => {
  return data?.length ? (
    data?.map((data, index) => {
      return (
        <Index.TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          key={data?._id}
        >
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {index + 1}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.platform ? data?.platform : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.elements ? data?.elements : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.totalSeconds ? data?.totalSeconds : 0} ({data?.unit})
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.discountrate ? data?.discountrate : 0}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {parseFloat(data?.remainingUnits)}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell
            component="td"
            variant="td"
            scope="row"
            className="table-td"
          >
            <Index.Box className="input-cus-flex">
              <Index.Tooltip
                title={"Schedule"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="schedule-btn border-btn"
                  onClick={() => handleOpenSchedule(data, index)}
                >
                  <img
                    src={PagesIndex.Svg.calendaricon}
                    className="admin-icons"
                    alt="discard"
                  />
                </Index.Button>
              </Index.Tooltip>
              <Index.Tooltip
                title={"View"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="schedule-btn border-btn"
                  onClick={() => handleOpenScheduleView(data, index)}
                >
                  <img
                    src={PagesIndex.Svg.yelloweye}
                    className="admin-icons"
                    alt="discard"
                  />
                </Index.Button>
              </Index.Tooltip>
            </Index.Box>
          </Index.TableCell>
        </Index.TableRow>
      );
    })
  ) : (
    <PagesIndex.DataNotFound />
  );
};

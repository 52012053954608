import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ViewAdsManagement() {
  const videoRef = useRef();
  const navigate = PagesIndex.useNavigate();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const params = PagesIndex.useParams();
  const { id } = params;
  const [data, setData] = useState({});
  const [adData, setAdData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openCalenderFrom, setOpenCalenderFrom] = useState(false);
  const [openCalenderTo, setOpenCalenderTo] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    formRef.current.resetForm();
    setOpenCalenderFrom(false);
    setOpenCalenderTo(false);
    setOpen(false);
  };

  const formRef = useRef();
  const initialValues = {
    startDate: null,
    endDate: null,
    modalType: "",
  };

  const handleGetAdData = () => {
    PagesIndex.apiGetHandler(`${PagesIndex.Api.GET_AD_DETAILS}/${id}`).then(
      (res) => {
        if (res?.status === 200) {
          setData(res?.data?.getAdsById);
          videoRef?.current?.load();
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const handleGetAdvertiseData = () => {
    setLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.ADVERTISERWISE_AD_LIST + "/" + id
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setAdData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  useEffect(() => {
    handleGetAdData();
    handleGetAdvertiseData();
  }, []);

  const renderData = () => {
    return adData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <>
              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.createdAt
                    ? PagesIndex.moment(data?.createdAt).format(
                        "D MMM YYYY hh:mm A"
                      )
                    : "-"}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.newsId?.newsNo ? data?.newsId?.newsNo : "-"}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Typography
                  className={`admin-table-data-text ${
                    data?.type === "View" ? "dark-red-color" : "orange-color"
                  }`}
                >
                  {data?.type ? data?.type : "View"}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                {data?.userId?.firstName
                  ? data?.userId?.firstName + " " + data?.userId?.lastName
                  : "-"}
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                {data?.userId?.gender ? data?.userId?.gender : "-"}
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                {data?.userId?.birthDate
                  ? PagesIndex.moment(new Date(data?.userId?.birthDate)).format(
                      "YYYY-MM-DD"
                    )
                  : "-"}
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                {data?.userId?.birthDate
                  ? PagesIndex.moment().diff(
                      PagesIndex.moment(data?.userId?.birthDate),
                      "years"
                    )
                  : "-"}
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                {data?.userId?.location ? data?.userId?.location : "-"}
              </Index.TableCell>
            </>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (adData?.length) {
      return renderData();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleExportCsv = (values) => {
    let startDate = PagesIndex.moment(new Date(values?.startDate)).format(
      "YYYY-MM-DD"
    );
    let endDate = PagesIndex.moment(new Date(values?.endDate)).format(
      "YYYY-MM-DD"
    );
    if (values?.modalType === "summary") {
      const urlEncoded = new URLSearchParams();
      urlEncoded.append("id", id);
      urlEncoded.append("from", startDate);
      urlEncoded.append("to", endDate);

      PagesIndex.apiPostHandler(
        PagesIndex.Api.EXPORT_ADSPLAY_LIST,
        urlEncoded
      ).then((res) => {
        if (res.status === 200) {
          const headers = ["Date & Time", "Click", "View", "CTR"];
          let modifiedData = res?.data?.map((data) => {
            return [
              PagesIndex.moment(data?.date).format("DD-MM-YYYY"),
              data?.click ? data?.click : 0,
              data?.views ? data?.views : 0,
              data?.ctr ? data?.ctr : 0,
            ].join(",");
          });

          // Constructing the CSV content
          let userNameRow = `,${data?.advId?.compName},,`; // User's name in the first row, spanned across columns
          let headersRow = headers.join(",");
          let csvContent =
            userNameRow + "\n" + headersRow + "\n" + modifiedData.join("\n");

          handleClose();
          exportData(
            csvContent,
            `${PagesIndex.moment().format(
              "DD-MM-YYYY hh:mm:ss A"
            )}_AdsPlay_list.csv`,
            "text/csv"
          );
        }
      });
    }

    if (values?.modalType === "details") {
      let filterData = adData?.filter((data) => {
        let createdAtDate = PagesIndex.moment(
          data?.createdAt,
          "DD-MM-YYYY"
        ).toDate();

        let startDateObj = PagesIndex.moment(startDate, "DD-MM-YYYY").toDate();
        let endDateObj = PagesIndex.moment(endDate, "DD-MM-YYYY").toDate();
        // Compare the dates
        let filterStartDate = startDateObj <= createdAtDate;
        let filterEndDate = createdAtDate <= endDateObj;
        // Return true if the date falls within the specified range
        return filterStartDate && filterEndDate;
      });

      const headers = [
        "Date & Time",
        "News Id",
        "Type",
        "User Name",
        "Gender",
        "Date Of Birth",
        "Age",
        "Location",
      ];
      let modifiedData = filterData?.map((data) => {
        return [
          PagesIndex.moment(data?.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
          data?.newsId?.newsNo ? data?.newsId?.newsNo : "-",
          data?.type ? data?.type : "-",
          data?.userId?.firstName
            ? data?.userId?.firstName + " " + data?.userId?.lastName
            : "-",
          data?.userId?.gender ? data?.userId?.gender : "-",
          data?.userId?.birthDate
            ? PagesIndex.moment(new Date(data?.userId?.birthDate)).format(
                "YYYY-MM-DD"
              )
            : "-",
          data?.userId?.birthDate
            ? PagesIndex.moment().diff(
                PagesIndex.moment(data?.userId?.birthDate),
                "years"
              )
            : "-",
          data?.userId?.location ? data?.userId?.location : "-",
        ].join(",");
      });

      // Constructing the CSV content
      let userNameRow = `,${data?.advId?.compName},,`; // User's name in the first row, spanned across columns
      let headersRow = headers.join(",");
      let csvContent =
        userNameRow + "\n" + headersRow + "\n" + modifiedData.join("\n");

      exportData(
        csvContent,
        `${PagesIndex.moment().format(
          "DD-MM-YYYY hh:mm:ss A"
        )}_AdsPlay_list.csv`,
        "text/csv"
      );
    }
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleExportCsv}
      initialValues={initialValues}
      validationSchema={PagesIndex.dashbordFilterDate}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-management-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main view-page-title-main">
              <Index.Box className="admin-title-left">
                <Index.Button
                  className="admin-adduser-btn btn-primary back-btn"
                  onClick={() => {
                    navigate(`/admin/ads-management`);
                  }}
                >
                  <img
                    src={PagesIndex.Svg.backarrow}
                    className="admin-icon edit-icons"
                    alt="plus"
                  />
                </Index.Button>
                <Index.Typography
                  className="admin-view-page-title"
                  component="h2"
                  variant="h2"
                >
                  Ad Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Tooltip
                  title="Export Report"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-adduser-btn-main btn-main-primary">
                    <Index.Button
                      className="admin-adduser-btn btn-primary"
                      onClick={() => handleOpen()}
                    >
                      <img
                        src={PagesIndex.Svg.report}
                        className="admin-icon edit-icons"
                        alt="plus"
                      />
                      <span>Export Report</span>
                    </Index.Button>
                  </Index.Box>
                </Index.Tooltip>

                {adminDetails?.roleId?.permissions?.includes("ads_edit") && (
                  <Index.Tooltip
                    title="Edit Ad"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Box className="admin-adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-adduser-btn btn-primary"
                        onClick={() =>
                          navigate(`/admin/edit-ads-management/${data?._id}`, {
                            state: {
                              data: {
                                ...data,
                                category: data?.category?.map(
                                  (item) => item?._id
                                ),
                                newsId: data?.newsId?.map((item) => item?._id),
                              },
                              type: "viewToEdit",
                            },
                          })
                        }
                      >
                        <img
                          src={PagesIndex.Svg.editwhite}
                          className="admin-icon edit-icons"
                          alt="plus"
                        />
                        <span>Edit Ad</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-details-card">
              <Index.Box className="admin-file-view-card common-card">
                <Index.Box className="admin-file-view-main">
                  {data?.mediaType === "image" ? (
                    <img
                      src={
                        `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}` ||
                        PagesIndex.Svg.noimgfound
                      }
                      className="admin-file-view-img"
                      alt="upload img"
                    />
                  ) : (
                    <video
                      ref={videoRef}
                      loop={true}
                      autoPlay="autoplay"
                      muted
                      className={"upload-img"}
                    >
                      <source
                        src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`}
                      />
                    </video>
                  )}
                </Index.Box>
                {data?.mediaType === "adMob" && (
                  <Index.Box className="view-highlight-box">
                    <Index.Typography className="view-highlight-lable">
                      Ad Id
                    </Index.Typography>
                    <Index.Box className="view-highlight-data">
                      {data?.adMobId || "-"}
                    </Index.Box>
                  </Index.Box>
                )}
                <Index.Box className="view-highlight-box">
                  <Index.Typography className="view-highlight-lable">
                    Per day Ad
                  </Index.Typography>
                  <Index.Typography className="view-highlight-data">
                    {data?.perDayAd || "-"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-highlight-box">
                  <Index.Typography className="view-highlight-lable">
                    Type
                  </Index.Typography>
                  <Index.Typography className="view-highlight-data">
                    {data?.type || "-"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-highlight-box">
                  <Index.Typography className="view-highlight-lable">
                    Weekdays
                  </Index.Typography>
                  <Index.Typography className="view-highlight-data">
                    {data?.weekDay?.length
                      ? data?.weekDay?.toString()?.replaceAll(",", ", ")
                      : ""}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="view-ads-main-box">
                <Index.Box className="common-card">
                  <Index.Box className="ads-details-main">
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Client:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.advId?.compName || "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Advertisement Id:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.adsNo || "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Start Date:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {PagesIndex.moment(data?.startDate)
                              .utc()
                              .format("DD MMM YYYY")}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          End Date:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {PagesIndex.moment(data?.endDate)
                              .utc()
                              .format("DD MMM YYYY")}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Budget:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.budget}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Total Ads to play:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.totalAdPlay || "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Media Type:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.mediaType || "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Ad Position:
                        </Index.Typography>
                        <Index.Box className="view-content-link-flex">
                          <Index.Typography className="view-content-data">
                            {data?.adsPart?.toString()?.replaceAll(",", ", ")}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-row-content-box">
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Time Bands:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.slot?.length
                              ? data?.slot?.toString()?.replaceAll(",", ", ")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="common-card ads-manage-details-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Manage whom to show
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="ads-manage-details-flex">
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Gender:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.gender?.length
                              ? data?.gender?.toString()?.replaceAll(",", ", ")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Age Groups:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.age?.length
                              ? data?.age?.toString()?.replaceAll(",", ", ")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          State:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.state?.stateName || "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Categories:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.category?.length
                              ? data?.category
                                  ?.map((data) => data?.categoryName)
                                  ?.toString()
                                  ?.replaceAll(",", ", ")
                              : "_"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          District:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.district?.name ? data?.district?.name : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-row-content-box">
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Location:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.coordinates?.length ? (
                              data?.coordinates?.map((data) => {
                                return (
                                  <Index.Typography
                                    className="view-content-data"
                                    key={data?._id}
                                  >
                                    {data?.description}
                                  </Index.Typography>
                                );
                              })
                            ) : (
                              <Index.Typography className="view-content-data">
                                -
                              </Index.Typography>
                            )}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex">
                        <Index.Typography className="view-content-lable">
                          Video Id:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-data">
                            {data?.newsId?.length
                              ? data?.newsId
                                  ?.map((data) => data?.newsNo)
                                  ?.toString()
                                  ?.replaceAll(",", ", ")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                      <Index.Box className="view-content-flex view-content-flex-align-item-start">
                        <Index.Typography className="view-content-lable">
                          Video Link:
                        </Index.Typography>
                        <Index.Box className="view-content-data-flex">
                          <Index.Typography className="view-content-lable">
                            {data?.newsId?.length ? (
                              data?.newsId?.map((data) => {
                                return (
                                  <Index.Typography
                                    className="view-content-data"
                                    key={data?._id}
                                  >
                                    {data?.newsTitle}
                                  </Index.Typography>
                                );
                              })
                            ) : (
                              <Index.Typography className="view-content-data">
                                -
                              </Index.Typography>
                            )}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-modal-hgt-scroll cus-scrollbar view-ads-row">
              <Index.Box className="common-card">
                <Index.Box className={`${loading ? "" : "page-table-main"}`}>
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="15%"
                            >
                              Date & Time
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="15%"
                            >
                              News Id
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Type
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="20%"
                            >
                              User Name
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Gender
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="20%"
                            >
                              Date Of Birth
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="10%"
                            >
                              Age
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="20%"
                            >
                              Location
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {renderContent()}
                        </Index.TableBody>
                      </Index.Table>
                    )}
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-pagination-main">
              {adData.length ? (
                <Index.Pagination
                  page={currentPage}
                  count={Math.ceil(adData.length / 10)}
                  onChange={(event, newPage) => setCurrentPage(newPage)}
                  variant="outlined"
                  shape="rounded"
                  className="admin-pagination"
                />
              ) : (
                ""
              )}
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Export Report
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleClose}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-input-box add-user-input">
                  <Index.FormHelperText className="admin-form-lable">
                    Select Type
                  </Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <Index.Box className="admin-dropdown-box">
                      <Index.FormControl className="admin-form-control">
                        <Index.Select
                          className="admin-dropdown-select"
                          name="modalType"
                          fullWidth
                          id="modalType"
                          value={values?.modalType}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={
                            values?.modalType ? undefined : () => "Select Type"
                          }
                        >
                          <Index.MenuItem
                            value="summary"
                            className="admin-menuitem"
                          >
                            Summary
                          </Index.MenuItem>
                          <Index.MenuItem
                            value="details"
                            className="admin-menuitem"
                          >
                            Details
                          </Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  className={"admin-modal-hgt-scroll cus-scrollbar"}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 5",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>From Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderFrom}
                            onClose={() => setOpenCalenderFrom(false)}
                            disabled={!values?.modalType}
                            className="admin-form-control admin-date-picker-control"
                            value={values.startDate} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("startDate", date);
                              setFieldValue("endDate", null);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderFrom(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenCalenderFrom(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 5",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>To Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderTo}
                            disabled={!values?.modalType}
                            onClose={() => setOpenCalenderTo(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.endDate} // Use '01/01/2000' as the default value
                            minDate={
                              values.startDate
                                ? dayjs(values.startDate).add(0, "day")
                                : dayjs().add(0, "day")
                            }
                            // maxDate={dayjs().subtract(16, "years")}
                            onChange={(date) => {
                              setFieldValue("endDate", date);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderTo(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenCalenderTo(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.endDate && touched.endDate && errors.endDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 2",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-save-user-btn btn-primary"
                          type="submit"
                          disabled={!values?.modalType}
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>Export</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>
        </>
      )}
    </PagesIndex.Formik>
  );
}

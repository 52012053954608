const Api = {
  LOGIN: "login",
  PROFILE: "profile",
  EDIT_PROFILE: "update-profile",
  CHANGE_PASSWORD: "change-password",
  VALIDATE_EMAIL: "forgot-password",
  RESEND_OTP: "resend-otp",
  VERIFY_OTP: "verify-otp",
  RESET_PASSWORD: "reset-password",
  LOGIN_LOG: "login-log",

  GET_ROLES: "get-roles",
  ADD_EDIT_ROLEPERMISSION: "add-edit-role",
  DELETE_ROLE: "remove-role",
  ISACTIVE_ROLE: "role-active-deActive",
  GET_ROLE_ACTIVITY_LOG: "get-activity-log-role",

  GET_CMS: "get-cms-details",
  ADD_EDIT_CMS: "add-edit-cms",
  GET_CMS_HELP_SUPPORT: "get-cms-details-help-support",

  GET_SUBADMIN: "get-sub-admin-list",
  ADD_EDIT_SUBADMIN: "add-edit-sub-admin",
  DELETE_SUBADMIN: "delete-sub-admin",
  ISACTIVE_SUBADMIN: "admin-active-deActive",
  GET_ISACTIVE_ACTIVITY_LOG: "get-activity-log-sub-admin",

  GET_CATEGORY: "getAllCategory",
  ADD_EDIT_CATEGORY: "addEditCategory",
  DELETE_CATEGORY: "deleteCategory",
  ISACTIVE_CATEGORY: "category-active-deActive",
  GET_CATEGORY_ACTIVITY_LOG: "get-activity-log-category",

  GET_STATE: "getAllState",
  ADD_EDIT_STATE: "addEditState",
  DELETE_STATE: "deleteState",
  ISACTIVE_STATE: "state-active-deActive",
  GET_STATE_ACTIVITY_LOG: "get-activity-log-state",

  GET_FAQ: "getAllFaq",
  ADD_EDIT_FAQ: "addEditFaq",
  DELETE_FAQ: "deleteFaq",
  GET_FAQ_ACTIVITY_LOG: "get-activity-log-faq",

  GET_SETTING_DATA: "list-site-setting",
  UPDATE_SETTING: "update-site-setting",
  USER_LIST: "user-list",
  ISACTIVE_USER: "user-active-deActive",
  GET_USER_DETAILS: "get-user-detail",
  GET_USER_COUNT: "get-user-views-news-rating",
  GET_USER_DETAILS_GRAPH: "user-content-graph",
  ADD_EDIT_USER_BLOCK_REASON: "user-block-unblock",
  ADD_USER: "admin-add-edit-user",
  GET_ADMIN_ACTIVITY: "get-admin-activity",
  GET_USER_GRAPH_DATA: "ads-user-list",
  EXPORT_ADSPLAY_LIST: "export-adsplay-list",
  GET_USER_VOTES_LIST: "user-vote-list",

  ADD_EDIT_ZONEMASTER: "addEditZoneMaster",
  GET_ZONE_BY_STATE: "getZoneByState",
  GET_ZONEMASTER: "getAllZoneMaster",
  DELETE_ZONEMASTER: "deleteZoneMaster",
  ISACTIVE_ZONE: "zone-active-deActive",
  GET_ZONE_ACTIVITY_LOG: "get-activity-log-zone",

  ADD_EDIT_DISTRICTMASTER: "addEditDistrictMaster",
  GET_DISTRICTMASTER: "getAllDistrictMaster",
  DELETE_DISTRICTMASTER: "deleteDistrictMaster",
  ISACTIVE_DISTRICTMASTER: "district-active-deActive",
  GET_DISTRICT_BY_ZONE: "getDistrictByZone",
  GET_DISTRICT_ACTIVITY_LOG: "get-activity-log-district",

  GET_TAG: "getAllTag",
  ADD_EDIT_TAG: "addEditTag",
  DELETE_TAG: "deleteTag",
  ISACTIVE_TAG: "tag-active-deActive",
  GET_TAG_ACTIVITY_LOG: "get-activity-log-tag",

  GET_MOVIE_TAG: "get-all-tag-movie",
  ADD_EDIT_MOVIE_TAG: "add-edit-tag-type-movie",
  DELETE_MOVIE_TAG: "delete-tag-type-movie",
  ISACTIVE_MOVIE_TAG: "tag-active-deActive-movie",
  GET_MOVIE_TAG_ACTIVITY_LOG: "get-activity-log-tag-type-movie",

  ADD_EDIT_NEWS: "addEditNews",
  GET_NEWS: "getAllNews",
  DELETE_NEWS: "deleteNews",
  ISACTIVE_NEWS: "news-active-deActive",
  GET_NEWS_ACTIVITY_LOG: "get-activity-log-news",
  VIEW_NEWS: "view-news",
  EXPORT_NEWS: "export-news",
  GET_ALL_NEWS: "all-news",
  GET_NEWS_GRAPH_DATA: "news-graph-data",
  GET_USER_NEWS_RATING: "get-user-news-rating",
  GET_USER_VOTE_RATING: "vote-list",
  CHANGE_AD_STATUS_OF_NEWS: "change-status-ads-play",

  GET_USER_QUERY: "get-user-query",
  GET_USER_CONTECT_LIST: "user-contact-list",
  GET_FEEDBACK: "get-feedback",

  ADD_EDIT_ACTOR: "add-edit-cast",
  GET_ALL_ACTOR: "get-cast",
  DELETE_ACTOR: "deleteCast",

  ADD_EDIT_MOVIES: "add-edit-movies",
  GET_ALL_MOVIES: "get-movies",
  DELETE_MOVIES: "delete-movies",
  MOVIES_STATUS: "action-movies",
  VIEW_MOVIE_DATA: "view-movies",

  GET_ALL_MOVIE_CATEGORY: "get-movie-category",
  GET_MOVIE_ACTIVITY_LOG: "get-activity-log-movie",
  DELETE_MOVIE_CATEGORY: "delete-movie-category",
  ADD_EDIT_MOVIE_CATEGORY: "add-edit-movie-category",
  ISACTIVE_MOVIE_CATEGORY: "action-movies-category",
  GET_MOVIE_CATEGORY_ACTIVITY_LOG: "get-activity-log-movie-category",

  GET_ALL_WEB_SERIES: "get-webseries",
  VIEW_WEB_SERIES_DATA: "view-webseries",

  GET_SEASON: "get-season",
  ADD_EDIT_SEASON: "add-edit-season",
  DELETE_SEASON: "delete-season",
  SEASON_STATUS: "season-active-deActive",

  GET_EPISODE: "get-episode",
  ADD_EDIT_EPISODE: "add-edit-episode",
  DELETE_EPISODE: "delete-episode",
  EPISODE_STATUS: "episode-active-deActive",

  ADD_EDIT_EVENT: "add-edit-event",
  GET_EVENT: "get-event",
  DELETE_EVENT: "delete-event",
  ISACTIVE_EVENT: "action-event",
  GET_EVENT_ACTIVITY_LOG: "",

  ADD_EDIT_AD_ZONEMASTER: "addEditZoneMasterAd",
  GET_AD_ZONEMASTER: "getAllZoneMasterAd",
  DELETE_AD_ZONEMASTER: "deleteZoneMasterAd",
  ISACTIVE_AD_ZONE: "zone-active-deActive-ad",
  GET_AD_ZONE_ACTIVITY_LOG: "get-activity-log-zone-ad",

  GET_ADVERTISER_PREREQUISITE_DATA: "add-advertiser-prerequisite-data",
  ADD_ADVERTISER: "add-advertiser",
  GET_ADVERTISER: "get-all-advertisor",
  GET_ALL_ADVERTISER: "get-all-advertiser",
  DELETE_ADVERTISER: "delete-advertiser",
  ISACTIVE_ADVERTISER: "action-advertiser",
  GET_ADVERTISING: "getAllAdvertising",

  GET_ADVERTISEMENTS: "get-all-ads",
  ADD_ADVERTISEMENT: "add-edit-ads",
  DELETE_ADVERTISEMENT: "delete-ads",
  ISACTIVE_ADVERTISEMENT: "action-ads",
  ADVERTISERWISE_AD_LIST: "advwise-ads-list",
  DELETE_ADVERTISER_LEAD: "delete-advertising",
  GET_AD_DETAILS: "get-ads-details",
  ARCHIVED_ADVERTISING_LIST: "archived-list",
  GET_CLIENTS_DETAILS: "/adv-details",
  ADD_BILLING_INFORMATION: "/add-billing",

  SEND_EVENT_MAIL: "eventMail",
  GET_SLOT_LIST: "slot-list",

  GET_HOME_CATEGORY: "get-all-section",
  ADD_EDIT_HOME_CATEGORY: "add-edit-section",
  DELETE_HOME_CATEGORY: "delete-section",
  ISACTIVE_HOME_CATEGORY: "section-active-deActive",
  GET_HOME_CATEGORY_ACTIVITY_LOG: "get-activity-log-section",
  UPDATE_ROW_HOME_CATEGORY: "update-section-row",

  GET_SPONSOR: "get-sponsor",
  ADD_EDIT_SPONSOR: "add-edit-sponsor",
  DELETE_SPONSOR: "delete-sponsor",
  ACTION_SPONSOR: "action-sponsor",
  GET_ACTIVITY_LOG: "get-activity-log-sponsor",
  GET_VIEW_DETAILS: "get-sponsor",

  // Not a CRUD wise
  AGE_LIST: "age-list",
  UPLOAD_S3_LINK: "upload-s3-link",
  RESPONSE_USER_QUERY: "response-user-query",

  GET_DASHBOARD_COUNT: "get-total-count",
  GET_NEWS_LIST_GRAPH_DATA: "ads-play-user-news-list",
  GET_DESHBOARD_NEWS_GRAPH_DATA: "get-news-graph-data",
  GET_DESHBOARD_CONTANT_GRAPH: "get-content-graph",
  GET_DESHBOARD_HOME_SECTION_GRAPH: "get-home-section-graph",
  GET_DESHBOARD_NEWS_RATING_GRAPH: "get-news-rating-graph",
  GET_DESHBOARD_DISTRICT_WISE_NEWS_GRAPH: "get-district-wise-news-graph",

  GET_POST_VIDEO_LIST: "get-post-video-list",
  GET_ALL_POST_VIDEO_LIST: "get-all-post-video-list",
  USER_ACCEPT_REJECT_VIDEO: "user-accept-video",
  USER_DOWNLOAD_VIDEO: "post-video-download",
  USER_VIDEO_ACTIVITY_LOG: "get-activity-log-post",

  GET_CHANNELS: "get-all-channel",
  ADD_EDIT_CHANNEL: "add-edit-channel",
  DELETE_CHANNEL: "delete-channel",
  ACTIVE_DEACTIVE_CHANNEL: "channel-active-deactive",
  CHANNEL_ACTIVITY_LOG: "get-activity-log-channel",

  AD_REPORT: "ads-report",

  GET_PROGRAM_TYPE: "get-all-program-type",
  ADD_EDIT_PROGRAM_TYPE: "add-edit-program-type",
  DELETE_PROGRAM_TYPE: "delete-program-type",
  ACTIVE_DEACTIVE_PROGRAM_TYPE: "program-type-action",
  PROGRAM_TYPE_ACTIVITY_LOG: "get-activity-log-program-type",

  GET_PROGRAM: "get-all-program",
  ADD_EDIT_PROGRAM: "add-edit-program",
  DELETE_PROGRAM: "delete-program",
  ACTIVE_DEACTIVE_PROGRAM: "program-action",
  PROGRAM_ACTIVITY_LOG: "get-activity-log-program",

  GET_DOCUMENT_TYPES: "document-list",
  UPLOAD_CLIENT_DOCUMENT: "upload-documents",
  DELETE_DOCUMENT: "delete-document",

  GET_ELEMENTS: "get-all-element",
  ADD_EDIT_ELEMENT: "add-edit-element",
  DELETE_ELEMENT: "delete-element",
  ACTIVE_DEACTIVE_ELEMENT: "element-action",
  ELEMENT_ACTIVITY_LOG: "get-activity-log-element",

  GET_CREATIVES_BY_CLIENT: "client-creative",
  GET_CREATIVES: "get-all-creative",
  ADD_EDIT_CREATIVE: "add-edit-creative",
  DELETE_CREATIVE: "delete-creative",

  GET_SLOTS: "get-all-slot",
  ADD_SLOT: "add-edit-slot",

  GET_SLOTS_BY_TYPE: "get-slot-by-type",
  GET_SCHEDULES: "get-all-schedule",
  ADD_EDIT_SCHEDULE: "add-edit-schedule",
  DELETE_SCHEDULE: "delete-schedule",
  VIEW_SLOT_SCHEDULE: "view-schedule",
  ISACTIVE_SCHEDULE: "action-schedule",

  ADD_EDIT_SLOT_SCHEDULE: "slot-schedule",
  GET_SLOT_SCHEDULE: "/get-schedule",
  DELETE_SLOT_SCHEDULE: "/delete-schedule-slot",

  ADD_EDIT_SALES_ORDER: "add-edit-sales-order",
  GET_ALL_SALES_ORDERS: "get-all-sales-order",
  DELETE_SALES_ORDER: "delete-sales-order",
  GET_SALES_STATUS: "action-status",

  GET_ALL_CLIENT_CATEGORY: "get-category",
  GET_CLIENT_CATEGORY_ACTIVITY_LOG: "get-activity-log-clients-category",
  DELETE_CLIENT_CATEGORY: "delete-clients-category",
  ADD_EDIT_CLIENT_CATEGORY: "add-edit-category",
  ISACTIVE_CLIENT_CATEGORY: "client-category-active-deActive",

  GET_ALL_CLIENT_SUB_CATEGORY: "get-all-subcategory",
  GET_CLIENT_SUB_CATEGORY_ACTIVITY_LOG: "get-activity-log-clients-sub-category",
  DELETE_CLIENT_SUB_CATEGORY: "delete-clients-sub-category",
  ADD_EDIT_CLIENT_SUB_CATEGORY: "add-edit-subcategory",
  ISACTIVE_CLIENT_SUB_CATEGORY: "client-sub-category-active-deActive",

  GET_ALL_MEDIATYPE: "get-media-platform",
  GET_MEDIATYPE_ACTIVITY_LOG: "get-activity-log-media-platform",
  DELETE_MEDIATYPE: "delete-media-platform",
  ADD_EDIT_MEDIATYPE: "add-edit-media-platform",
  ISACTIVE_MEDIATYPE: "media-platform-active-deActive",

  GET_ALL_VOTERS_LIST: "export-vote",

  GET_WEBSITE_SCHEDULE_LIST: "get-other-schedule",
  ADD_EDIT_WEBSITE_SCHEDULE_LIST: "add-edit-other-schedule",

  ADD_BOOKING: "add-sub-order",
  GET_BOOKING: "get-sub-sales-order",

  GET_UPLOADED_CONTENT_LIST: "get-admin-wise-uploaded-news",
  GET_UPLOADED_VIDEO_CONTENT_LIST: "get-admin-wise-uploaded-video",

  NOTIFICATION_LIST: "notification-list",
  NOTIFICATION_READ: "notification-read",
  USER_LOGOUT: "logout",
  GET_PENDINGVIDEO_COUNT: "get-post-video-count",

  ADD_AUTO_SCHEDULE_ENTRY: "auto-entry-schedule",

  GET_ALL_CLIENT_AGENCY: "get-agency",
  GET_CLIENT_AGENCY_ACTIVITY_LOG: "get-activity-log-clients-agency",
  DELETE_CLIENT_AGENCY: "delete-clients-agency",
  ADD_EDIT_CLIENT_AGENCY: "add-edit-agency",
  ISACTIVE_CLIENT_AGENCY: "client-agency-active-deActive",

  GET_UPLOADED_VIDEOS: "get-all-upload-video",
  GET_UPLOADED_VIDEOS_ARCHIVED: "get-all-archive-video",
  DELETE_UPLOADED_VIDEOS: "delete-video",
  HARD_DELETE_UPLOADED_VIDEOS: "hard-delete-video",
  RESTORE_ARCHIVED_VIDEOS: "restore-video",

  GET_ALL_SERVER: "get-internal",
  DELETE_SERVER: "delete-internal",
  ADD_EDIT_SERVER: "add-edit-internal",
  ISACTIVE_SERVER: "action-internal",

  GET_ALL_BANNER: "get-all-banner",
  DELETE_BANNER: "delete-banner",
  ADD_EDIT_BANNER: "add-edit-banner",
  ISACTIVE_BANNER: "action-banner",
};
export { Api };

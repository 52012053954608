import { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getIn } from "formik";
import WeekdayTableView from "./WeekdayTableView";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";

export const SchedulingModalInnerView = (props) => {
  const { index, values, selectedData, orderList, creativeList, programData } =
    props;

  const dateType = `scheduleDetails[${index}].type`;
  const scheduleType = `scheduleDetails[${index}].scheduleType`;
  const selectedDates = `scheduleDetails[${index}].selectedDates`;
  return (
    <Index.Box className="order-show-inner-box order-show-inner-box-view">
      <Index.Box className="grid-row">
        <Index.Box className="admin-sub-title-main view-order-title-main">
          <Index.Typography className="admin-sub-title">
            Date Type
          </Index.Typography>
        </Index.Box>

        <Index.Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          columnGap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 8",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box add-ads-input-main admin-radio-main">
              <Index.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={dateType}
                className="admin-radiogroup"
                value={values?.type}
              >
                <Index.FormControlLabel
                  value="dateRange"
                  disabled
                  control={<Index.Radio className="admin-radio" />}
                  label="Date Range"
                />
                <Index.FormControlLabel
                  value="date"
                  disabled
                  control={<Index.Radio className="admin-radio" />}
                  label="Date"
                />
              </Index.RadioGroup>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {values?.type === "dateRange" ? (
        <Index.Box className="view-date-slot-main">
          <Index.Box className="grid-row">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              rowGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              className="date-range-row"
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-input-box  admin-date-input-main input-spacing-remove">
                  <Index.FormHelperText className="admin-form-lable">
                    <span>Start Date</span>
                    <span className="astrick-sing">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <PagesIndex.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <PagesIndex.DatePicker
                        disabled
                        className="admin-form-control admin-date-picker-control"
                        value={PagesIndex.dayjs(values.startDate)} // Use '01/01/2000' as the default value
                        minDate={
                          selectedData?.startDate
                            ? PagesIndex.dayjs(selectedData?.startDate)
                            : PagesIndex.dayjs(orderList?.startDate)
                        }
                        maxDate={
                          selectedData?.endDate
                            ? PagesIndex.dayjs(selectedData?.endDate)
                            : PagesIndex.dayjs(orderList?.endDate)
                        }
                        slotProps={{
                          popper: {
                            className: "date-picker-body",
                          },
                        }}
                        format="DD/MM/YYYY"
                      />
                    </PagesIndex.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 8",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-input-box admin-date-input-main input-spacing-remove">
                  <Index.FormHelperText className="admin-form-lable">
                    <span>End Date</span>
                    <span className="astrick-sing">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <PagesIndex.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <PagesIndex.DatePicker
                        disabled
                        className="admin-form-control admin-date-picker-control"
                        value={PagesIndex.dayjs(values.endDate)} // Use '01/01/2000' as the default value
                        minDate={
                          values?.startDate
                            ? PagesIndex.dayjs(values?.startDate)
                            : selectedData?.startDate
                            ? PagesIndex.dayjs(selectedData?.startDate)
                            : PagesIndex.dayjs(orderList?.startDate)
                        }
                        maxDate={
                          selectedData?.endDate
                            ? PagesIndex.dayjs(selectedData?.endDate)
                            : PagesIndex.dayjs(orderList?.endDate)
                        }
                        slotProps={{
                          popper: {
                            className: "date-picker-body",
                          },
                        }}
                        format="DD/MM/YYYY"
                      />
                    </PagesIndex.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <></>
      )}
      {values?.type === "date" ? (
        <Index.Box className="admin-input-box">
          <DatePicker
            disabled
            className="admin-form-control admin-multi-date-picker-control"
            name={selectedDates}
            value={values?.selectedDates?.map((data) => data?.fullDate)}
            multiple
            placeholder="Select Dates"
            plugins={[<DatePanel />]}
            format={"DD/MM/YYYY"}
            minDate={
              selectedData?.startDate
                ? new Date(selectedData?.startDate)
                : new Date(orderList?.startDate)
            }
            maxDate={
              selectedData?.endDate
                ? new Date(selectedData?.endDate)
                : new Date(orderList?.endDate)
            }
          />
        </Index.Box>
      ) : (
        <></>
      )}
      <Index.Box className="creative-order-row">
        <Index.Box className="grid-row">
          <Index.Box className="admin-sub-title-main view-order-title-main">
            <Index.Typography className="admin-sub-title">
              Schedule Type
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          rowGap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box admin-radio-main input-spacing-remove">
              <Index.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={scheduleType}
                className="admin-radiogroup"
                value={values?.scheduleType}
              >
                <Index.FormControlLabel
                  value="program"
                  disabled
                  control={<Index.Radio className="admin-radio" />}
                  label="Program"
                />
                <Index.FormControlLabel
                  value="timeBand"
                  disabled
                  control={<Index.Radio className="admin-radio" />}
                  label="Timeband"
                />
              </Index.RadioGroup>
            </Index.Box>
          </Index.Box>
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 6",
              lg: "span 6",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box view-order-lable-input">
              <Index.FormHelperText className="admin-form-lable">
                Add Creative
              </Index.FormHelperText>
              <Index.Box className="admin-input-box input-complete-box">
                <Index.Box className="admin-form-group">
                  <Index.Autocomplete
                    disabled
                    className="admin-auto-complete-control"
                    options={
                      creativeList?.filter(
                        (creativeData) =>
                          selectedData?.platform ===
                            creativeData?.platformId?.typeName &&
                          selectedData?.elements ===
                            creativeData?.elementId?.name
                      ) || []
                    }
                    disableCloseOnSelect
                    closeText={""}
                    openText={""}
                    clearText={""}
                    value={
                      values?.creative
                        ? creativeList?.find(
                            (data) => data?._id === values?.creative
                          )
                        : null
                    }
                    getOptionLabel={(option) =>
                      option?.duration
                        ? `${option?.code}-${option?.name}-${option?.duration} Sec`
                        : `${option?.code}-${option?.name}`
                    }
                    renderOption={(props, item) => (
                      <li {...props} key={item?._id}>
                        {item?.duration
                          ? `${item?.code}-${item?.name}-${item?.duration} Sec`
                          : `${item?.code}-${item?.name}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <Index.TextField
                        fullWidth
                        className="admin-form-control"
                        name="creative"
                        {...params}
                        size="small"
                        variant="outlined"
                        placeholder="Select Creative"
                      />
                    )}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {values?.scheduleType === "program" ? (
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-input-box view-order-lable-input">
                <Index.FormHelperText className="admin-form-lable">
                  Program
                </Index.FormHelperText>
                <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                  <Index.Box className="admin-form-group">
                    <Index.Autocomplete
                      disabled
                      className="admin-form-control admin-auto-complete-control"
                      options={programData || []}
                      closeText={""}
                      openText={""}
                      clearText={""}
                      value={
                        values?.program
                          ? programData?.find(
                              (data) => data?._id === values?.program
                            )
                          : null
                      }
                      getOptionLabel={(option) => option?.programName}
                      renderOption={(props, item) => (
                        <li {...props} key={item?._id}>
                          {item?.programName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <Index.TextField
                          fullWidth
                          className="admin-form-control"
                          name="program"
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder="Select Program"
                        />
                      )}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ) : (
            <></>
          )}

          {values?.scheduleType === "timeBand" ? (
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="view-date-slot-main">
                <Index.Box className="grid-row">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                        <Index.FormHelperText className="admin-form-lable">
                          From Time
                        </Index.FormHelperText>
                        <Index.FormControl className="admin-form-control admin-drop-form-control">
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.TimePicker
                                disabled
                                views={["hours", "minutes", "seconds"]}
                                timeSteps={{
                                  minutes: 1,
                                  seconds: 1,
                                }}
                                className="admin-form-control admin-date-picker-control"
                                value={PagesIndex.dayjs(values?.fromTime)}
                                ampm={false}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                }}
                              />
                            </PagesIndex.LocalizationProvider>
                          </Index.Box>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                        <Index.FormHelperText className="admin-form-lable">
                          To Time
                        </Index.FormHelperText>
                        <Index.FormControl className="admin-form-control admin-drop-form-control">
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.TimePicker
                                disabled
                                views={["hours", "minutes", "seconds"]}
                                timeSteps={{
                                  minutes: 1,
                                  seconds: 1,
                                }}
                                className="admin-form-control admin-date-picker-control"
                                value={PagesIndex.dayjs(values?.toTime)}
                                ampm={false}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                }}
                              />
                            </PagesIndex.LocalizationProvider>
                          </Index.Box>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ) : (
            <></>
          )}
        </Index.Box>
      </Index.Box>
      {values?.type === "dateRange" ? (
        values?.weekDays?.length ? (
          <Index.Box className="week-main">
            <Index.Box className="week-day-name-flex">
              <Index.Typography className="week-day-text"></Index.Typography>
              <Index.Typography className="week-day-text">Sun</Index.Typography>
              <Index.Typography className="week-day-text">Mon</Index.Typography>
              <Index.Typography className="week-day-text">Tue</Index.Typography>
              <Index.Typography className="week-day-text">Wed</Index.Typography>
              <Index.Typography className="week-day-text">Thu</Index.Typography>
              <Index.Typography className="week-day-text">Fri</Index.Typography>
              <Index.Typography className="week-day-text">Sat</Index.Typography>
              <Index.Typography className="week-day-text">
                Total
              </Index.Typography>
              <Index.Typography className="week-day-text">
                Total Seconds
              </Index.Typography>
            </Index.Box>
            {values?.weekDays.map((data, index) => {
              return (
                <WeekdayTableView
                  creativeDuration={values?.creativeDuration}
                  data={data}
                />
              );
            })}
          </Index.Box>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {values?.type === "date" ? (
        values?.selectedDates?.length ? (
          <Index.Box className="week-main">
            <Index.Box className="week-day-name-flex">
              {values?.selectedDates.map((data, index) => {
                return (
                  <Index.Typography className="week-day-text" key={data?.day}>
                    {data?.day}
                  </Index.Typography>
                );
              })}
            </Index.Box>
            <Index.Box className="week-content-main-flex">
              {values?.selectedDates.map((data, index) => {
                return (
                  <Index.Box
                    className="week-box"
                    key={`${data?.day}-${data?.onlyDate}`}
                  >
                    <Index.Typography className="week-date-text">
                      {data?.onlyDate}
                    </Index.Typography>
                    <Index.Box className="admin-input-box week-input-box">
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="admin-form-control"
                          placeholder="0"
                          value={data?.value === 0 ? "" : data?.value}
                          disabled
                          inputProps={{ maxLength: 3 }}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                );
              })}
            </Index.Box>
          </Index.Box>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </Index.Box>
  );
};

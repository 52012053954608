import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { FieldArray, getIn } from "formik";
import dayjs from "dayjs";

export default function AddAdvertiser() {
  const { id } = PagesIndex.useParams();
  const formRef = useRef();
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const [eventData, setEventData] = useState([]);
  const [mediaPlatform, setMediaPlatform] = useState([]);
  const [prerequisiteData, setPrerequisiteData] = useState({});
  const [accDirectorDropDown, setAccDirectorDropDown] = useState(false);
  const [groupHeadDropDown, setGroupHeadDropDown] = useState(false);
  const [managerDropDown, setManagerDropDown] = useState(false);
  const [add2DropDown, setAdd2DropDown] = useState(false);
  const [stateDropDown, setStateDropDown] = useState(false);
  const [zoneDropDown, setZoneDropDown] = useState(false);
  const [cityDropDown, setCityDropDown] = useState(false);
  const [brandDropDown, setBrandDropDown] = useState(false);
  const [brandTagDropDown, setBrandTagDropDown] = useState(false);
  const [brandAmbDropDown, setBrandAmbDropDown] = useState(false);
  const [contactDropDown, setContactDropDown] = useState(false);
  const [emailDropDown, setEmailDropDown] = useState(false);
  const [agencyDropDown, setAgencyDropDown] = useState(false);
  const [catDropDown, setCatDropDown] = useState(false);
  const [subCatDropDown, setSubCatDropDown] = useState(false);
  const [favCelebDropDown, setFavCelebDropDown] = useState(false);
  const [passionDropDown, setPassionDropDown] = useState(false);
  const [socialDropDown, setSocialDropDown] = useState(false);
  const [impactDropDown, setImpactDropDown] = useState(false);
  const [familyDropDown, setFamilyDropDown] = useState(false);
  const [mediaToUseDropDown, setMediaToUseDropDown] = useState();
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [openCalender, setOpenCalender] = useState(false);
  const [draftButtonSpinner, setDraftButtonSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [validationSchema, setValidationSchema] = useState(PagesIndex.adSchema);
  const [advertiserData, setAdvertiserData] = useState({
    address1List: [],
    address2List: [],
    cityList: [],
    brandList: [],
    brandAmbassadorList: [],
    brandSloganList: [],
    contactList: [],
    emailList: [],
    impactPartenershipList: [],
    familyDetailsList: [],
    celebrityList: [],
    passionList: [],
    socialCauseList: [],
  });
  const initialValues = {
    accountDirector: id ? state?.data?.accDirector?._id : null,
    groupHead: id ? state?.data?.grpHead?._id : null,
    manager: id ? state?.data?.manager?._id : null,
    companyName: id
      ? state?.data?.compName
      : state?.advertiserLeadData
      ? state?.advertiserLeadData?.businessInformation
      : "",
    zone: id ? state?.data?.zone?._id : null,
    address1: id ? state?.data?.add1 : "",
    address2: id ? state?.data?.add2 : "",
    state: id ? state?.data?.state?._id : null,
    city: id ? state?.data?.city : null,
    directCustomer: id
      ? state?.data?.directCustomer == false
        ? "No"
        : "Yes"
      : "Yes",
    remark: id ? state?.data?.remark : "",
    brands: id
      ? state?.data?.brand
      : [
          {
            brandName: "",
            brandAmbassador: "",
            brandTagline: "",
            mediaToUse: [],
            adPeriod: [],
          },
        ],

    title: id
      ? state?.data?.firstName?.includes("Mrs.")
        ? "Mrs."
        : state?.data?.firstName?.includes("Ms.")
        ? "Ms."
        : "Mr."
      : "Mr.",
    firstName: id
      ? state?.data?.firstName?.includes("Mrs.")
        ? state?.data?.firstName?.replaceAll("Mrs. ", "")
        : state?.data?.firstName?.includes("Ms.")
        ? state?.data?.firstName?.replaceAll("Ms. ", "")
        : state?.data?.firstName?.replaceAll("Mr. ", "")
      : state?.advertiserLeadData
      ? state?.advertiserLeadData?.userId?.firstName
      : "",
    lastName: id
      ? state?.data?.lastName
      : state?.advertiserLeadData
      ? state?.advertiserLeadData?.userId?.lastName
      : "",
    designation: id ? state?.data?.designation : "",
    agencyName: id ? state?.data?.agencyName : null,
    contactNumber: id ? state?.data?.contact : "",
    emailId: id ? state?.data?.email : "",
    category: id ? state?.data?.category?._id : "",
    subCategory: id ? state?.data?.subCategory?._id : "",
    fct: id ? state?.data?.fct?.toString() : "",
    nfct: id ? state?.data?.nfct?.toString() : "",

    favCelebrity: state?.data?.celebrities || [],

    passion: state?.data?.passionate || [],
    socialCause: state?.data?.socialCause || [],
    impactPartnerShip: id ? state?.data?.partenership : "Impact",
    familyDetails: id ? state?.data?.familyDetails : [],
    billingCompName: id ? state?.data?.billingCompName : "",
    address: id ? state?.data?.billingAddress : "",
    number: id ? state?.data?.billingContact : "",
    email: id ? state?.data?.billingEmail : "",
    GSTNo: id ? state?.data?.gstNo : "",
    PANNo: id ? state?.data?.panNo : "",
    agencyDetails: id
      ? state?.data?.agencyDetails
      : [
          {
            role: "",
            name: "",
            contact: "",
            email: "",
          },
        ],
    events: id
      ? state?.data?.events?.map((data) => ({
          eventName: data?._id,
          date: dayjs(new Date(data?.date)),
        }))
      : [],
    isDraft: id ? state?.data?.isDraft : true,
  };

  const roleData = [
    { role: "Media Planner" },
    { role: "Media Buyer" },
    { role: "Creative" },
    { role: "Client Servicing" },
    { role: "Billing/Booking" },
    { role: "Other" },
  ];
  useEffect(() => {
    handlePrerequisiteData();
    handleGetEvent();
    handleGetAd();
    handleGetMediaPlatform();
  }, []);

  useEffect(() => {
    if (
      state?.data?.billingCompName ||
      state?.data?.billingAddress ||
      state?.data?.billingEmail ||
      state?.data?.gstNo ||
      state?.data?.panNo
    ) {
      setShowBillingDetails(true);
    }
  }, [state?.data]);

  const handleGetMediaPlatform = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_MEDIATYPE).then((res) => {
      if (res.status === 200) {
        const activeState = res?.data?.filter((state) => state?.isActive);
        setMediaPlatform(activeState);
      }
    });
  };

  const handleGetAd = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res?.status === 200) {
        const celebrityList = [];
        const passionList = [];
        const socialCauseList = [];
        const brandNameList = [];
        const brandAmbassadorList = [];
        const brandSloganList = [];
        res.data?.map((data) =>
          data?.celebrities?.map((data) => celebrityList.push(data))
        );
        res.data?.map((data) =>
          data?.passionate?.map((data) => passionList.push(data))
        );
        res.data?.map((data) =>
          data?.socialCause?.map((data) => socialCauseList.push(data))
        );
        res.data?.map((data) =>
          data?.brand?.map((data) => brandNameList.push(data?.brandName))
        );
        res.data?.map((data) =>
          data?.brands?.map((data) =>
            brandAmbassadorList.push(data?.brandAmbassador)
          )
        );
        res.data?.map((data) =>
          data?.brands?.map((data) => brandSloganList.push(data?.brandTagline))
        );
        setAdvertiserData({
          address1List: [
            ...new Set([
              ...res?.data
                ?.filter((data) => data?.add1)
                ?.map((data) => data?.add1),
            ]),
          ],
          address2List: [
            ...new Set([
              ...res?.data
                ?.filter((data) => data?.add2)
                ?.map((data) => data?.add2),
            ]),
          ],
          cityList: [
            ...new Set([
              ...res?.data
                ?.filter((data) => data?.city)
                ?.map((data) => data?.city),
            ]),
          ],
          contactList: [
            ...new Set([
              ...res?.data
                ?.filter((data) => data?.contact)
                ?.map((data) => data?.contact),
            ]),
          ],
          emailList: [
            ...new Set([
              ...res?.data
                ?.filter((data) => data?.email)
                ?.map((data) => data?.email),
            ]),
          ],
          familyDetailsList: [
            ...new Set([
              ...res?.data
                ?.filter((data) => data?.familyDetails)
                ?.map((data) => data?.familyDetails),
            ]),
          ],
          celebrityList: [...new Set([...celebrityList])],
          passionList: [...new Set([...passionList])],
          socialCauseList: [...new Set([...socialCauseList])],
          brandList: [...new Set([...brandNameList])],
          brandAmbassadorList: [...new Set([...brandAmbassadorList])],
          brandSloganList: [...new Set([...brandSloganList])],
        });
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetEvent = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_EVENT).then((res) => {
      if (res.status === 200) {
        const activeEvents = res?.data?.filter((state) => state?.isActive);
        setEventData(activeEvents);
      }
    });
  };
  const handlePrerequisiteData = () => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_ADVERTISER_PREREQUISITE_DATA
    ).then((res) => {
      if (res.status === 200) {
        setPrerequisiteData(res?.data);
      }
    });
  };
  const handleAddEditAdvertiser = (values) => {
    if (values?.isDraft) {
      setDraftButtonSpinner(true);
    } else {
      setButtonSpinner(true);
    }
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    if (values?.accountDirector) {
      urlEncoded.append("accDirector", values?.accountDirector);
    }
    if (values?.groupHead) {
      urlEncoded.append("grpHead", values?.groupHead);
    }
    if (values?.manager) {
      urlEncoded.append("manager", values?.manager);
    }
    urlEncoded.append(
      "compName",
      values?.companyName?.replace(/\s+/g, " ").trim()
    );
    urlEncoded.append("add1", values?.address1);
    urlEncoded.append("add2", values?.address2);
    if (values?.state) {
      urlEncoded.append("state", values?.state);
    }
    if (values?.zone) {
      urlEncoded.append("zone", values?.zone);
    }
    if (values?.city) {
      urlEncoded.append("city", values?.city);
    }
    urlEncoded.append("brand", JSON.stringify(values?.brands));
    if (values?.category) {
      urlEncoded.append("category", values?.category);
    }
    if (values?.subCategory) {
      urlEncoded.append("subCategory", values?.subCategory);
    }
    if (values?.title && values?.firstName) {
      urlEncoded.append("firstName", `${values?.title} ${values?.firstName}`);
    } else {
      urlEncoded.append("firstName", ``);
    }
    if (values?.lastName) {
      urlEncoded.append("lastName", values?.lastName);
    } else {
      urlEncoded.append("lastName", "");
    }
    if (values?.designation) {
      urlEncoded.append(
        "designation",
        values?.designation?.replace(/\s+/g, " ").trim()
      );
    }
    if (values?.contactNumber) {
      urlEncoded.append("contact", values?.contactNumber);
    }
    if (values?.emailId) {
      urlEncoded.append("email", values?.emailId);
    }
    urlEncoded.append(
      "directCustomer",
      values?.directCustomer == "No" ? false : true
    );
    urlEncoded.append("remark", values?.remark);

    urlEncoded.append("agencyName", values?.agencyName);

    if (values?.fct) {
      urlEncoded.append("fct", values?.fct);
    }
    if (values?.nfct) {
      urlEncoded.append("nfct", values?.nfct);
    }
    urlEncoded.append(`socialCause`, JSON.stringify(values?.socialCause));
    urlEncoded.append("partenership", values?.impactPartnerShip);
    urlEncoded.append(`passionate`, JSON.stringify(values?.passion));
    urlEncoded.append("familyDetails", JSON.stringify(values?.familyDetails));
    urlEncoded.append("agencyDetails", JSON.stringify(values?.agencyDetails));
    if (state?.advertiserLeadData) {
      urlEncoded.append("leadId", state?.advertiserLeadData?._id);
    }
    urlEncoded.append(`celebrities`, JSON.stringify(values?.favCelebrity));
    urlEncoded.append(
      "billingCompName",
      values?.billingCompName?.replace(/\s+/g, " ").trim()
    );
    urlEncoded.append("billingAddress", values?.address);
    urlEncoded.append("billingContact", values?.number);
    urlEncoded.append("billingEmail", values?.email);
    urlEncoded.append("gstNo", values?.GSTNo);
    urlEncoded.append("panNo", values?.PANNo);
    urlEncoded.append("isDraft", values?.isDraft);
    urlEncoded.append(
      "events",
      JSON.stringify(
        values?.events.map((data) => ({
          eventName: data?.eventName,
          date: PagesIndex.moment(new Date(data?.date)).format("YYYY-MM-DD"),
        }))
      )
    );

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_ADVERTISER, urlEncoded)
      .then((res) => {
        setButtonSpinner(false);
        setDraftButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          // if (values?.isDraft) {
          navigate(`/admin/clients`);
          // } else {
          //   navigate(`/admin/view-client/${id ? id : res?.data?._id}`, {
          //     state: {
          //       page: 3,
          //     },
          //   });
          // }
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  const handleShowBillingDetailsSection = () => {
    setShowBillingDetails((prev) => !prev);
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {id ? "Edit" : "Add"} Client
        </Index.Typography>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleAddEditAdvertiser}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit} noValidate="novalidate">
            {/* <ScrollIntoView /> */}
            <Index.Box className="common-card advertisement-card">
              <Index.Box className="grid-row">
                <Index.Box className="grid-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      NC 360 Details
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          <span>Account Director</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={accDirectorDropDown}
                              // onClose={() => setAccDirectorDropDown(false)}
                              className="admin-auto-complete-control"
                              options={prerequisiteData?.accountDirector || []}
                              id="accountDirector"
                              value={
                                values?.accountDirector &&
                                prerequisiteData?.accountDirector &&
                                prerequisiteData?.accountDirector?.find(
                                  (o) => o._id === values?.accountDirector
                                )
                                  ? prerequisiteData?.accountDirector?.find(
                                      (o) => o._id === values?.accountDirector
                                    )
                                  : values?.accountDirector || null
                              }
                              closeText={""}
                              openText={""}
                              clearText={""}
                              onChange={(e, value) => {
                                setFieldValue("accountDirector", value?._id);
                              }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.name}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.name
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  name="accountDirector"
                                  placeholder="Select Account Director"
                                  variant="outlined"
                                  // onChange={() => setAccDirectorDropDown(true)}
                                />
                              )}
                            />
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.accountDirector && touched.accountDirector
                              ? errors.accountDirector
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Group Head
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={groupHeadDropDown}
                              // onClose={() => setGroupHeadDropDown(false)}
                              className="admin-auto-complete-control"
                              options={prerequisiteData?.groupHead || []}
                              id="groupHead"
                              value={
                                values?.groupHead &&
                                prerequisiteData?.groupHead &&
                                prerequisiteData?.groupHead?.find(
                                  (o) => o._id === values?.groupHead
                                )
                                  ? prerequisiteData?.groupHead?.find(
                                      (o) => o._id === values?.groupHead
                                    )
                                  : values?.groupHead || null
                              }
                              closeText={""}
                              openText={""}
                              clearText={""}
                              onChange={(e, value) => {
                                setFieldValue("groupHead", value?._id);
                              }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.name}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.name
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  name="groupHead"
                                  placeholder="Select Group Head"
                                  variant="outlined"
                                  // onChange={() => setGroupHeadDropDown(true)}
                                />
                              )}
                            />
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.groupHead && touched.groupHead
                              ? errors.groupHead
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Manager
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={managerDropDown}
                              // onClose={() => setManagerDropDown(false)}
                              className="admin-auto-complete-control"
                              options={prerequisiteData?.manager || []}
                              id="manager"
                              value={
                                values?.manager &&
                                prerequisiteData?.manager &&
                                prerequisiteData?.manager?.find(
                                  (o) => o._id === values?.manager
                                )
                                  ? prerequisiteData?.manager?.find(
                                      (o) => o._id === values?.manager
                                    )
                                  : values?.manager || null
                              }
                              closeText={""}
                              openText={""}
                              clearText={""}
                              onChange={(e, value) => {
                                setFieldValue("manager", value?._id);
                              }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.name}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.name
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  name="manager"
                                  placeholder="Select Manager"
                                  variant="outlined"
                                  // onChange={() => setManagerDropDown(true)}
                                />
                              )}
                            />
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.manager && touched.manager
                              ? errors.manager
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="common-card advertisement-card">
              <Index.Box className="grid-row">
                <Index.Box className="grid-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Company Details
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Direct Customer
                        </Index.FormHelperText>
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.Box className="admin-dropdown-box">
                            <Index.FormControl className="admin-form-control admin-drop-form-control">
                              <Index.Select
                                className="admin-dropdown-select admin-drop-select"
                                name="directCustomer"
                                id="directCustomer"
                                value={values?.directCustomer}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                renderValue={
                                  values?.directCustomer
                                    ? undefined
                                    : () => "Select Direct Customer"
                                }
                              >
                                <Index.MenuItem
                                  value={"Yes"}
                                  className="admin-drop-menuitem"
                                >
                                  Yes
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value={"No"}
                                  className="admin-drop-menuitem"
                                >
                                  No
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Company Name<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="companyName"
                            name="companyName"
                            value={values?.companyName}
                            onChange={handleChange}
                            className="admin-form-control"
                            placeholder="Enter Company Name"
                            inputProps={{ maxLength: 100 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.companyName && touched.companyName
                              ? errors.companyName
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      {/* <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Address 1<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.TextField
                              open={add1DropDown}
                              onOpen={() => {
                                setAdd1DropDown(true);
                              }}
                              onClose={() => setAdd1DropDown(false)}
                              className="admin-auto-complete-filed"
                              options={advertiserData?.address1List}
                              id="address1"
                              disableClearable
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={values?.address1}
                              onChange={(e, selectedOptions) => {
                                if (!e.key) {
                                  setFieldValue("address1", selectedOptions);
                                }
                              }}
                              renderOption={(props, item) => (
                                <li {...props} key={item}>
                                  {item}
                                </li>
                              )}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder={"Enter Address 1"}
                                  {...params}
                                  name="address1"
                                  size="small"
                                  variant="outlined"
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      setAdd1DropDown(false);
                                      e.preventDefault();
                                      const existData =
                                        advertiserData?.address1List.find(
                                          (data) =>
                                            data?.toLowerCase() ===
                                            e.target.value?.toLowerCase()
                                        );
                                      if (existData) {
                                        setFieldValue("address1", existData);
                                      } else {
                                        setFieldValue(
                                          "address1",
                                          e.target.value
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.address1 && touched.address1
                            ? errors.address1
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box> */}

                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Address 1<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="address1"
                            name="address1"
                            value={values?.address1}
                            onChange={handleChange}
                            className="admin-form-control"
                            placeholder="Enter Adress 1"
                            inputProps={{ maxLength: 500 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.address1 && touched.address1
                              ? errors.address1
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      {/* <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Address 2
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              open={add2DropDown}
                              onOpen={() => {
                                setAdd2DropDown(true);
                              }}
                              onClose={() => setAdd2DropDown(false)}
                              className="admin-auto-complete-filed"
                              options={advertiserData?.address2List}
                              id="address2"
                              disableClearable
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={values?.address2}
                              onChange={(e, selectedOptions) => {
                                if (!e.key) {
                                  setFieldValue("address2", selectedOptions);
                                }
                              }}
                              getOptionLabel={(option) => option}
                              renderOption={(props, item) => (
                                <li {...props} key={item}>
                                  {item}
                                </li>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder={"Enter Address 2"}
                                  {...params}
                                  size="small"
                                  name="address2"
                                  variant="outlined"
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      setAdd2DropDown(false);
                                      e.preventDefault();
                                      const existData =
                                        advertiserData?.address2List.find(
                                          (data) =>
                                            data?.toLowerCase() ===
                                            e.target.value?.toLowerCase()
                                        );
                                      if (existData) {
                                        setFieldValue("address2", existData);
                                      } else {
                                        setFieldValue(
                                          "address2",
                                          e.target.value
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.address2 && touched.address2
                            ? errors.address2
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box> */}

                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Address 2
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="address2"
                            name="address2"
                            value={values?.address2}
                            onChange={handleChange}
                            className="admin-form-control"
                            placeholder="Enter Adress 2"
                            inputProps={{ maxLength: 500 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.address2 && touched.address2
                              ? errors.address2
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 2",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          State<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={stateDropDown}
                              // onClose={() => setStateDropDown(false)}
                              className="admin-auto-complete-control"
                              options={prerequisiteData?.state || []}
                              id="state"
                              value={
                                values?.state &&
                                prerequisiteData?.state &&
                                prerequisiteData?.state?.find(
                                  (o) => o._id === values?.state
                                )
                                  ? prerequisiteData?.state?.find(
                                      (o) => o._id === values?.state
                                    )
                                  : values?.state || null
                              }
                              closeText={""}
                              openText={""}
                              clearText={""}
                              onChange={(e, value) => {
                                setFieldValue("state", value?._id);
                              }}
                              getOptionLabel={(option) => option?.stateName}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.stateName}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.stateName
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  name="state"
                                  placeholder="Select State"
                                  variant="outlined"
                                  // onChange={() => {
                                  //   setStateDropDown(true);
                                  // }}
                                />
                              )}
                            />
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.state && touched.state
                              ? errors.state
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 2",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Zone<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={zoneDropDown}
                              // onClose={() => setZoneDropDown(false)}
                              className="admin-auto-complete-control"
                              options={prerequisiteData?.zone || []}
                              id="zone"
                              value={
                                values?.zone && prerequisiteData?.zone
                                  ? prerequisiteData?.zone?.find(
                                      (o) => o._id === values?.zone
                                    )
                                  : values?.zone || null
                              }
                              closeText={""}
                              openText={""}
                              clearText={""}
                              onChange={(e, value) => {
                                setFieldValue("zone", value?._id);
                              }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.name}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.name
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  name="zone"
                                  placeholder="Select Zone"
                                  variant="outlined"
                                  onChange={() => {
                                    setZoneDropDown(true);
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.zone && touched.zone ? errors.zone : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 2",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          City<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              open={cityDropDown}
                              onClose={() => setCityDropDown(false)}
                              className="admin-auto-complete-filed"
                              options={advertiserData?.cityList}
                              id="city"
                              disableClearable
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={values?.city}
                              onChange={(e, selectedOptions) => {
                                if (!e.key) {
                                  setFieldValue("city", selectedOptions);
                                }
                              }}
                              getOptionLabel={(option) => option}
                              renderOption={(props, item) => (
                                <li {...props} key={item}>
                                  {item}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder={"Select City"}
                                  {...params}
                                  size="small"
                                  name="city"
                                  variant="outlined"
                                  onChange={() => {
                                    setCityDropDown(true);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Tab") {
                                      e.preventDefault();
                                      var inputs =
                                        document.getElementsByTagName("input");
                                      for (var i = 0; i < inputs.length; i++) {
                                        if (
                                          document.activeElement.id ==
                                            inputs[i].id &&
                                          i + 1 < inputs.length
                                        ) {
                                          inputs[i + 1].focus();
                                          break;
                                        }
                                      }
                                      setCityDropDown(false);
                                      const existData =
                                        advertiserData?.cityList.find(
                                          (data) =>
                                            data?.toLowerCase() ===
                                            e.target.value?.toLowerCase()
                                        );
                                      if (existData) {
                                        setFieldValue("city", existData);
                                      } else {
                                        setFieldValue("city", e.target.value);
                                      }
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.city && touched.city ? errors.city : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 3",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Category<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={catDropDown}
                              // onClose={() => setCatDropDown(false)}
                              className="admin-auto-complete-control"
                              options={prerequisiteData?.category || []}
                              id="category"
                              value={
                                values?.category
                                  ? prerequisiteData?.category?.find(
                                      (o) => o._id === values?.category
                                    ) || null
                                  : null
                              }
                              closeText={""}
                              openText={""}
                              clearText={""}
                              disableClearable
                              onChange={(e, value) => {
                                setFieldValue("category", value?._id);
                                setFieldTouched("category", false);
                                setFieldValue("subCategory", "");
                              }}
                              getOptionLabel={(option) => option?.categoryName}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.categoryName}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.categoryName
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  name="zone"
                                  placeholder="Select category"
                                  variant="outlined"
                                  // onChange={(e) => {
                                  //   setCatDropDown(true);
                                  // }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.category && touched.category
                            ? errors.category
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 3",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Sub Category<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              // open={subCatDropDown}
                              // onOpen={() => {
                              //   setSubCatDropDown(true);
                              // }}
                              onClose={() => setSubCatDropDown(false)}
                              className="admin-auto-complete-filed"
                              options={
                                prerequisiteData?.subCategory?.filter(
                                  (data) =>
                                    data?.categoryId === values?.category
                                ) || []
                              }
                              id="subCategory"
                              disableClearable
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={
                                values?.subCategory &&
                                prerequisiteData?.subCategory &&
                                prerequisiteData?.subCategory?.find(
                                  (o) => o._id === values?.subCategory
                                )
                                  ? prerequisiteData?.subCategory?.find(
                                      (o) => o._id === values?.subCategory
                                    )
                                  : null
                              }
                              onChange={(e, value) => {
                                setFieldValue("subCategory", value?._id);
                              }}
                              getOptionLabel={(option) =>
                                option?.subCategoryName
                              }
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.subCategoryName}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.subCategoryName
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder={"Select Sub Category"}
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  name="subCategory"
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.subCategory && touched.subCategory
                            ? errors.subCategory
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card advertisement-card">
              <Index.Box className="grid-row">
                <Index.Box className="grid-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Client Personal Details
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          First Name<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.Box className="gender-drpdown-flex">
                            <Index.Box className="admin-dropdown-box gender-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  id="title"
                                  name="title"
                                  value={values?.title}
                                  onChange={handleChange}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <Index.MenuItem
                                    value="Mr."
                                    className="admin-drop-menuitem"
                                  >
                                    Mr.
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value={"Ms."}
                                    className="admin-drop-menuitem"
                                  >
                                    Ms.
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value={"Mrs."}
                                    className="admin-drop-menuitem"
                                  >
                                    Mrs.
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              placeholder="Enter First Name"
                              id={"firstName"}
                              name={"firstName"}
                              value={values?.firstName}
                              onChange={handleChange}
                              inputProps={{ maxLength: 30 }}
                            />
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.firstName && touched.firstName
                              ? errors.firstName
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Last Name<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            placeholder="Enter Last Name"
                            id={"lastName"}
                            name={"lastName"}
                            value={values?.lastName}
                            onChange={handleChange}
                            inputProps={{ maxLength: 30 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.lastName && touched.lastName
                              ? errors.lastName
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Designation<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            name="designation"
                            value={values?.designation}
                            fullWidth
                            id="designation"
                            className="admin-form-control"
                            placeholder="Enter Designation"
                            onChange={handleChange}
                            // inputProps={{ maxLength: 50 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.designation && touched.designation
                              ? errors.designation
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 3",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Mobile Number<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            name="contactNumber"
                            value={values?.contactNumber}
                            fullWidth
                            id="contactNumber"
                            className="admin-form-control"
                            placeholder="Enter Mobile Number"
                            onChange={handleChange}
                            inputProps={{ maxLength: 10 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.contactNumber && touched.contactNumber
                              ? errors.contactNumber
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 5",
                        md: "span 5",
                        lg: "span 5",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Email Id<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            name="emailId"
                            value={values?.emailId}
                            fullWidth
                            id="emailId"
                            className="admin-form-control"
                            placeholder="Enter Email Id"
                            onChange={handleChange}
                            inputProps={{ maxLength: 50 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.emailId && touched.emailId
                              ? errors.emailId
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 2",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          FCT
                        </Index.FormHelperText>
                        <Index.Box className="admin-dropdown-box">
                          <Index.FormControl className="admin-form-control admin-drop-form-control">
                            <Index.Select
                              className="admin-dropdown-select admin-drop-select"
                              id="fct"
                              name="fct"
                              value={values?.fct}
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              renderValue={
                                values?.fct ? undefined : () => "Select FCT"
                              }
                            >
                              <Index.MenuItem
                                value={"true"}
                                className="admin-drop-menuitem"
                              >
                                Yes
                              </Index.MenuItem>
                              <Index.MenuItem
                                value={"false"}
                                className="admin-drop-menuitem"
                              >
                                No
                              </Index.MenuItem>
                            </Index.Select>
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.fct && touched.fct ? errors.fct : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 2",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          NFCT
                        </Index.FormHelperText>
                        <Index.Box className="admin-dropdown-box">
                          <Index.FormControl className="admin-form-control admin-drop-form-control">
                            <Index.Select
                              className="admin-dropdown-select admin-drop-select"
                              id="nfct"
                              name="nfct"
                              value={values?.nfct}
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              renderValue={
                                values?.nfct ? undefined : () => "Select NFCT1"
                              }
                            >
                              <Index.MenuItem
                                value={"true"}
                                className="admin-drop-menuitem"
                              >
                                Yes
                              </Index.MenuItem>
                              <Index.MenuItem
                                value={"false"}
                                className="admin-drop-menuitem"
                              >
                                No
                              </Index.MenuItem>
                            </Index.Select>
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.nfct && touched.nfct ? errors.nfct : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                        <Index.FormHelperText className="admin-form-lable">
                          <span>Favourite Celebrities</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              open={favCelebDropDown}
                              onClose={() => setFavCelebDropDown(false)}
                              className="admin-auto-complete-filed"
                              options={advertiserData?.celebrityList}
                              id="favCelebrity"
                              multiple
                              disableCloseOnSelect
                              freeSolo
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={values?.favCelebrity}
                              onChange={(e, selectedOptions) => {
                                if (!e.key) {
                                  setFieldValue(
                                    "favCelebrity",
                                    selectedOptions.map((option) => option)
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option}
                              renderOption={(props, item) => (
                                <li {...props} key={item}>
                                  {item}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  name="favCelebrity"
                                  placeholder={
                                    values?.favCelebrity?.length
                                      ? ""
                                      : "Enter Favourite Celebrity"
                                  }
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setFavCelebDropDown(true);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.target.value && e.key === "Enter") {
                                      e.preventDefault();
                                      const existData =
                                        advertiserData?.celebrityList.find(
                                          (data) =>
                                            data?.toLowerCase() ===
                                            e.target.value?.toLowerCase()
                                        );
                                      if (existData) {
                                        setFieldValue("favCelebrity", [
                                          ...new Set([
                                            ...values?.favCelebrity,
                                            existData,
                                          ]),
                                        ]);
                                      } else {
                                        setFieldValue("favCelebrity", [
                                          ...values?.favCelebrity,
                                          e.target.value,
                                        ]);
                                      }
                                    } else if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.favCelebrity && touched.favCelebrity
                            ? errors.favCelebrity
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Passions
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              open={passionDropDown}
                              onClick={() => setPassionDropDown(false)}
                              className="admin-auto-complete-filed"
                              options={advertiserData?.passionList}
                              id="passion"
                              multiple
                              disableClearable
                              freeSolo
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={values?.passion}
                              onChange={(e, selectedOptions) => {
                                if (!e.key) {
                                  setFieldValue(
                                    "passion",
                                    selectedOptions.map((option) => option)
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option}
                              renderOption={(props, item) => (
                                <li {...props} key={item}>
                                  {item}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  name="passion"
                                  placeholder={
                                    values?.passion?.length
                                      ? ""
                                      : "Enter Passion"
                                  }
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setPassionDropDown(true);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.target.value && e.key === "Enter") {
                                      e.preventDefault();
                                      const existData =
                                        advertiserData?.passionList.find(
                                          (data) =>
                                            data?.toLowerCase() ===
                                            e.target.value?.toLowerCase()
                                        );
                                      if (existData) {
                                        setFieldValue("passion", [
                                          ...new Set([
                                            ...values?.passion,
                                            existData,
                                          ]),
                                        ]);
                                      } else {
                                        setFieldValue("passion", [
                                          ...values?.passion,
                                          e.target.value,
                                        ]);
                                      }
                                    } else if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.passion && touched.passion
                            ? errors.passion
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Social Causes
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              open={socialDropDown}
                              onClose={() => setSocialDropDown(false)}
                              className="admin-auto-complete-filed"
                              options={advertiserData?.socialCauseList}
                              id="socialCause"
                              multiple
                              disableClearable
                              freeSolo
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={values?.socialCause}
                              onChange={(e, selectedOptions) => {
                                if (!e.key) {
                                  setFieldValue(
                                    "socialCause",
                                    selectedOptions.map((option) => option)
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option}
                              renderOption={(props, item) => (
                                <li {...props} key={item}>
                                  {item}
                                </li>
                              )}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element
                                      .toLowerCase()
                                      .startsWith(
                                        state.inputValue.toLowerCase()
                                      )
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  name="socialCause"
                                  placeholder={
                                    values?.socialCause?.length
                                      ? ""
                                      : "Enter Social Cause"
                                  }
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setSocialDropDown(true);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.target.value && e.key === "Enter") {
                                      e.preventDefault();
                                      const existData =
                                        advertiserData?.socialCauseList.find(
                                          (data) =>
                                            data?.toLowerCase() ===
                                            e.target.value?.toLowerCase()
                                        );
                                      if (existData) {
                                        setFieldValue("socialCause", [
                                          ...new Set([
                                            ...values?.socialCause,
                                            existData,
                                          ]),
                                        ]);
                                      } else {
                                        setFieldValue("socialCause", [
                                          ...values?.socialCause,
                                          e.target.value,
                                        ]);
                                      }
                                    } else if (e.key === "Enter") {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.socialCause && touched.socialCause
                            ? errors.socialCause
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-radio-main">
                        <Index.FormHelperText className="admin-form-lable">
                          Impact Or Show Partnership
                        </Index.FormHelperText>
                        <Index.RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          id="impactPartnerShip"
                          name="impactPartnerShip"
                          className="admin-radiogroup"
                          value={values?.impactPartnerShip}
                          onChange={handleChange}
                        >
                          <Index.FormControlLabel
                            value="Impact"
                            control={<Index.Radio className="admin-radio" />}
                            label="Impact"
                          />
                          <Index.FormControlLabel
                            value="Show Partnership"
                            control={<Index.Radio className="admin-radio" />}
                            label="Show Partnership"
                          />
                        </Index.RadioGroup>
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.impactPartnerShip && touched.impactPartnerShip
                            ? errors.impactPartnerShip
                            : null}
                        </PagesIndex.FormHelperText>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-event-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Remark
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="remark"
                            name="remark"
                            value={values?.remark}
                            onChange={handleChange}
                            className="admin-form-control"
                            placeholder="Enter Remark"
                            inputProps={{ maxLength: 500 }}
                          />
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.remark && touched.remark
                              ? errors.remark
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                        {values?.familyDetails?.length ? (
                          <Index.FormHelperText className="admin-form-lable">
                            Family Details
                          </Index.FormHelperText>
                        ) : (
                          ""
                        )}
                        <Index.Box className="admin-form-group">
                          <Index.Box className="grid-row">
                            {values?.familyDetails?.length ? (
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 4",
                                    md: "span 4",
                                    lg: "span 4",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Name
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 4",
                                    md: "span 4",
                                    lg: "span 4",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Relation
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 4",
                                    md: "span 4",
                                    lg: "span 4",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="event-add-flex">
                                    <Index.Box className="admin-input-box admin-add-event-input-box">
                                      <Index.FormHelperText className="admin-form-lable">
                                        Comment
                                      </Index.FormHelperText>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            ) : (
                              ""
                            )}
                            <FieldArray name="familyDetails">
                              {({ push, remove }) => (
                                <Index.Box className="grid-main">
                                  <Index.Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                  >
                                    {values?.familyDetails?.map(
                                      (data, index) => {
                                        const Name = `familyDetails[${index}].name`;
                                        const touchedName = getIn(
                                          touched,
                                          Name
                                        );
                                        const errorName = getIn(errors, Name);

                                        const Relation = `familyDetails[${index}].relation`;
                                        const touchedRelation = getIn(
                                          touched,
                                          Relation
                                        );
                                        const errorRelation = getIn(
                                          errors,
                                          Relation
                                        );

                                        const Comment = `familyDetails[${index}].comment`;
                                        const touchedComment = getIn(
                                          touched,
                                          Comment
                                        );
                                        const errorComment = getIn(
                                          errors,
                                          Comment
                                        );

                                        return (
                                          <React.Fragment key={data?._id}>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 4",
                                                md: "span 4",
                                                lg: "span 4",
                                              }}
                                              className="grid-column"
                                            >
                                              <Index.Box className="admin-input-box admin-add-event-input-box">
                                                <Index.Box className="admin-form-group">
                                                  <Index.TextField
                                                    name={Name}
                                                    value={data?.name}
                                                    fullWidth
                                                    id={Name}
                                                    className="admin-form-control"
                                                    placeholder="Enter Name"
                                                    onChange={handleChange}
                                                    inputProps={{
                                                      maxLength: 100,
                                                    }}
                                                  />
                                                  <PagesIndex.FormHelperText className="admin-error-text">
                                                    {errorName && touchedName
                                                      ? errorName
                                                      : null}
                                                  </PagesIndex.FormHelperText>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 4",
                                                md: "span 4",
                                                lg: "span 4",
                                              }}
                                              className="grid-column"
                                            >
                                              <Index.Box className="admin-input-box admin-add-event-input-box">
                                                <Index.Box className="admin-dropdown-box">
                                                  <Index.TextField
                                                    name={Relation}
                                                    value={data?.relation}
                                                    fullWidth
                                                    id={Relation}
                                                    className="admin-form-control"
                                                    placeholder="Enter Relation"
                                                    onChange={handleChange}
                                                    inputProps={{
                                                      maxLength: 100,
                                                    }}
                                                  />
                                                  <PagesIndex.FormHelperText className="admin-error-text">
                                                    {errorRelation &&
                                                    touchedRelation
                                                      ? errorRelation
                                                      : null}
                                                  </PagesIndex.FormHelperText>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                            <Index.Box
                                              gridColumn={{
                                                xs: "span 12",
                                                sm: "span 4",
                                                md: "span 4",
                                                lg: "span 4",
                                              }}
                                              className="grid-column"
                                            >
                                              <Index.Box className="event-add-flex">
                                                <Index.Box className="admin-input-box admin-add-event-input-box">
                                                  <Index.Box className="admin-form-group">
                                                    <Index.TextField
                                                      name={Comment}
                                                      value={data?.comment}
                                                      fullWidth
                                                      id={Comment}
                                                      className="admin-form-control"
                                                      placeholder="Enter Comment"
                                                      onChange={handleChange}
                                                      inputProps={{
                                                        maxLength: 100,
                                                      }}
                                                    />
                                                    <PagesIndex.FormHelperText className="admin-error-text">
                                                      {errorComment &&
                                                      touchedComment
                                                        ? errorComment
                                                        : null}
                                                    </PagesIndex.FormHelperText>
                                                  </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="admin-input-box admin-add-event-input-box add-row-input-box">
                                                  <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                                    {index ===
                                                    values?.familyDetails
                                                      ?.length -
                                                      1 ? (
                                                      <Index.Button
                                                        className="orange-btn add-row-btn"
                                                        onClick={() => {
                                                          push({
                                                            id:
                                                              values
                                                                .familyDetails[
                                                                values
                                                                  .familyDetails
                                                                  ?.length - 1
                                                              ]?.id + 1 || 0,
                                                            name: "",
                                                            relation: "",
                                                            comment: "",
                                                          });
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            PagesIndex.Svg.plus
                                                          }
                                                          className="admin-incress-dicress-icon"
                                                          alt="Save"
                                                        />
                                                      </Index.Button>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <Index.Button
                                                      className="btn-primary add-event-btn "
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                    >
                                                      <img
                                                        src={
                                                          PagesIndex.Svg
                                                            .deleteicon
                                                        }
                                                        className="admin-incress-dicress-icon"
                                                        alt="Save"
                                                      />
                                                    </Index.Button>
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </Index.Box>
                                  {!values?.familyDetails?.length ? (
                                    <Index.Box className="btn-main-primary">
                                      <Index.Button
                                        className="btn-primary"
                                        onClick={() => {
                                          push({
                                            id:
                                              values.familyDetails[
                                                values.familyDetails?.length - 1
                                              ]?.id + 1 || 0,
                                            name: "",
                                            relation: "",
                                            comment: "",
                                          });
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.plus}
                                          className="admin-user-save-icon"
                                          alt="Save"
                                        />
                                        <span>Add Family Details</span>
                                      </Index.Button>
                                    </Index.Box>
                                  ) : (
                                    ""
                                  )}
                                </Index.Box>
                              )}
                            </FieldArray>
                          </Index.Box>
                        </Index.Box>
                        {/* <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.familyDetails && touched.familyDetails
                            ? errors.familyDetails
                            : null}
                        </PagesIndex.FormHelperText> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="common-card advertisement-card">
              <Index.Box className="grid-row">
                <Index.Box className="grid-main">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Advertisement Details
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <FieldArray name="brands">
                        {({ push, remove }) =>
                          values?.brands?.map((data, index) => {
                            const brandName = `brands[${index}].brandName`;
                            const touchedBrandName = getIn(touched, brandName);
                            const errorBrandName = getIn(errors, brandName);

                            const brandAmbassador = `brands[${index}].brandAmbassador`;
                            const touchedBrandAmbassador = getIn(
                              touched,
                              brandAmbassador
                            );
                            const errorBrandAmbassador = getIn(
                              errors,
                              brandAmbassador
                            );

                            const brandTagline = `brands[${index}].brandTagline`;
                            const touchedBrandTagline = getIn(
                              touched,
                              brandTagline
                            );
                            const errorBrandTagline = getIn(
                              errors,
                              brandTagline
                            );

                            const mediaToUse = `brands[${index}].mediaToUse`;
                            const touchedMediaToUse = getIn(
                              touched,
                              mediaToUse
                            );
                            const errorMediaToUse = getIn(errors, mediaToUse);

                            const advPeriod = `brands[${index}].adPeriod`;
                            const touchedAdvPeriod = getIn(touched, advPeriod);
                            const errorAdvPeriod = getIn(errors, advPeriod);

                            return (
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Brand Name
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                        <Index.Autocomplete
                                          open={brandDropDown === index}
                                          onClose={() => setBrandDropDown()}
                                          className="admin-auto-complete-filed"
                                          options={advertiserData?.brandList}
                                          id={brandName}
                                          disableClearable
                                          closeText={""}
                                          openText={""}
                                          clearText={""}
                                          value={data?.brandName}
                                          onChange={(e, selectedOptions) => {
                                            if (!e.key) {
                                              setFieldValue(
                                                brandName,
                                                selectedOptions
                                              );
                                            }
                                          }}
                                          getOptionLabel={(option) => option}
                                          renderOption={(props, item) => (
                                            <li {...props} key={item}>
                                              {item}
                                            </li>
                                          )}
                                          filterOptions={(options, state) => {
                                            let newOptions = [];
                                            options.forEach((element) => {
                                              if (
                                                element
                                                  ?.toLowerCase()
                                                  ?.startsWith(
                                                    state?.inputValue?.toLowerCase()
                                                  )
                                              )
                                                newOptions.push(element);
                                            });
                                            return newOptions;
                                          }}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              fullWidth
                                              className="admin-form-control"
                                              placeholder={"Enter Brand Name"}
                                              {...params}
                                              name={brandName}
                                              size="small"
                                              variant="outlined"
                                              onChange={(e) => {
                                                setBrandDropDown(index);
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Tab") {
                                                  e.preventDefault();
                                                  var inputs =
                                                    document.getElementsByTagName(
                                                      "input"
                                                    );
                                                  for (
                                                    var i = 0;
                                                    i < inputs.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      document.activeElement
                                                        .id == inputs[i].id &&
                                                      i + 1 < inputs.length
                                                    ) {
                                                      inputs[i + 1].focus();
                                                      break;
                                                    }
                                                  }
                                                  setBrandDropDown(false);
                                                  const existData =
                                                    advertiserData?.brandList.find(
                                                      (data) =>
                                                        data?.toLowerCase() ===
                                                        e.target.value?.toLowerCase()
                                                    );
                                                  if (existData) {
                                                    setFieldValue(
                                                      brandName,
                                                      existData
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      brandName,
                                                      e.target.value
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                      </Index.FormControl>
                                    </Index.Box>
                                    <PagesIndex.FormHelperText className="admin-error-text">
                                      {errorBrandName && touchedBrandName
                                        ? errorBrandName
                                        : null}
                                    </PagesIndex.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      <span>Brand Slogan / Tagline</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                        <Index.Autocomplete
                                          open={brandTagDropDown === index}
                                          onClose={() => setBrandTagDropDown()}
                                          className="admin-auto-complete-filed"
                                          options={
                                            advertiserData?.brandSloganList
                                          }
                                          id={brandTagline}
                                          disableClearable
                                          closeText={""}
                                          openText={""}
                                          clearText={""}
                                          value={data?.brandTagline}
                                          onChange={(e, selectedOptions) => {
                                            if (!e.key) {
                                              setFieldValue(
                                                brandTagline,
                                                selectedOptions
                                              );
                                            }
                                          }}
                                          getOptionLabel={(option) => option}
                                          renderOption={(props, item) => (
                                            <li {...props} key={item}>
                                              {item}
                                            </li>
                                          )}
                                          filterOptions={(options, state) => {
                                            let newOptions = [];
                                            options.forEach((element) => {
                                              if (
                                                element
                                                  ?.toLowerCase()
                                                  ?.startsWith(
                                                    state?.inputValue?.toLowerCase()
                                                  )
                                              )
                                                newOptions.push(element);
                                            });
                                            return newOptions;
                                          }}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              fullWidth
                                              className="admin-form-control"
                                              placeholder={
                                                "Enter Brand Slogan/Tagline"
                                              }
                                              {...params}
                                              size="small"
                                              variant="outlined"
                                              name={brandTagline}
                                              onChange={(e) => {
                                                setBrandTagDropDown(index);
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Tab") {
                                                  e.preventDefault();
                                                  var inputs =
                                                    document.getElementsByTagName(
                                                      "input"
                                                    );
                                                  for (
                                                    var i = 0;
                                                    i < inputs.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      document.activeElement
                                                        .id == inputs[i].id &&
                                                      i + 1 < inputs.length
                                                    ) {
                                                      inputs[i + 1].focus();
                                                      break;
                                                    }
                                                  }
                                                  setBrandTagDropDown();
                                                  const existData =
                                                    advertiserData?.brandSloganList.find(
                                                      (data) =>
                                                        data?.toLowerCase() ===
                                                        e.target.value?.toLowerCase()
                                                    );
                                                  if (existData) {
                                                    setFieldValue(
                                                      brandTagline,
                                                      existData
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      brandTagline,
                                                      e.target.value
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                      </Index.FormControl>
                                    </Index.Box>
                                    <PagesIndex.FormHelperText className="admin-error-text">
                                      {errorBrandTagline && touchedBrandTagline
                                        ? errorBrandTagline
                                        : null}
                                    </PagesIndex.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      <span>Brand Ambassador</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                        <Index.Autocomplete
                                          open={brandAmbDropDown === index}
                                          onClose={() => setBrandAmbDropDown()}
                                          className="admin-auto-complete-filed"
                                          options={
                                            advertiserData?.brandAmbassadorList
                                          }
                                          id={brandAmbassador}
                                          disableClearable
                                          closeText={""}
                                          openText={""}
                                          clearText={""}
                                          value={data?.brandAmbassador}
                                          onChange={(e, selectedOptions) => {
                                            if (!e.key) {
                                              setFieldValue(
                                                brandAmbassador,
                                                selectedOptions
                                              );
                                            }
                                          }}
                                          getOptionLabel={(option) => option}
                                          renderOption={(props, item) => (
                                            <li {...props} key={item}>
                                              {item}
                                            </li>
                                          )}
                                          filterOptions={(options, state) => {
                                            let newOptions = [];
                                            options.forEach((element) => {
                                              if (
                                                element
                                                  ?.toLowerCase()
                                                  ?.startsWith(
                                                    state?.inputValue?.toLowerCase()
                                                  )
                                              )
                                                newOptions.push(element);
                                            });
                                            return newOptions;
                                          }}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              fullWidth
                                              className="admin-form-control"
                                              placeholder={
                                                "Enter Brand Ambassador"
                                              }
                                              {...params}
                                              size="small"
                                              variant="outlined"
                                              name={brandAmbassador}
                                              onChange={(e) => {
                                                setBrandAmbDropDown(index);
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Tab") {
                                                  e.preventDefault();
                                                  var inputs =
                                                    document.getElementsByTagName(
                                                      "input"
                                                    );
                                                  for (
                                                    var i = 0;
                                                    i < inputs.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      document.activeElement
                                                        .id == inputs[i].id &&
                                                      i + 1 < inputs.length
                                                    ) {
                                                      inputs[i + 1].focus();
                                                      break;
                                                    }
                                                  }
                                                  setBrandAmbDropDown();
                                                  const existData =
                                                    advertiserData?.brandAmbassadorList.find(
                                                      (data) =>
                                                        data?.toLowerCase() ===
                                                        e.target.value?.toLowerCase()
                                                    );
                                                  if (existData) {
                                                    setFieldValue(
                                                      brandAmbassador,
                                                      existData
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      brandAmbassador,
                                                      e.target.value
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                      </Index.FormControl>
                                    </Index.Box>
                                    <PagesIndex.FormHelperText className="admin-error-text">
                                      {errorBrandAmbassador &&
                                      touchedBrandAmbassador
                                        ? errorBrandAmbassador
                                        : null}
                                    </PagesIndex.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box input-complete-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Media Platform Use
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                        <Index.Autocomplete
                                          open={mediaToUseDropDown === index}
                                          onOpen={() =>
                                            setMediaToUseDropDown(index)
                                          }
                                          onClose={() =>
                                            setMediaToUseDropDown()
                                          }
                                          id={mediaToUse}
                                          className="admin-auto-complete-filed"
                                          options={mediaPlatform || []}
                                          multiple
                                          disableCloseOnSelect
                                          closeText={""}
                                          openText={""}
                                          clearText={""}
                                          value={
                                            data?.mediaToUse
                                              ? mediaPlatform?.filter((elem) =>
                                                  data?.mediaToUse?.some(
                                                    (item) =>
                                                      item?._id === elem?._id
                                                  )
                                                )
                                              : []
                                          }
                                          onChange={(e, selectedOptions) => {
                                            setFieldValue(
                                              mediaToUse,
                                              selectedOptions.map((option) => ({
                                                _id: option?._id,
                                                name: option?.name,
                                              }))
                                            );
                                          }}
                                          getOptionLabel={(option) =>
                                            option?.name
                                          }
                                          renderOption={(props, item) => (
                                            <li {...props} key={item?._id}>
                                              {item?.name}
                                            </li>
                                          )}
                                          filterOptions={(options, state) => {
                                            let newOptions = [];
                                            options.forEach((element) => {
                                              if (
                                                element.name
                                                  ?.toLowerCase()
                                                  ?.startsWith(
                                                    state?.inputValue?.toLowerCase()
                                                  )
                                              )
                                                newOptions.push(element);
                                            });
                                            return newOptions;
                                          }}
                                          renderInput={(params) => (
                                            <Index.TextField
                                              name={mediaToUse}
                                              fullWidth
                                              className="admin-form-control"
                                              placeholder={
                                                data?.mediaToUse?.length
                                                  ? ""
                                                  : "Select Media Platform"
                                              }
                                              {...params}
                                              size="small"
                                              variant="outlined"
                                              // onChange={(e) => {
                                              //   setMediaToUseDropDown(index);
                                              // }}
                                            />
                                          )}
                                        />
                                      </Index.FormControl>
                                    </Index.Box>
                                    <PagesIndex.FormHelperText className="admin-error-text">
                                      {errorMediaToUse && touchedMediaToUse
                                        ? errorMediaToUse
                                        : null}
                                    </PagesIndex.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="event-add-flex">
                                    <Index.Box className="admin-input-box add-row-input-box admin-add-news-input-box input-complete-box">
                                      <Index.FormHelperText className="admin-form-lable">
                                        Normal Advertising Periods{" "}
                                      </Index.FormHelperText>
                                      <Index.Box className="admin-form-group">
                                        <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                          <Index.Autocomplete
                                            className="admin-auto-complete-filed"
                                            options={[
                                              "All",
                                              "January",
                                              "February",
                                              "March",
                                              "April",
                                              "May",
                                              "June",
                                              "July",
                                              "August",
                                              "September",
                                              "October",
                                              "November",
                                              "December",
                                            ]}
                                            id={advPeriod}
                                            multiple
                                            disableCloseOnSelect
                                            closeText={""}
                                            openText={""}
                                            clearText={""}
                                            value={data?.adPeriod}
                                            onChange={(e, selectedOptions) => {
                                              if (
                                                selectedOptions.includes("All")
                                              ) {
                                                setFieldValue("adPeriod", [
                                                  "January",
                                                  "February",
                                                  "March",
                                                  "April",
                                                  "May",
                                                  "June",
                                                  "July",
                                                  "August",
                                                  "September",
                                                  "October",
                                                  "November",
                                                  "December",
                                                ]);
                                              } else {
                                                setFieldValue(
                                                  advPeriod,
                                                  selectedOptions.map(
                                                    (option) => option
                                                  )
                                                );
                                              }
                                            }}
                                            getOptionLabel={(option) => option}
                                            renderOption={(props, item) => (
                                              <li {...props} key={item}>
                                                {item}
                                              </li>
                                            )}
                                            filterOptions={(options, state) => {
                                              let newOptions = [];
                                              options.forEach((element) => {
                                                if (
                                                  element
                                                    .toLowerCase()
                                                    .startsWith(
                                                      state.inputValue.toLowerCase()
                                                    )
                                                )
                                                  newOptions.push(element);
                                              });
                                              return newOptions;
                                            }}
                                            renderInput={(params) => (
                                              <Index.TextField
                                                fullWidth
                                                className="admin-form-control"
                                                placeholder={
                                                  values?.adPeriod?.length
                                                    ? ""
                                                    : "Enter Advertising Period"
                                                }
                                                {...params}
                                                name={advPeriod}
                                                size="small"
                                                variant="outlined"
                                              />
                                            )}
                                          />
                                        </Index.FormControl>
                                        <PagesIndex.FormHelperText className="admin-error-text">
                                          {errorAdvPeriod && touchedAdvPeriod
                                            ? errorAdvPeriod
                                            : null}
                                        </PagesIndex.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-input-box admin-add-news-input-box">
                                      <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                                      <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                        {index ===
                                        values?.brands?.length - 1 ? (
                                          <Index.Button
                                            className="orange-btn add-row-btn"
                                            onClick={() => {
                                              push({
                                                brandName: "",
                                                brandAmbassador: "",
                                                brandTagline: "",
                                                mediaToUse: [],
                                              });
                                            }}
                                          >
                                            <img
                                              src={PagesIndex.Svg.plus}
                                              className="admin-incress-dicress-icon"
                                              alt="Add"
                                            />
                                          </Index.Button>
                                        ) : (
                                          ""
                                        )}
                                        {values?.brands?.length > 1 && (
                                          <Index.Button
                                            className="btn-primary add-event-btn "
                                            onClick={() => remove(index)}
                                          >
                                            <img
                                              src={PagesIndex.Svg.deleteicon}
                                              className="admin-incress-dicress-icon"
                                              alt="Delete"
                                            />
                                          </Index.Button>
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            );
                          })
                        }
                      </FieldArray>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="common-card advertisement-card">
              <FieldArray name="agencyDetails">
                {({ push, remove }) => (
                  <Index.Box className="">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h2"
                        variant="h2"
                      >
                        Agency Details
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-event-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Agency Name
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  // open={agencyDropDown}
                                  // onClose={() => setAgencyDropDown(false)}
                                  className="admin-auto-complete-control"
                                  options={prerequisiteData?.agency || []}
                                  id="agencyName"
                                  value={
                                    values?.agencyName &&
                                    prerequisiteData?.agency &&
                                    prerequisiteData?.agency?.find(
                                      (o) => o._id === values?.agencyName
                                    )
                                      ? prerequisiteData?.agency?.find(
                                          (o) => o._id === values?.agencyName
                                        )
                                      : values?.agencyName || null
                                  }
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  onChange={(e, value) => {
                                    setFieldValue("agencyName", value?._id);
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.agencyName
                                  }
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.agencyName}
                                    </li>
                                  )}
                                  filterOptions={(options, state) => {
                                    let newOptions = [];
                                    options.forEach((element) => {
                                      if (
                                        element.agencyName
                                          .toLowerCase()
                                          .startsWith(
                                            state.inputValue.toLowerCase()
                                          )
                                      )
                                        newOptions.push(element);
                                    });
                                    return newOptions;
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      {...params}
                                      size="small"
                                      name="agencyName"
                                      placeholder="Select Agency Name"
                                      variant="outlined"
                                      // onChange={() => setAgencyDropDown(true)}
                                    />
                                  )}
                                />
                              </Index.FormControl>
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.agencyName && touched.agencyName
                                  ? errors.agencyName
                                  : null}
                              </PagesIndex.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        ></Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 3",
                                md: "span 3",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-input-box admin-add-event-input-box">
                                <Index.FormHelperText className="admin-form-lable">
                                  Role
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 3",
                                md: "span 3",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-input-box admin-add-event-input-box">
                                <Index.FormHelperText className="admin-form-lable">
                                  Name
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 2",
                                md: "span 2",
                                lg: "span 2",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="admin-input-box admin-add-event-input-box">
                                <Index.FormHelperText className="admin-form-lable">
                                  Mobile Number
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 4",
                                md: "span 4",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="event-add-flex">
                                <Index.Box className="admin-input-box admin-add-event-input-box">
                                  <Index.FormHelperText className="admin-form-lable">
                                    Email Id
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            {values?.agencyDetails?.map((data, index) => {
                              const Role = `agencyDetails[${index}].role`;
                              const touchedRole = getIn(touched, Role);
                              const errorRole = getIn(errors, Role);

                              const Name = `agencyDetails[${index}].name`;
                              const touchedName = getIn(touched, Name);
                              const errorName = getIn(errors, Name);

                              const AgencyContact = `agencyDetails[${index}].contact`;
                              const touchedcontact = getIn(
                                touched,
                                AgencyContact
                              );
                              const errorcontact = getIn(errors, AgencyContact);

                              const AgencyEmail = `agencyDetails[${index}].email`;
                              const touchedEmail = getIn(touched, AgencyEmail);
                              const errorEmail = getIn(errors, AgencyEmail);

                              return (
                                <React.Fragment key={data?._id}>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 3",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="admin-input-box admin-add-event-input-box">
                                      <Index.Box className="admin-dropdown-box">
                                        <Index.FormControl className="admin-form-control admin-drop-form-control">
                                          <Index.Select
                                            className="admin-dropdown-select admin-drop-select"
                                            name={Role}
                                            id={Role}
                                            value={data?.role}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            renderValue={
                                              data?.role
                                                ? undefined
                                                : () => "Select Role"
                                            }
                                          >
                                            {roleData?.map((data) => {
                                              return (
                                                <Index.MenuItem
                                                  key={data?.role}
                                                  value={data?.role}
                                                  className="admin-drop-menuitem"
                                                >
                                                  {data?.role}
                                                </Index.MenuItem>
                                              );
                                            })}
                                          </Index.Select>
                                        </Index.FormControl>
                                        <PagesIndex.FormHelperText className="admin-error-text">
                                          {errorRole && touchedRole
                                            ? errorRole
                                            : null}
                                        </PagesIndex.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 3",
                                      md: "span 3",
                                      lg: "span 3",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="admin-input-box admin-add-event-input-box">
                                      <Index.Box className="admin-form-group">
                                        <Index.TextField
                                          name={Name}
                                          value={data?.name}
                                          fullWidth
                                          id={Name}
                                          className="admin-form-control"
                                          placeholder="Enter Name"
                                          onChange={handleChange}
                                          inputProps={{ maxLength: 100 }}
                                        />
                                        <PagesIndex.FormHelperText className="admin-error-text">
                                          {errorName && touchedName
                                            ? errorName
                                            : null}
                                        </PagesIndex.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 2",
                                      md: "span 2",
                                      lg: "span 2",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="admin-input-box admin-add-event-input-box">
                                      <Index.Box className="admin-form-group">
                                        <Index.TextField
                                          name={AgencyContact}
                                          value={data?.contact}
                                          fullWidth
                                          id={AgencyContact}
                                          className="admin-form-control"
                                          placeholder="Enter Mobile Number"
                                          onChange={(e) => {
                                            const regex = /^[0-9]*$/;
                                            if (regex.test(e.target.value)) {
                                              setFieldValue(
                                                AgencyContact,
                                                e.target.value
                                              );
                                            }
                                          }}
                                          inputProps={{ maxLength: 10 }}
                                        />
                                        <PagesIndex.FormHelperText className="admin-error-text">
                                          {errorcontact && touchedcontact
                                            ? errorcontact
                                            : null}
                                        </PagesIndex.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 4",
                                      md: "span 4",
                                      lg: "span 4",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="event-add-flex">
                                      <Index.Box className="admin-input-box admin-add-event-input-box">
                                        <Index.Box className="admin-form-group">
                                          <Index.TextField
                                            name={AgencyEmail}
                                            value={data?.email}
                                            fullWidth
                                            id={AgencyEmail}
                                            className="admin-form-control"
                                            placeholder="Enter Email Id"
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 100 }}
                                          />
                                          <PagesIndex.FormHelperText className="admin-error-text">
                                            {errorEmail && touchedEmail
                                              ? errorEmail
                                              : null}
                                          </PagesIndex.FormHelperText>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="admin-input-box admin-add-event-input-box add-row-input-box">
                                        <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                          {index ===
                                          values?.agencyDetails?.length - 1 ? (
                                            <Index.Button
                                              className="orange-btn add-row-btn"
                                              onClick={() => {
                                                push({
                                                  id:
                                                    values.agencyDetails[
                                                      values.agencyDetails
                                                        ?.length - 1
                                                    ]?.id + 1 || 0,
                                                  name: "",
                                                  contct: "",
                                                  email: "",
                                                });
                                              }}
                                            >
                                              <img
                                                src={PagesIndex.Svg.plus}
                                                className="admin-incress-dicress-icon"
                                                alt="Save"
                                              />
                                            </Index.Button>
                                          ) : (
                                            ""
                                          )}
                                          {values?.agencyDetails?.length > 1 ? (
                                            <Index.Button
                                              className="btn-primary add-event-btn "
                                              onClick={() => remove(index)}
                                            >
                                              <img
                                                src={PagesIndex.Svg.deleteicon}
                                                className="admin-incress-dicress-icon"
                                                alt="Save"
                                              />
                                            </Index.Button>
                                          ) : (
                                            ""
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </React.Fragment>
                              );
                            })}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                )}
              </FieldArray>
            </Index.Box>
            <FieldArray name="events">
              {({ push, remove }) => (
                <>
                  {values?.events?.length ? (
                    <Index.Box className="common-card advertisement-card">
                      <Index.Box className="grid-row">
                        <Index.Box className="">
                          <Index.Box className="admin-sub-title-main">
                            <Index.Typography
                              className="admin-sub-title"
                              component="h2"
                              variant="h2"
                            >
                              Event
                            </Index.Typography>
                          </Index.Box>
                          {values?.events?.length ? (
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 4",
                                  lg: "span 4",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-input-box admin-add-event-input-box">
                                  <Index.FormHelperText className="admin-form-lable">
                                    Event<span className="astrick-sing">*</span>
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 5",
                                  md: "span 5",
                                  lg: "span 5",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="event-add-flex">
                                  <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main add-event-date-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Date
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                >
                                  {values?.events?.map((data, index) => {
                                    const eventName = `events[${index}].eventName`;
                                    const touchedEventName = getIn(
                                      touched,
                                      eventName
                                    );
                                    const errorEventName = getIn(
                                      errors,
                                      eventName
                                    );

                                    const date = `events[${index}].date`;
                                    const touchedDate = getIn(touched, date);
                                    const errorDate = getIn(errors, date);
                                    return (
                                      <React.Fragment key={data?._id}>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 4",
                                            lg: "span 4",
                                          }}
                                          className="grid-column"
                                        >
                                          <Index.Box className="admin-input-box admin-add-event-input-box">
                                            <Index.Box className="admin-dropdown-box">
                                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                                <Index.Select
                                                  className="admin-dropdown-select admin-drop-select"
                                                  id={eventName}
                                                  name={eventName}
                                                  value={data?.eventName}
                                                  onChange={handleChange}
                                                  displayEmpty
                                                  inputProps={{
                                                    "aria-label":
                                                      "Without label",
                                                  }}
                                                  renderValue={
                                                    data?.eventName
                                                      ? undefined
                                                      : () => "Select Event"
                                                  }
                                                >
                                                  {eventData?.map((data) => {
                                                    return (
                                                      <Index.MenuItem
                                                        key={data?._id}
                                                        value={data?._id}
                                                        className="admin-drop-menuitem"
                                                      >
                                                        {data?.name}
                                                      </Index.MenuItem>
                                                    );
                                                  })}
                                                </Index.Select>
                                              </Index.FormControl>
                                              <PagesIndex.FormHelperText className="admin-error-text">
                                                {errorEventName &&
                                                touchedEventName
                                                  ? errorEventName
                                                  : null}
                                              </PagesIndex.FormHelperText>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 5",
                                            md: "span 5",
                                            lg: "span 5",
                                          }}
                                          className="grid-column"
                                        >
                                          <Index.Box className="event-add-flex">
                                            <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main add-event-date-input-box">
                                              <Index.Box className="admin-form-group">
                                                <PagesIndex.LocalizationProvider
                                                  dateAdapter={
                                                    Index.AdapterDayjs
                                                  }
                                                >
                                                  <Index.DatePicker
                                                    open={
                                                      openCalender === index
                                                    }
                                                    onClose={() =>
                                                      setOpenCalender("")
                                                    }
                                                    name={date}
                                                    id={date}
                                                    className="admin-form-control admin-date-picker-control"
                                                    value={dayjs(data?.date)} // Use '01/01/2000' as the default value
                                                    onChange={(data) => {
                                                      setFieldValue(date, data);
                                                    }}
                                                    slotProps={{
                                                      textField: {
                                                        readOnly: true,
                                                        onClick: () =>
                                                          setOpenCalender(
                                                            index
                                                          ),
                                                      },
                                                      inputAdornment: {
                                                        onClick: () =>
                                                          setOpenCalender(
                                                            index
                                                          ), // This triggers on the calendar icon
                                                      },
                                                    }}
                                                    format="DD/MM/YYYY"
                                                  />
                                                </PagesIndex.LocalizationProvider>

                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                  {errorDate && touchedDate
                                                    ? errorDate
                                                    : null}
                                                </PagesIndex.FormHelperText>
                                              </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="admin-input-box admin-add-event-input-box add-row-input-box">
                                              <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                                {index ===
                                                values?.events?.length - 1 ? (
                                                  <Index.Button
                                                    className="orange-btn add-row-btn"
                                                    onClick={() => {
                                                      push({
                                                        id:
                                                          values.events[
                                                            values.events
                                                              ?.length - 1
                                                          ]?.id + 1 || 0,
                                                        eventName: "",
                                                        date: null,
                                                      });
                                                    }}
                                                  >
                                                    <img
                                                      src={PagesIndex.Svg.plus}
                                                      className="admin-incress-dicress-icon"
                                                      alt="Save"
                                                    />
                                                  </Index.Button>
                                                ) : (
                                                  ""
                                                )}
                                                {values?.events?.length >=
                                                  0 && (
                                                  <Index.Button
                                                    className="btn-primary add-row-btn"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        PagesIndex.Svg
                                                          .deleteicon
                                                      }
                                                      className="admin-incress-dicress-icon"
                                                      alt="Save"
                                                    />
                                                  </Index.Button>
                                                )}
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        {/* <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 2",
                                  md: "span 2",
                                  lg: "span 2",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-input-box admin-add-event-input-box">
                                  <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                                  <Index.Button
                                    className="btn-primary"
                                    onClick={() => remove(index)}
                                  >
                                    <img
                                      src={PagesIndex.Svg.trash}
                                      className="admin-user-save-icon"
                                      alt="Save"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box> */}
                                      </React.Fragment>
                                    );
                                  })}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          ) : (
                            ""
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    ""
                  )}
                  <Index.Box className="admin-user-btn-flex advertiser-btn">
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className="btn-primary"
                        onClick={handleShowBillingDetailsSection}
                      >
                        {showBillingDetails ? "Remove" : "Add"} Billing Details
                      </Index.Button>
                    </Index.Box>
                    {!values?.events?.length ? (
                      <Index.Box className="btn-main-primary">
                        <Index.Button
                          className="btn-primary"
                          onClick={() => {
                            push({
                              id:
                                values.events[values.events?.length - 1]?.id +
                                  1 || 0,
                              eventName: "",
                              date: null,
                            });
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.plus}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>Add Event</span>
                        </Index.Button>
                      </Index.Box>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </>
              )}
            </FieldArray>
            {showBillingDetails && (
              <Index.Box className="common-card advertisement-card">
                <Index.Box className="grid-row">
                  <Index.Box className="grid-main">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h2"
                        variant="h2"
                      >
                        Billing Details
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Company Name
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="billingCompName"
                              fullWidth
                              id="billingCompName"
                              className="admin-form-control"
                              placeholder="Enter Company Name"
                              onChange={handleChange}
                              value={values?.billingCompName}
                              error={
                                errors.billingCompName &&
                                touched.billingCompName
                              }
                              inputProps={{ maxLength: 40 }}
                            />
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.billingCompName && touched.billingCompName
                                ? errors.billingCompName
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Address
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="address"
                              fullWidth
                              id="address"
                              className="admin-form-control"
                              placeholder="Enter Address"
                              onChange={handleChange}
                              value={values?.address}
                              error={errors.address && touched.address}
                              // inputProps={{ maxLength: 40 }}
                            />
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.address && touched.address
                                ? errors.address
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Email Id
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="email"
                              fullWidth
                              id="email"
                              className="admin-form-control"
                              placeholder="Enter Email Id"
                              onChange={handleChange}
                              value={values?.email}
                              error={errors.email && touched.email}
                              inputProps={{ maxLength: 50 }}
                            />
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.email && touched.email
                                ? errors.email
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            GST No.
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="GSTNo"
                              fullWidth
                              id="GSTNo"
                              className="admin-form-control"
                              placeholder="Enter GST No."
                              onChange={(e) =>
                                setFieldValue(
                                  "GSTNo",
                                  e.target.value.toUpperCase()
                                )
                              }
                              value={values?.GSTNo}
                              error={errors.GSTNo && touched.GSTNo}
                              inputProps={{ maxLength: 15 }}
                            />

                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.GSTNo && touched.GSTNo
                                ? errors.GSTNo
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            PAN No.
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="PANNo"
                              fullWidth
                              id="PANNo"
                              className="admin-form-control"
                              placeholder="Enter PAN No."
                              onChange={(e) =>
                                setFieldValue(
                                  "PANNo",
                                  e.target.value.toUpperCase()
                                )
                              }
                              value={values?.PANNo}
                              error={errors.PANNo && touched.PANNo}
                              inputProps={{ maxLength: 10 }}
                            />
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.PANNo && touched.PANNo
                                ? errors.PANNo
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
            <Index.Box className="admin-user-btn-flex">
              <Index.Box className="border-btn-main">
                <Index.Button
                  className="border-btn"
                  onClick={() => navigate("/admin/clients")}
                  disabled={draftButtonSpinner || buttonSpinner}
                >
                  Discard
                </Index.Button>
              </Index.Box>
              <Index.Box className="border-btn-main">
                <Index.Button
                  className="border-btn save-draft-btn"
                  type="submit"
                  disabled={draftButtonSpinner || buttonSpinner}
                  onClick={() => {
                    setValidationSchema(PagesIndex?.adDraftSchema);
                    setFieldValue("isDraft", true);
                  }}
                >
                  {draftButtonSpinner ? (
                    <PagesIndex.Spinner />
                  ) : (
                    <span>
                      {values?.isDraft && id ? "Save" : "Save As Draft"}
                    </span>
                  )}
                </Index.Button>
              </Index.Box>
              <Index.Box className="btn-main-primary">
                <Index.Button
                  className={`${id ? "update-btn" : "save-btn"} btn-primary`}
                  type="submit"
                  onClick={() => {
                    setValidationSchema(PagesIndex?.adSchema);
                    setFieldValue("isDraft", false);
                  }}
                  disabled={buttonSpinner || draftButtonSpinner}
                >
                  {buttonSpinner ? (
                    <PagesIndex.Spinner />
                  ) : (
                    <>
                      <img
                        src={PagesIndex.Svg.save}
                        className="admin-user-save-icon"
                        alt="Save"
                      />
                      <span>{id ? "Update" : "Add"}</span>
                    </>
                  )}
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </form>
        )}
      </PagesIndex.Formik>
    </Index.Box>
  );
}

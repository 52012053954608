import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./programManagement.Reponsive.css";

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ProgramManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const [data, setData] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [programTypeData, setProgramTypeData] = useState([]);
  const [id, setId] = useState("");
  const [selectedProgramData, setSelectedProgramData] = useState();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [openCalender, setOpenCalender] = useState(false);
  const [openStartTime, setOpenStartTime] = useState(false);
  const [openEndTime, setOpenEndTime] = useState(false);

  const initialValues = {
    // programTypeId: id ? selectedProgramData?.programTypeId?._id : "",
    programName: id ? selectedProgramData?.programName : "",
    // date: id
    //   ? selectedProgramData?.date
    //     ? PagesIndex.dayjs(selectedProgramData?.date)
    //     : null
    //   : null,
    startTime: id
      ? selectedProgramData?.startTime
        ? PagesIndex.dayjs(selectedProgramData?.startTime)
        : null
      : null,
    endTime: id
      ? selectedProgramData?.endTime
        ? PagesIndex.dayjs(selectedProgramData?.endTime)
        : null
      : null,
    // difference: id ? selectedProgramData?.seconds : 0,
  };
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);

  const [activityLog, setActivityLog] = useState([]);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [activityLoading, setActivityLoading] = useState(true);
  const [filterLog, setFilterLog] = useState([]);

  const [openLogModal, setOpenLogModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.PROGRAM_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.currentprogramName !== data?.previousprogramName ||
            data?.currentName !== data?.previousName ||
            data?.previousStatus !== data?.currentStatus
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetProgramType();
    handleGetChannel();
    handleGetProgram();
  }, []);
  const handleGetProgram = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setData(res?.data);
        setFilteredData(res?.data);
      }
    });
  };
  const handleGetChannel = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CHANNELS).then((res) => {
      if (res.status === 200) {
        const activeZone = res?.data?.filter((channel) => channel?.isActive);

        setChannelData(activeZone);
      }
    });
  };
  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectedProgramData(data);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleGetProgramType = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM_TYPE).then((res) => {
      if (res.status === 200) {
        setProgramTypeData(res.data);
      }
    });
  };
  const handleAddEditProgram = (values) => {
    setButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    // urlEncoded.append("programTypeId", values?.programTypeId);
    // urlEncoded.append(
    //   "date",
    //   PagesIndex.dayjs(values?.date).format("YYYY-MM-DD")
    // );
    urlEncoded.append("startTime", values?.startTime);
    urlEncoded.append("endTime", values?.endTime);
    // urlEncoded.append("seconds", values?.difference);
    urlEncoded.append("programName", values?.programName);

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_PROGRAM, urlEncoded)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetProgram();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteProgram = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_PROGRAM, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetProgram();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.programName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.programTypeId?.type
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.updatedBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.ACTIVE_DEACTIVE_PROGRAM,
      urlEncoded
    ).then((res) => {
      setStatusButtonSpinner(false);
      if (res.status === 200) {
        handleCloseStatusModal();
        PagesIndex.toasterSuccess(res?.message);
        handleGetProgram();
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.programName}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes("program_edit") ||
              adminDetails?.roleId?.permissions?.includes(
                "program_delete"
              )) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Tooltip
                  title={data?.isActive ? "Active" : "Deactive"}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => {
                        handleOpenStatusModal(data?._id);
                      }}
                    />
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>
            )}

            {(adminDetails?.roleId?.permissions?.includes("program_edit") ||
              adminDetails?.roleId?.permissions?.includes("program_delete") ||
              adminDetails?.roleId?.permissions?.includes("program_log")) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  {adminDetails?.roleId?.permissions?.includes(
                    "program_edit"
                  ) && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          handleOpen("Edit", data?._id, data);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                  {adminDetails?.roleId?.permissions?.includes(
                    "program_log"
                  ) && (
                    <Index.Tooltip
                      title="Log"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenLogModal(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.logicon}
                          className="admin-icon"
                          alt="Log"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                  {adminDetails?.roleId?.permissions?.includes(
                    "program_delete"
                  ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "-";
        if (
          data?.currentprogramName !== data?.previousprogramName ||
          data?.currentType !== data?.previousType ||
          data?.previousStatus !== data?.currentStatus
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${
              data?.dateTime
                ? PagesIndex.moment(data?.dateTime).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "-"
            }`;
          }
        }
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            {updatedInfo !== "-" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={`${
                      data?.currentprogramName !== data?.previousprogramName
                        ? `${data?.updatedBy?.name} has updated program name from ${data?.previousprogramName} to ${data?.currentprogramName}`
                        : ""
                    }`}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.currentprogramName !==
                        data?.previousprogramName && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated program type name from{" "}
                          <span className="span-bold">
                            {data?.previousprogramName}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.currentprogramName + " "}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                  <Index.Tooltip
                    title={`${
                      data?.previousStatus !== data?.currentStatus
                        ? `${data?.updatedBy?.name} has updated status from ${
                            !data?.currentStatus ? "Active" : "Deactive"
                          } to ${data?.currentStatus ? "Active" : "Deactive"} `
                        : ""
                    }`}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.previousStatus !== data?.currentStatus && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated status from{" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };

  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Program List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {adminDetails?.roleId?.permissions?.includes("program_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      setId("");
                      handleOpen("Add");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Program</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box
            className={`admin-distric-table-main ${
              loading ? "" : "page-table-main"
            }`}
          >
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Program Name
                      </Index.TableCell>
                      {/* <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Platform
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Program Type Name
                      </Index.TableCell> */}

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Created By
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Updated By
                      </Index.TableCell>

                      {(adminDetails?.roleId?.permissions?.includes(
                        "program_edit"
                      ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "program_delete"
                        )) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="6%"
                          align="right"
                        >
                          Status
                        </Index.TableCell>
                      )}

                      {(adminDetails?.roleId?.permissions?.includes(
                        "program_edit"
                      ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "program_delete"
                        ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "program_log"
                        )) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                          align="right"
                        >
                          Action
                        </Index.TableCell>
                      )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {renderContent()}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditProgram}
          initialValues={initialValues}
          validationSchema={PagesIndex.programAddSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            let startTime = PagesIndex.dayjs(values?.startTime);
            let endTime = PagesIndex.dayjs(values?.endTime);
            return (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {addOrEdit} Program
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box
                    component={"form"}
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                    className="admin-modal-hgt-scroll cus-scrollbar"
                    id="scrollDiv"
                  >
                    {/* <ScrollIntoView /> */}
                    {/* <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Program Type<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          className="admin-auto-complete-control"
                          options={
                            programTypeData?.filter((data) => data?.isActive) ||
                            []
                          }
                          id="programTypeId"
                          closeText={""}
                          openText={""}
                          clearText={""}
                          value={
                            values?.programTypeId
                              ? programTypeData?.find(
                                  (o) => o._id === values?.programTypeId
                                )
                              : values?.programTypeId || null
                          }
                          onChange={(e, value) => {
                            setFieldValue("programTypeId", value?._id);
                          }}
                          getOptionLabel={(option) => option?.typeName}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.typeName}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              name="programTypeId"
                              {...params}
                              size="small"
                              variant="outlined"
                              placeholder="Select Program Type"
                            />
                          )}
                        />
                        {touched.programTypeId && errors.programTypeId && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.programTypeId}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Program Name<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="programName"
                          fullWidth
                          id="programName"
                          className="admin-form-control"
                          placeholder="Enter Program Name"
                          onChange={handleChange}
                          value={values?.programName}
                          inputProps={{ maxLength: 40 }}
                          // error={errors.programName && touched.programName}
                        />
                        {touched.programName && errors.programName && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.programName}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>Select Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalender}
                            onClose={() => {
                              setOpenCalender(false);
                            }}
                            className="admin-form-control admin-date-picker-control"
                            disablePast
                            value={values.date} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("date", date);
                              setFieldValue("startTime", null);
                              setFieldValue("endTime", null);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalender(true),
                              },
                              inputAdornment: {
                                onClick: () =>
                                  setOpenCalender(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.date && touched.date && errors.date}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>Select Start Time</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.TimePicker
                            open={openStartTime}
                            onClose={() => {
                              setOpenStartTime(false);
                            }}
                            views={["hours", "minutes", "seconds"]}
                            timeSteps={{ minutes: 1, seconds: 1 }}
                            className="admin-form-control admin-date-picker-control"
                            ampm={false}
                            value={values.startTime} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("startTime", date);
                              setFieldValue("endTime", null);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenStartTime(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenStartTime(true), // This triggers on the calendar icon
                              },
                            }}
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.startTime &&
                            touched.startTime &&
                            errors.startTime}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>Select End Time</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.TimePicker
                            open={openEndTime}
                            onClose={() => {
                              setOpenEndTime(false);
                            }}
                            disabled={!values?.startTime}
                            views={["hours", "minutes", "seconds"]}
                            timeSteps={{ minutes: 1, seconds: 1 }}
                            ampm={false}
                            className="admin-form-control admin-date-picker-control"
                            minTime={PagesIndex.dayjs(values.startTime).add(
                              10,
                              "seconds"
                            )}
                            value={values.endTime} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("endTime", date);
                              setFieldValue(
                                "difference",
                                date.diff(startTime, "seconds")
                              );
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenEndTime(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenEndTime(true), // This triggers on the calendar icon
                              },
                            }}
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.endTime && touched.endTime && errors.endTime}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Difference
                        <span className="astrick-sing">*</span>{" "}
                        <span className="option-condition-text">
                          (in seconds)
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="difference"
                          fullWidth
                          id="difference"
                          disabled
                          className="admin-form-control"
                          value={values?.difference}
                          inputProps={{ maxLength: 40 }}
                          // error={errors.programName && touched.programName}
                        />
                      </Index.Box>
                    </Index.Box> */}
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="border-btn-main">
                        <Index.Button
                          className="border-btn"
                          onClick={() => handleClose()}
                          disabled={buttonSpinner}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="btn-main-primary">
                        <Index.Button
                          className={`${
                            id ? "update-btn" : "save-btn"
                          } admin-save-user-btn btn-primary`}
                          type="submit"
                          disabled={buttonSpinner}
                        >
                          {buttonSpinner ? (
                            <PagesIndex.Spinner />
                          ) : (
                            <>
                              <img
                                src={PagesIndex.Svg.save}
                                className="admin-user-save-icon"
                                alt="Save"
                              />
                              <span>{id ? "Update" : "Add"}</span>
                            </>
                          )}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            );
          }}
        </PagesIndex.Formik>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this program? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteProgram}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main distric-activity-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="60%"
                          >
                            Activity Log
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            Updated Date & Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"program"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}

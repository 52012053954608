import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./dashBoard.css";
import "./dashBoard.responsive.css";
import OwlCarousel from "react-owl-carousel3";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const colorTheme = [
  "#111827",
  "#6b7280",
  "#c85803",
  "#000",
  "#b90005",
  "#3c0000",
  "#717171",
  "#dbd5ce",
  "#5cb37d",
  "#adb5bd",
  "#ffa447",
  "#59B4C3",
  "#D04848",
  "#173ab8",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function formatCount(count) {
  if (count >= 1e9) {
    return (count / 1e9).toFixed(1) + "B";
  } else if (count >= 1e6) {
    return (count / 1e6).toFixed(1) + "M";
  } else if (count >= 1e3) {
    return (count / 1e3).toFixed(1) + "K";
  } else {
    return count.toString(); // Return as string
  }
}

const generateRandomColors = (numColors) => {
  const colors = [];
  const usedIndexes = new Set();

  while (colors.length < numColors) {
    const randomIndex = Math.floor(Math.random() * colorTheme.length);
    if (!usedIndexes.has(randomIndex)) {
      colors.push(colorTheme[randomIndex]);
      usedIndexes.add(randomIndex);
    }
  }

  return colors;
};

export default function Dashboard() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [openRatingList, setOpenRatingList] = useState(false);
  const [openViewerList, setOpenViewerList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dashBoardData, setDashBoardData] = useState({});
  const [userContentData, setUserContentData] = useState({});
  const [userUploadedVideoData, setUserUploadedVideoData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCalenderFrom, setOpenCalenderFrom] = useState(false);
  const [openCalenderTo, setOpenCalenderTo] = useState(false);
  const [type, setType] = useState("today");
  const [previousType, setPreviousType] = useState("");
  const [newsId, setNewsId] = useState("");
  const [date, setDate] = useState("");

  const [newsRatingGraph, setNewsRatingGraph] = useState([]);
  const [districtWiseData, setDistrictWiseData] = useState([]);

  let viewsList = dashBoardData?.viewsList?.filter(
    (ele) => ele?.newsId?._id == newsId
  );
  let ratingList = dashBoardData?.ratingList?.filter(
    (ele) => ele?.newsId?._id == newsId
  );

  const handleOpen = () => setOpen(true);
  const handleClose = (value) => {
    formRef.current.resetForm();
    setOpen(false);
    if (value !== "submit") setType(previousType);
  };

  const formRef = useRef();
  const initialValues = {
    startDate: null,
    endDate: null,
  };

  const handleCloseRatingListModal = () => setOpenRatingList(false);
  const handleOpenRatingListModal = (newsNo) => {
    setOpenRatingList(true);
    setNewsId(newsNo);
  };

  const handleCloseViewerListModal = () => setOpenViewerList(false);
  const handleOpenViewerListModal = (newsNo) => {
    setOpenViewerList(true);
    setNewsId(newsNo);
  };

  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  useEffect(() => {
    if (!adminDetails?.roleId?.permissions?.includes("dashboard_view")) {
      navigate("/");
      dispatch(PagesIndex.AdminLogOutAction());
    }
  }, []);

  const renderLiveNews = () => {
    return dashBoardData?.totalLiveNews?.map((data) => {
      let reactionIcon;
      if (data?.rating != null) {
        if (data.rating > 0 && data.rating < 1) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction1}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 1 && data.rating < 2) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction2}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 2 && data.rating < 3) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction3}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 3 && data.rating <= 4) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction4}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else if (data.rating >= 4 && data.rating <= 5) {
          reactionIcon = (
            <img
              src={PagesIndex.Svg.reaction5}
              className="reaction-icon"
              alt="reaction"
            />
          );
        } else {
          reactionIcon = "-";
        }
      } else {
        reactionIcon = "-";
      }
      return (
        <Index.TableRow key={data?._id}>
          <Index.TableCell
            component="td"
            variant="td"
            className="table-td border-btn-main"
          >
            <Index.Link
              to={`/admin/view-video/${data?._id}`}
              className="border-btn border-link"
              target="_blank"
            >
              {data?.newsNo ? data?.newsNo : "-"}
            </Index.Link>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Tooltip
              title={data?.newsTitle}
              arrow
              placement="top"
              className="admin-tooltip"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                <span>{data?.newsTitle ? data?.newsTitle : "-"}</span>
              </Index.Typography>
            </Index.Tooltip>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.category?.length
                ? data?.category
                    ?.map((category) => category?.categoryName)
                    .join(", ")
                : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.district?.name ? data?.district?.name : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell
            component="td"
            variant="td"
            className="table-td table-cursor"
            align="center"
            onClick={() => handleOpenViewerListModal(data?._id)}
          >
            {data?.views}
          </Index.TableCell>

          <Index.TableCell
            component="td"
            variant="td"
            className="table-td table-cursor"
            align="center"
            onClick={() =>
              !data?.rating == 0 && handleOpenRatingListModal(data?._id)
            }
          >
            {reactionIcon}
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const renderLiveNewsContent = () => {
    return (
      <>
        {loader && <PagesIndex.Loader />}
        {!loader &&
          dashBoardData?.totalLiveNews?.length > 0 &&
          renderLiveNews()}
        {!loader &&
          (!dashBoardData?.totalLiveNews ||
            dashBoardData?.totalLiveNews?.length === 0) && (
            <PagesIndex.DataNotFound />
          )}
      </>
    );
  };

  const renderUserUploadedContentList = () => {
    return (
      <>
        {loader && <PagesIndex.Loader />}
        {!loader && userContentData?.length > 0 && renderUserUplaodedContent()}
        {!loader && (!userContentData || userContentData?.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };

  const renderUserUplaodedContent = () => {
    return userContentData?.map((data) => {
      return (
        <Index.TableRow key={data?._id}>
          <Index.TableCell component="td" variant="td" className="table-td">
            {data?.name}
          </Index.TableCell>
          <Index.TableCell
            component="td"
            variant="td"
            className="table-td"
            align="center"
          >
            {data?.count}
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const [seriesPie, setSeriesPie] = useState([]);
  const [optionsPie, setOptionsPie] = useState({
    chart: {
      width: "100%",
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: "Content Duration Distribution",
            columnDelimiter: ",",
            // headerCategory: 'Times Of (the) Day',
            // headerValue: 'View News',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "Content Duration Distribution",
          },
          png: {
            filename: "Content Duration Distribution",
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      style: {
        fontSize: "10px",
        fontWeight: 100,
      },
      formatter: function (val) {
        return val == 100 ? val + `%` : val.toFixed(2) + "%";
      },
    },
    legend: {
      position: "bottom",
    },
    labels: [],
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: "Poppins",
      },
    },
    responsive: [
      {
        breakpoint: 1375,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return val == 100 ? val + `%` : val.toFixed(2) + "%";
        },
      },
    },
  });

  const [newsData, setNewsData] = useState([]);
  const [seriesBar, setSeriesBar] = useState([
    {
      name: "Views",
      data: [],
    },
  ]);
  const [optionsBar, setOptionsBar] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: "Categories Wise Views Distribution",
            columnDelimiter: ",",
            // headerCategory: 'Times Of (the) Day',
            // headerValue: 'View News',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "Categories Wise Views Distribution",
          },
          png: {
            filename: "Categories Wise Views Distribution",
          },
        },
        autoSelected: "zoom",
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
        fontSize: "10px",
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
      formatter: function (val) {
        return val == 100 ? val + `%` : val.toFixed(2) + "%";
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
  });

  const optionss = {
    chart: {
      width: "100%",
      type: "line",
      fontFamily: "Poppins",
      toolbar: {
        show: true,
      },
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 3,
    },
    xaxis: {
      categories: newsRatingGraph.map((data) => data.date),
      labels: {
        style: {
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      theme: "light",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: "Poppins",
      },
    },
  };
  const seriess = [
    {
      name: "Views",
      data: newsRatingGraph.map((data) => data.views),
      color: "#3c0000",
    },
    {
      name: "Rating",
      data: newsRatingGraph.map((data) => data.rating),
      color: "#c85803",
    },
    {
      name: "News Count",
      data: newsRatingGraph.map((data) => data.newsCount),
      color: "#717171",
    },
  ];

  const [homeSeriesPie, setHomeSeriesPie] = useState([]);
  const [homeOptionsPie, setHomeOptionsPie] = useState({
    chart: {
      width: "100%",
      type: "pie",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: "Content Progress Distribution",
            columnDelimiter: ",",
            // headerCategory: 'Times Of (the) Day',
            // headerValue: 'View News',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: "Content Progress Distribution",
          },
          png: {
            filename: "Content Progress Distribution",
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      style: {
        fontSize: "10px",
        fontWeight: 100,
      },
      formatter: function (val) {
        return val == 100 ? val + `%` : val.toFixed(2) + "%";
      },
    },
    legend: {
      position: "bottom",
    },
    labels: [],
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: "Poppins",
      },
    },
    responsive: [
      {
        breakpoint: 1375,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return val == 100 ? val + `%` : val.toFixed(2) + "%";
        },
      },
    },
  });

  // for open handleChangedropdown
  const handleChangedropdown = (event) => {
    setType(event.target.value);
    setPreviousType(type);
    if (event.target.value !== "custom" && date) {
      setDate("");
    }
  };
  const getDashBoardCount = useCallback(() => {
    setLoader(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_DASHBOARD_COUNT).then((res) => {
      if (res.status === 200) {
        setLoader(false);
        setDashBoardData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, []);
  const getUserUplaodedContent = (urlEncoded) => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_UPLOADED_CONTENT_LIST + "/" + type,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        setUserContentData(
          res.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const getUserUplaodedVideoContent = (urlEncoded) => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_UPLOADED_VIDEO_CONTENT_LIST + "/" + type,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        setUserUploadedVideoData(
          res.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleGetUserGraphDetails = (urlEncoded) => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_DESHBOARD_CONTANT_GRAPH + "/" + type,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        const colors = generateRandomColors(10);
        setSeriesPie(
          res?.data?.map((data) => +parseFloat(data?.percentage)?.toFixed(2))
        );
        setOptionsPie((prev) => ({
          ...prev,
          labels: res?.data?.map((data) => data?.categoryName),
          colors: colors,
        }));
        setOptionsBar((prev) => ({
          ...prev,
          xaxis: {
            ...prev?.xaxis,
            categories: res?.data?.map((data) => data?.categoryName),
          },
          colors: colors,
        }));
        setSeriesBar((prev) => [
          {
            ...prev[0],
            data: res?.data?.map((data) => data?.views),
          },
        ]);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const getNewsGraphData = (urlEncoded) => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_DESHBOARD_NEWS_GRAPH_DATA + "/" + type,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        // Manipulate the data format
        const chartData = Object.entries(res.data).map(([time, count]) => ({
          x: time,
          y: count,
        }));

        // Set the manipulated data to state
        setNewsData(chartData);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleGetHomeSectionGraph = (urlEncoded) => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_DESHBOARD_HOME_SECTION_GRAPH + "/" + type,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        const colors = generateRandomColors(10);
        setHomeSeriesPie(
          res?.data?.map((data) => +parseFloat(data?.percentage)?.toFixed(2))
        );
        setHomeOptionsPie((prev) => ({
          ...prev,
          labels: res?.data?.map((data) => data?.name),
          colors: colors,
        }));
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleGetNewRatingGraph = (urlEncoded) => {
    let updatedType = "today";
    if (type == "today") {
      updatedType = "sevenday";
    } else {
      updatedType = type;
    }

    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_DESHBOARD_NEWS_RATING_GRAPH + "/" + updatedType,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        setNewsRatingGraph(res.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleGetDistrictWise = (urlEncoded) => {
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_DESHBOARD_DISTRICT_WISE_NEWS_GRAPH + "/" + type,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        const districtData = res?.data?.data;
        const newData = Object.entries(districtData).map(
          ([district, info]) => ({
            district,
            count: info.count,
            image: info.image,
          })
        );
        setDistrictWiseData(newData);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  useEffect(() => {
    getDashBoardCount();
  }, [getDashBoardCount]);

  const getData = (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "from",
      PagesIndex.moment(new Date(values?.startDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append(
      "to",
      PagesIndex.moment(new Date(values?.endDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append(
      "viewAll",
      adminDetails?.roleId?.permissions?.includes("upload_video_view_all")
    );
    let date = {
      from: "",
      to: "",
      viewAll: adminDetails?.roleId?.permissions?.includes(
        "upload_video_view_all"
      ),
    };
    let data = values?.startDate || values?.endDate ? urlEncoded : date;

    handleGetUserGraphDetails(data);
    handleGetNewRatingGraph(data);
    getNewsGraphData(data);
    handleGetHomeSectionGraph(data);
    handleGetDistrictWise(data);
    getUserUplaodedContent(data);
    getUserUplaodedVideoContent(data);
  };
  useEffect(() => {
    if (type === "custom") {
      handleOpen();
    } else if (type !== "selected date") {
      getData();
    }
  }, [type]);

  const getDateWiseData = (values) => {
    getData(values);
    setType("selected date");
    setDate(
      `${PagesIndex.moment(values?.startDate)
        .utc()
        .format("DD/MM/YYYY")} to ${PagesIndex.moment(values?.endDate)
        .utc()
        .format("DD/MM/YYYY")}`
    );
    handleClose("submit");
  };

  const slidercategory = {
    slidercategory: {
      0: {
        items: 1,
        margin: 0,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4,
      },
      1200: {
        items: 5,
      },
      1500: {
        items: 7,
      },
    },
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers = ["User Name", "Total Uploaded Content"];
    let modifiedData = userContentData.map((data, index) => {
      let objData = [
        data?.name ? data?.name?.replaceAll(",", "") : "-",
        data?.count,
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_User_uploaded_content_list.csv`,
      "text/csv"
    );
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={getDateWiseData}
      initialValues={initialValues}
      validationSchema={PagesIndex.dashbordFilterDate}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Dashboard
              </Index.Typography>
            </Index.Box>
            <Index.Box className="admin-dashboad-row">
              <Index.Box className="admin-dash-card-row">
                <Index.Box className="grid-column">
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashBoardData?.users
                            ? formatCount(dashBoardData?.users)
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.usersicon}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Videos
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashBoardData?.news
                            ? formatCount(dashBoardData?.news)
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.videoicons}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Clients
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashBoardData?.clientAdvertiser
                            ? formatCount(dashBoardData?.clientAdvertiser)
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.Advertisericons}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Sponsors
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashBoardData?.sponsors
                            ? formatCount(dashBoardData?.sponsors)
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.sponsericons}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="admin-dashboard-box common-card">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Uploaded Videos
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashBoardData?.uploadVideoCount
                            ? formatCount(dashBoardData?.uploadVideoCount)
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-icon-box">
                          <img
                            src={PagesIndex.Svg.videoicons}
                            className="admin-dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Live News
        </Index.Typography>
      </Index.Box>
      <Index.Box className="admin-dashboad-row">
        <Index.Box className="admin-dash-card-row">
          {
            dashBoardData?.totalLiveNews !== 0 && (
              <Index.Box className="grid-column">
                <Index.Box className="admin-dashboard-box common-card">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Live News
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {dashBoardData?.totalLiveNews ? dashBoardData?.totalLiveNews : 0}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <Index.Box className="admin-dash-icon-box">
                        <img
                          src={PagesIndex.Svg.usersicon}
                          className="admin-dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )
          }

          <Index.Box className="grid-column">
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography
                    className="admin-dash-text"
                    variant="p"
                    component="p"
                  >
                    Live News Views
                  </Index.Typography>
                  <Index.Typography
                    className="admin-dash-price"
                    variant="h1"
                    component="h1"
                  >
                    {dashBoardData?.liveNewsCountViews ? dashBoardData?.liveNewsCountViews : 0}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                  <Index.Box className="admin-dash-icon-box">
                    <img
                      src={PagesIndex.Svg.usersicon}
                      className="admin-dash-icons"
                      alt="dashboard icon"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="grid-column" onClick={() => handleOpenRatingListModal()}>
            <Index.Box className="admin-dashboard-box common-card">
              <Index.Box className="admin-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography
                    className="admin-dash-text"
                    variant="p"
                    component="p"
                  >
                    Live News Rating
                  </Index.Typography>
                  <Index.Typography
                    className="admin-dash-price"
                    variant="h1"
                    component="h1"
                  >
                    {dashBoardData?.liveNewsRating ? dashBoardData?.liveNewsRating : 0}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right">
                  <Index.Box className="admin-dash-icon-box">
                    <img
                      src={PagesIndex.Svg.usersicon}
                      className="admin-dash-icons"
                      alt="dashboard icon"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box> */}
            {districtWiseData?.length ? (
              <Index.Box className="analytic-title-flex dash-live-news-title-flex">
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    District
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="dash-dropdown-box">
                  <Index.Box className="admin-form-group">
                    <Index.Box className="admin-dropdown-box">
                      <Index.FormControl className="admin-form-control">
                        <Index.Select
                          className="admin-dropdown-select "
                          value={type}
                          onChange={handleChangedropdown}
                          displayEmpty
                          defaultValue="today"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <Index.MenuItem
                            value="today"
                            className="admin-menuitem"
                          >
                            Today
                          </Index.MenuItem>
                          <Index.MenuItem
                            value="sevenday"
                            className="admin-menuitem"
                          >
                            Last 7 Days
                          </Index.MenuItem>
                          <Index.MenuItem
                            value="month"
                            className="admin-menuitem"
                          >
                            Last Month
                          </Index.MenuItem>
                          <Index.MenuItem
                            value="custom"
                            className="admin-menuitem"
                          >
                            Custom
                          </Index.MenuItem>
                          {date ? (
                            <Index.MenuItem
                              value="selected date"
                              className="admin-menuitem"
                              disabled
                            >
                              {date}
                            </Index.MenuItem>
                          ) : null}
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            ) : null}
            <Index.Box className="admin-dashboad-row">
              <Index.Box className="dash-slider-row">
                <Index.Box className="calegory-slider-main">
                  {districtWiseData?.length ? (
                    <OwlCarousel
                      className="owl-theme calegory-slider-owl"
                      margin={10}
                      responsive={slidercategory.slidercategory}
                      loop={true}
                      // nav={true}
                      autoplay={true}
                      autoplayTimeout={2500}
                      autoplayHoverPause={false}
                      dots={false}
                      stagePadding={0}
                      navText={[
                        "<img src=" + PagesIndex.Svg.previousarrow + " >",
                        "<img src=" + PagesIndex.Svg.nextarrow + " >",
                      ]}
                    >
                      {districtWiseData?.map((data) => {
                        return (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 2",
                              lg: "span 2",
                            }}
                            className="grid-column"
                            key={data?._id}
                          >
                            <Index.Box
                              className="dash-colors-box-main"
                              style={{
                                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url(${
                                  process.env.REACT_APP_IMAGE_ENDPOINT +
                                  data?.image
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              <Index.Box className="dash-icon-flex">
                                <Index.Box className="dash-colors-icon-box-main">
                                  {/* <img src={process.env.REACT_APP_IMAGE_ENDPOINT + data?.image} className="dash-colors-icons" alt="Dashboard" /> */}
                                </Index.Box>
                                <Index.Box className="dash-colors-count-box-main">
                                  <Index.Typography className="dash-colors-count-text">
                                    {formatCount(data?.count)}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Typography className="dash-colors-titles-main">
                                {data?.district}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        );
                      })}
                    </OwlCarousel>
                  ) : null}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {dashBoardData?.totalLiveNews?.length > 0 && (
              <>
                <Index.Box className="analytic-title-flex dash-live-news-title-flex">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Live News
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="admin-dashboad-live-news-row">
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="common-card">
                      {/* <Index.Box className="chart-title-main">
                  <Index.Typography
                    className="chart-title"
                    component="h2"
                    variant="h2"
                  >
                    Live News
                  </Index.Typography>
                </Index.Box> */}
                      <Index.Box className="page-table-main">
                        <Index.TableContainer
                          // component={Index.Paper}
                          className="table-container"
                          sx={{ maxHeight: 273 }}
                        >
                          <Index.Table stickyHeader aria-label="sticky table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  className="table-th"
                                  width="10%"
                                >
                                  News ID
                                </Index.TableCell>

                                <Index.TableCell
                                  className="table-th"
                                  width="20%"
                                >
                                  News Title
                                </Index.TableCell>

                                <Index.TableCell
                                  className="table-th"
                                  width="15%"
                                >
                                  News Category
                                </Index.TableCell>

                                <Index.TableCell
                                  className="table-th"
                                  width="10%"
                                >
                                  District
                                </Index.TableCell>

                                <Index.TableCell
                                  className="table-th"
                                  width="5%"
                                  align="center"
                                >
                                  Viewers
                                </Index.TableCell>

                                <Index.TableCell
                                  className="table-th"
                                  width="5%"
                                  align="center"
                                >
                                  Reaction
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {renderLiveNewsContent()}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            )}
            {userContentData?.length ? (
              <>
                <Index.Box className="analytic-title-flex dash-live-news-title-flex">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      User Uploaded Content
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-adduser-btn-main btn-main-secondary">
                    <Index.Button
                      className="admin-adduser-btn btn-secondary"
                      onClick={generateCSV}
                    >
                      <img
                        src={PagesIndex.Svg.exporticon}
                        className="admin-plus-icon"
                        alt="plus"
                      />
                      <span>Export CSV</span>
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="admin-dashboad-live-news-row">
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="common-card">
                      {/* <Index.Box className="chart-title-main">
                  <Index.Typography
                    className="chart-title"
                    component="h2"
                    variant="h2"
                  >
                    Live News
                  </Index.Typography>
                </Index.Box> */}
                      <Index.Box className="page-table-main">
                        <Index.TableContainer
                          // component={Index.Paper}
                          className="table-container"
                          sx={{ maxHeight: 273 }}
                        >
                          <Index.Table stickyHeader aria-label="sticky table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  className="table-th"
                                  width="10%"
                                >
                                  User Name
                                </Index.TableCell>
                                <Index.TableCell
                                  className="table-th"
                                  width="5%"
                                  align="center"
                                >
                                  Total Video Uploaded
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {renderUserUploadedContentList()}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}
            {userUploadedVideoData?.length ? (
              <>
                <Index.Box className="analytic-title-flex dash-live-news-title-flex">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      User Uploaded Video Content
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="admin-dashboad-live-news-row">
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="common-card">
                      {/* <Index.Box className="chart-title-main">
                  <Index.Typography
                    className="chart-title"
                    component="h2"
                    variant="h2"
                  >
                    Live News
                  </Index.Typography>
                </Index.Box> */}
                      <Index.Box className="page-table-main">
                        <Index.TableContainer
                          // component={Index.Paper}
                          className="table-container"
                          sx={{ maxHeight: 273 }}
                        >
                          <Index.Table stickyHeader aria-label="sticky table">
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  className="table-th"
                                  width="10%"
                                >
                                  User Name
                                </Index.TableCell>
                                <Index.TableCell
                                  className="table-th"
                                  width="5%"
                                  align="center"
                                >
                                  Total Video Uploaded
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {userUploadedVideoData?.map((data) => {
                                return (
                                  <Index.TableRow key={data?._id}>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {data?.name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {data?.count}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}
            <Index.Box className="analytic-title-flex">
              <Index.Box className="admin-sub-title-main">
                <Index.Typography
                  className="admin-sub-title"
                  component="h2"
                  variant="h2"
                >
                  Analytics
                </Index.Typography>
              </Index.Box>
              {!districtWiseData?.length ? (
                <Index.Box className="dash-dropdown-box">
                  <Index.Box className="admin-form-group">
                    <Index.Box className="admin-dropdown-box">
                      <Index.FormControl className="admin-form-control">
                        <Index.Select
                          className="admin-dropdown-select "
                          value={type}
                          onChange={handleChangedropdown}
                          displayEmpty
                          defaultValue="today"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <Index.MenuItem
                            value="today"
                            className="admin-menuitem"
                          >
                            Today
                          </Index.MenuItem>
                          <Index.MenuItem
                            value="sevenday"
                            className="admin-menuitem"
                          >
                            Last 7 Days
                          </Index.MenuItem>
                          <Index.MenuItem
                            value="month"
                            className="admin-menuitem"
                          >
                            Last Month
                          </Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              ) : null}
            </Index.Box>
            <Index.Box className="grid-row chart-row-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="dashboard-chart-main">
                      <Index.Box className="chart-col common-card">
                        <Index.Box className="chart-title-main">
                          <Index.Typography
                            className="chart-title"
                            component="h2"
                            variant="h2"
                          >
                            Content Duration Distribution
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="chart-main-box">
                          <Index.Box className="chart-box">
                            <PagesIndex.ReactApexChart
                              options={optionsPie}
                              series={seriesPie}
                              type="donut"
                              height={300}
                              width={optionsPie.chart.width}
                              // height={optionsPie.chart.height}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="dashboard-chart-main">
                      <Index.Box className="chart-col common-card">
                        <Index.Box className="chart-title-main">
                          <Index.Typography
                            className="chart-title"
                            component="h2"
                            variant="h2"
                          >
                            Content News Distribution
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="chart-main-box">
                          <Index.Box className="chart-box">
                            <PagesIndex.ReactApexChart
                              series={[{ name: "View News", data: newsData }]}
                              type="area"
                              width="100%"
                              height={250}
                              options={{
                                chart: {
                                  stacked: false,
                                  height: 350,
                                  zoom: {
                                    type: "x",
                                    enabled: true,
                                    autoScaleYaxis: true,
                                  },
                                  toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                      download: true,
                                      selection: false,
                                      zoom: false,
                                      zoomin: false,
                                      zoomout: false,
                                      pan: false,
                                      reset:
                                        false |
                                        '<img src="/static/icons/reset.png" width="20">',
                                      customIcons: [],
                                    },
                                    export: {
                                      csv: {
                                        filename: "Content News Distribution",
                                        columnDelimiter: ",",
                                        headerCategory: "Times Of (the) Day",
                                        headerValue: "View News",
                                        dateFormatter(timestamp) {
                                          return new Date(
                                            timestamp
                                          ).toDateString();
                                        },
                                      },
                                      svg: {
                                        filename: "Content News Distribution",
                                      },
                                      png: {
                                        filename: "Content News Distribution",
                                      },
                                    },
                                    autoSelected: "zoom",
                                  },
                                  xaxis: {
                                    categories: newsData.map((data) => data.x),
                                  },
                                  markers: {
                                    size: 0,
                                    strokeWidth: 0,
                                    fillOpacity: 1,
                                    colors: ["#008FFB"],
                                  },
                                  stroke: {
                                    width: 2,
                                    curve: "straight",
                                  },
                                  fill: {
                                    type: "gradient",
                                    gradient: {
                                      shadeIntensity: 1,
                                      opacityFrom: 0.7,
                                      opacityTo: 0.9,
                                      stops: [0, 100],
                                    },
                                  },
                                  discrete: [
                                    {
                                      seriesIndex: 0,
                                      dataPointIndex: newsData.reduce(
                                        (acc, curr, index, array) => {
                                          if (
                                            index > 0 &&
                                            array[index - 1].y !== curr.y
                                          ) {
                                            acc.push(index);
                                          }
                                          return acc;
                                        },
                                        []
                                      ),
                                    },
                                  ],
                                },
                                noData: {
                                  text: "No data available",
                                  align: "center",
                                  verticalAlign: "middle",
                                  offsetX: 0,
                                  offsetY: 0,
                                  style: {
                                    color: undefined,
                                    fontSize: "14px",
                                    fontFamily: undefined,
                                  },
                                },
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="dashboard-chart-main">
                      <Index.Box className="chart-col common-card">
                        <Index.Box className="chart-title-main">
                          <Index.Typography
                            className="chart-title"
                            component="h2"
                            variant="h2"
                          >
                            Content Progress Distribution
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="chart-main-box">
                          <Index.Box className="chart-box">
                            <PagesIndex.ReactApexChart
                              options={homeOptionsPie}
                              series={homeSeriesPie}
                              type="pie"
                              height={300}
                              width={homeOptionsPie.chart.width}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {/* <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="dashboard-chart-main">
                <Index.Box className="chart-col common-card">
                  <Index.Box className="chart-title-main">
                    <Index.Typography
                      className="chart-title"
                      component="h2"
                      variant="h2"
                    >
                      Categories csdcscdscd dcscd Distribution
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-main-box">
                    <Index.Box className="chart-box">
                      <PagesIndex.ReactApexChart
                        options={distrricWiseOptionsBar}
                        series={districtWiseData}
                        type="bar"
                        height={225}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="dashboard-chart-main">
                      <Index.Box className="chart-col common-card">
                        <Index.Box className="chart-title-main">
                          <Index.Typography
                            className="chart-title"
                            component="h2"
                            variant="h2"
                          >
                            Categories News Rating Distribution
                          </Index.Typography>
                        </Index.Box>
                        {/* <Index.Box className="chart-box custom-full">
                          <PagesIndex.ReactApexChart options={optionss} series={seriess} type="line"
                            width={500} height="100%" />
                        </Index.Box> */}
                        <Index.Box className="chart-box custom-full">
                          {newsRatingGraph && newsRatingGraph?.length > 0 ? (
                            <ResponsiveContainer
                              width="100%"
                              height={400}
                              style={{
                                fontSize: "13px",
                                fontFamily: "Poppins",
                              }}
                            >
                              <LineChart
                                data={newsRatingGraph}
                                margin={{ top: 20, bottom: 10 }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  dataKey="date"
                                  tick={{ fontFamily: "Arial", fontSize: 12 }}
                                />
                                <YAxis
                                  tick={{ fontFamily: "Arial", fontSize: 12 }}
                                />
                                <Tooltip />
                                <Legend />
                                <Line
                                  type="monotone"
                                  dataKey="views"
                                  stroke="#8884d8"
                                  strokeWidth={3}
                                  name="Views"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="rating"
                                  stroke="#82ca9d"
                                  strokeWidth={3}
                                  name="Rating"
                                />
                                <Line
                                  type="monotone"
                                  dataKey="newsCount"
                                  stroke="#ffc658"
                                  strokeWidth={3}
                                  name="News Count"
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          ) : (
                            <Index.Box className="custom-not-found-main">
                              <Index.Typography className="custom-not-found-text">
                                No data available
                              </Index.Typography>
                            </Index.Box>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="dashboard-chart-main">
                      <Index.Box className="chart-col common-card">
                        <Index.Box className="chart-title-main">
                          <Index.Typography
                            className="chart-title"
                            component="h2"
                            variant="h2"
                          >
                            Categories Wise Views Distribution
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="chart-main-box">
                          <Index.Box className="chart-box">
                            <PagesIndex.ReactApexChart
                              options={optionsBar}
                              series={seriesBar}
                              type="bar"
                              height={225}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Modal
              open={openRatingList}
              onClose={handleCloseRatingListModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-log-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Rating List
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseRatingListModal}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="common-card">
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="45%"
                              >
                                User Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="25%"
                              >
                                Mobile No.
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="20%"
                              >
                                News ID
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="10%"
                              >
                                Reaction
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {ratingList?.length ? (
                              ratingList?.map((data) => {
                                let reactionIcon;
                                if (data?.rating != null) {
                                  if (data.rating > 0 && data.rating < 1) {
                                    reactionIcon = (
                                      <img
                                        src={PagesIndex.Svg.reaction1}
                                        className="reaction-icon"
                                        alt="reaction"
                                      />
                                    );
                                  } else if (
                                    data.rating >= 1 &&
                                    data.rating < 2
                                  ) {
                                    reactionIcon = (
                                      <img
                                        src={PagesIndex.Svg.reaction2}
                                        className="reaction-icon"
                                        alt="reaction"
                                      />
                                    );
                                  } else if (
                                    data.rating >= 2 &&
                                    data.rating < 3
                                  ) {
                                    reactionIcon = (
                                      <img
                                        src={PagesIndex.Svg.reaction3}
                                        className="reaction-icon"
                                        alt="reaction"
                                      />
                                    );
                                  } else if (
                                    data.rating >= 3 &&
                                    data.rating <= 4
                                  ) {
                                    reactionIcon = (
                                      <img
                                        src={PagesIndex.Svg.reaction4}
                                        className="reaction-icon"
                                        alt="reaction"
                                      />
                                    );
                                  } else if (
                                    data.rating >= 4 &&
                                    data.rating <= 5
                                  ) {
                                    reactionIcon = (
                                      <img
                                        src={PagesIndex.Svg.reaction5}
                                        className="reaction-icon"
                                        alt="reaction"
                                      />
                                    );
                                  } else {
                                    reactionIcon = "-";
                                  }
                                } else {
                                  reactionIcon = "-";
                                }
                                return (
                                  <Index.TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    key={data?._id}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Typography className="admin-table-data-text">
                                        {data?.userId?.userName
                                          ? data?.userId?.userName
                                          : "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Typography className="admin-table-data-text">
                                        {data?.userId?.mobile
                                          ? data?.userId?.mobile
                                          : "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Typography className="admin-table-data-text">
                                        {data?.newsId?.newsNo
                                          ? data?.newsId?.newsNo
                                          : "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {reactionIcon}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <PagesIndex.DataNotFound />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>

            <Index.Modal
              open={openViewerList}
              onClose={handleCloseViewerListModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-log-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Viewer List
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseViewerListModal}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="common-card">
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="25%"
                              >
                                Email Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="30%"
                              >
                                User Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="20%"
                              >
                                Mobile No.
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="25%"
                                align="center"
                              >
                                News ID
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {viewsList?.length ? (
                              viewsList?.map((data) => {
                                return (
                                  <Index.TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    key={data?._id}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Typography className="admin-table-data-text">
                                        {data?.userId?.email || "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Typography className="admin-table-data-text">
                                        {data?.userId?.firstName &&
                                        data?.userId?.lastName
                                          ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
                                          : "-"}
                                      </Index.Typography>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {data?.userId?.mobile
                                        ? data?.userId?.mobile
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      <Index.Typography className="admin-table-data-text">
                                        {data?.newsId?.newsNo
                                          ? data?.newsId?.newsNo
                                          : "-"}
                                      </Index.Typography>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <PagesIndex.DataNotFound />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>
          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Select Date
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleClose}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  className={"admin-modal-hgt-scroll cus-scrollbar"}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>From Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderFrom}
                            onClose={() => setOpenCalenderFrom(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.startDate} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("startDate", date);
                              setFieldValue("endDate", null);
                            }}
                            disableFuture
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderFrom(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenCalenderFrom(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>To Date</span>
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderTo}
                            onClose={() => setOpenCalenderTo(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.endDate} // Use '01/01/2000' as the default value
                            minDate={
                              values.startDate
                                ? dayjs(values.startDate).add(0, "day")
                                : dayjs().add(0, "day")
                            }
                            disableFuture
                            onChange={(date) => {
                              setFieldValue("endDate", date);
                            }}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderTo(true),
                              },
                              inputAdornment: {
                                onClick: () => setOpenCalenderTo(true), // This triggers on the calendar icon
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.endDate && touched.endDate && errors.endDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-save-user-btn btn-primary"
                        type="submit"
                      >
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>Submit</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>
        </>
      )}
    </PagesIndex.Formik>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import WeekdayTableView from "./WeekdayTableView";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function SalesSubOrderList() {
  const params = PagesIndex.useParams();
  const { id } = params;
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [modalDataOrder, setModalDataOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const totalAmount = modalData?.orderDetails?.reduce((total, item) => {
    return total + parseFloat(item?.amount, 10);
  }, 0);

  const [creativeData, setCreativeData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [openView, setOpenView] = useState(false);

  // View BookingDetails modal
  const [openViewBookingDetails, setOpenViewBookingDetails] =
    React.useState(false);
  const handleOpenViewBookingDetails = (data) => {
    handleCloseView();
    setOpenViewBookingDetails(true);
    setModalDataOrder(data);
  };
  const handleCloseViewBookingDetails = () => {
    setOpenViewBookingDetails(false);
    setModalDataOrder({});
  };

  const handleOpenView = (data) => {
    setOpenView(true);
    setModalData(data);
  };
  const handleCloseView = () => {
    setOpenView(false);
    setModalData({});
  };
  const handleGetSalesOrder = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_BOOKING + "/" + id).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
        }
      }
    );
  };
  const handleGetCreatives = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CREATIVES).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setCreativeData(res.data);
      }
    });
  };
  const handleGetProgram = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM).then((res) => {
      if (res.status === 200) {
        setProgramData(res?.data);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    handleGetSalesOrder();
    handleGetCreatives();
    handleGetProgram();
  }, []);

  const handleDeleteSalesOrder = () => {
    const urlEncoded = new URLSearchParams();
    PagesIndex.apiPostHandler(
      PagesIndex.Api.DELETE_SALES_ORDER,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        handleCloseView();
        handleGetSalesOrder();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(new Date(data?.updatedAt))
          .format("YYYY-MM-DD")
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.subOrderNo
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.totalAmount
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        `${data?.startDate} ${data?.endDate}`
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.startDate
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.endDate
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                <span className="news-id-text">{data?.subOrderNo}</span>{" "}
                {data?.name}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${PagesIndex.moment(data?.startDate).format(
                  "DD/MM/YYYY"
                )} to ${PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {PagesIndex.moment(data?.startDate).format("DD/MM/YYYY")} to{" "}
                  {PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {parseFloat(data?.totalAmount)?.toLocaleString("en-Us") || "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.updatedAt || data?.createdBy?.name
                  ? data?.createdBy?.name +
                    "-" +
                    PagesIndex.moment(new Date(data?.updatedAt)).format(
                      "YYYY-MM-DD hh:mm:ss A"
                    )
                  : "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              align="right"
              className="table-td"
            >
              <Index.Box className="admin-table-data-btn-flex ">
                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenView(data)}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    />
                  </Index.Button>
                </Index.Tooltip>
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };
  const handleDownloadVideo = async (data) => {
    const a = document.createElement("a");

    a.setAttribute("download", data?.documentsName); // Replace with the desired filename for the downloaded file
    a.href = process.env.REACT_APP_IMAGE_ENDPOINT + data?.file;
    a.click();
    window.URL.revokeObjectURL(
      process.env.REACT_APP_IMAGE_ENDPOINT + data?.file
    );
  };

  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Bookings List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box
            className={`admin-distric-table-main ${
              loading ? "" : "page-table-main"
            }`}
          >
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="15%"
                      >
                        Booking
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="15%"
                      >
                        Duration
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Total Amount
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="22%"
                      >
                        Created Date & Time
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                        align="right"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {renderContent()}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-view-sales-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Booking Details{" "}
              <span className="sales-order-id-text">
                {/* ({modalData?.subOrderNo}) */}
              </span>
            </Index.Typography>
            <Index.Button className="modal-close-btn" onClick={handleCloseView}>
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="sales-order-view-main">
              <Index.Box className="sales-order-view-flex">
                <Index.Box className="sales-order-view-content">
                  <Index.Typography className="sales-order-name-lable">
                    Booking Name:
                  </Index.Typography>
                  <Index.Typography className="sales-order-name-text">
                    {modalData?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="sales-order-view-content">
                  <Index.Typography className="sales-order-name-lable">
                    Date:
                  </Index.Typography>
                  <Index.Typography className="sales-order-name-text">
                    {PagesIndex.moment(data?.startDate)
                      .utc()
                      .format("DD/MM/YYYY")}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-card">
                <Index.Box
                  className={`admin-client-table-main page-table-main`}
                >
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="2%"
                            >
                              Sr. No.
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Platform
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Element
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Total
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Selling Rate
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Remaining Units
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                            >
                              Used Units
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                              align="right"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {modalData?.orderDetails?.map((ele, index) => {
                            return (
                              <Index.TableRow
                                className="table-row"
                                key={ele?._id}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {index + 1}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {ele?.platform}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {ele?.elements}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {ele?.totalSeconds} ({ele?.unit})
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {parseFloat(
                                    ele?.discountrate
                                  )?.toLocaleString("en-Us")}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {parseFloat(
                                    ele?.remainingUnits
                                  )?.toLocaleString("en-Us")}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {ele?.units}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  align="right"
                                  className="table-td"
                                >
                                  <Index.Box className="admin-table-data-btn-flex ">
                                    <Index.Tooltip
                                      title="View"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn abc"
                                        onClick={() =>
                                          handleOpenViewBookingDetails(ele)
                                        }
                                      >
                                        <img
                                          src={PagesIndex.Svg.yelloweye}
                                          className="admin-icon"
                                          alt="View"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })}

                          {/* <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                              align="right"
                            >
                              <Index.Box className="total-text">
                                Total Amount:
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {parseFloat(totalAmount)?.toLocaleString("en-Us")}
                            </Index.TableCell>
                          </Index.TableRow> */}
                        </Index.TableBody>
                      </Index.Table>
                    )}
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
              {modalData?.document?.length !== 0 && (
                <>
                  <Index.Box className="view-sales-approve-box">
                    <Index.Box className="view-sales-approve-flex">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h2"
                          variant="h2"
                        >
                          List of Attachment
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="common-card attechment-card">
                    {modalData?.document?.map((url) => {
                      return (
                        <Index.Box
                          className="document-data-flex"
                          key={url?.file}
                        >
                          <Index.Box className="document-content-main">
                            <Index.Box className="document-lable-flex">
                              <Index.Typography className="document-id-text">
                                {url?.name?.toUpperCase()}
                              </Index.Typography>
                              <Index.Typography className="document-lable-text">
                                {url?.file}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="document-content-main">
                            <Index.Box className="document-delete-main">
                              <Index.Button
                                className="admin-table-data-btn"
                                onClick={() => handleDownloadVideo(url)}
                              >
                                <img
                                  src={PagesIndex.Svg.download}
                                  className="admin-icon"
                                  alt="Download"
                                ></img>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      );
                    })}
                  </Index.Box>
                </>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openViewBookingDetails}
        onClose={handleCloseViewBookingDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-view-sales-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              View Booking Details{" "}
              <span className="sales-order-id-text">
                {/* ({modalData?.subOrderNo}) */}
              </span>
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseViewBookingDetails}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="grid-row view-order-row">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="order-inner-box">
                    <Index.Typography className="order-inner-lable-text">
                      Platform Name:{" "}
                    </Index.Typography>
                    <Index.Typography className="order-inner-value-text">
                      {modalDataOrder?.platform}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 8",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="order-inner-box">
                    <Index.Typography className="order-inner-lable-text">
                      Element:{" "}
                    </Index.Typography>
                    <Index.Typography className="order-inner-value-text">
                      {modalDataOrder?.elements}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 8",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="order-inner-box">
                    <Index.Typography className="order-inner-lable-text">
                      Total Remaining Units:{" "}
                    </Index.Typography>
                    <Index.Typography className="order-inner-value-text">
                      {modalDataOrder?.remainingUnits}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {modalDataOrder?.slotAddData?.map((data) => {
              return (
                <>
                  <Index.Box className="order-show-inner-box view-booking-inner-main">
                    <Index.Box className="order-inner-box view-order-title-main">
                      <Index.Typography className="order-inner-lable-text">
                        Date Type :
                      </Index.Typography>
                      <Index.Typography className="order-inner-value-text">
                        {data?.type === "dateRange" ? "Date Range" : "Date"}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="grid-row view-order-row">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        {data?.type === "dateRange" && (
                          <>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 4",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="order-inner-box">
                                <Index.Typography className="order-inner-lable-text">
                                  Start Date :
                                </Index.Typography>
                                <Index.Typography className="order-inner-value-text">
                                  {PagesIndex.moment(data?.startDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 8",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="order-inner-box">
                                <Index.Typography className="order-inner-lable-text">
                                  End Date :
                                </Index.Typography>
                                <Index.Typography className="order-inner-value-text">
                                  {PagesIndex.moment(data?.endDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        )}
                        {data?.type === "date" && (
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="order-inner-box">
                              <Index.Typography className="order-inner-lable-text">
                                Dates :
                              </Index.Typography>
                              <Index.Typography className="order-inner-value-text">
                                {data?.selectedDates
                                  ?.map((value) => value?.fullDate)
                                  .join(", ")}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-order-outer-main">
                      <Index.Box className="order-inner-box view-order-title-main">
                        <Index.Typography className="order-inner-lable-text">
                          Schedule Type :
                        </Index.Typography>
                        <Index.Typography className="order-inner-value-text">
                          {data?.scheduleType === "timeBand"
                            ? "Time Band"
                            : "Program"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="grid-row view-order-row">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 3",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="order-inner-box">
                              <Index.Typography className="order-inner-lable-text">
                                Creative
                              </Index.Typography>
                              <Index.Typography className="order-inner-value-text">
                                {
                                  creativeData?.find(
                                    (value) => value?._id === data?.creative
                                  )?.name
                                }
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>

                          {data?.scheduleType === "program" && (
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 3",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="order-inner-box">
                                <Index.Typography className="order-inner-lable-text">
                                  Program
                                </Index.Typography>
                                <Index.Typography className="order-inner-value-text">
                                  {
                                    programData?.find(
                                      (value) => value?._id === data?.program
                                    )?.programName
                                  }
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          )}

                          {data?.scheduleType === "timeBand" && (
                            <>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 3",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="order-inner-box">
                                  <Index.Typography className="order-inner-lable-text">
                                    From Time
                                  </Index.Typography>
                                  <Index.Typography className="order-inner-value-text">
                                    {PagesIndex.moment(data?.fromTime).format(
                                      "hh:mm A"
                                    )}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>

                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 3",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="order-inner-box">
                                  <Index.Typography className="order-inner-lable-text">
                                    To Time
                                  </Index.Typography>
                                  <Index.Typography className="order-inner-value-text">
                                    {PagesIndex.moment(data?.toTime).format(
                                      "hh:mm A"
                                    )}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {data?.type === "dateRange" &&
                      data?.weekDays?.map((values) => {
                        return (
                          <WeekdayTableView
                            creativeDuration={data?.creativeDuration}
                            data={values}
                          />
                        );
                      })}
                    {data?.type === "date" && data?.selectedDates?.length ? (
                      <Index.Box className="week-main">
                        <Index.Box className="week-day-name-flex">
                          {data?.selectedDates.map((data, index) => {
                            return (
                              <Index.Typography
                                className="week-day-text"
                                key={data?.day}
                              >
                                {data?.day}
                              </Index.Typography>
                            );
                          })}
                        </Index.Box>
                        <Index.Box className="week-content-main-flex">
                          {data?.selectedDates.map((data, index) => {
                            return (
                              <Index.Box
                                className="week-box"
                                key={`${data?.day}-${data?.onlyDate}`}
                              >
                                <Index.Typography className="week-date-text">
                                  {data?.onlyDate}
                                </Index.Typography>
                                <Index.Box className="admin-input-box week-input-box">
                                  <Index.Box className="admin-form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      className="admin-form-control"
                                      placeholder="0"
                                      value={
                                        data?.value === 0 ? "" : data?.value
                                      }
                                      disabled
                                      inputProps={{ maxLength: 3 }}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            );
                          })}
                        </Index.Box>
                      </Index.Box>
                    ) : (
                      <></>
                    )}
                  </Index.Box>
                </>
              );
            })}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./adsManagement.css";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import MapComp from "./Map";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function AddAdsManagement() {
  const formRef = useRef();
  const videoRef = useRef();
  const { id } = PagesIndex.useParams();
  const location = PagesIndex.useLocation();
  const { state } = location;
  const navigate = PagesIndex.useNavigate();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [districtData, setDistrictData] = useState([]);
  const [tagData, setTagData] = useState([]);

  const handleGetDistrict = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_DISTRICTMASTER).then((res) => {
      if (res.status === 200) {
        setDistrictData(res.data?.filter((data) => data?.isActive));
      }
    });
  };
  const handleGetTag = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_TAG).then((res) => {
      if (res.status === 200) {
        const activeTags = res?.data?.filter((tag) => tag?.isActive);
        setTagData(activeTags);
      }
    });
  };

  const initialValues = {
    advertiser: id ? state?.data?.advId?._id : "",
    startDate: id ? dayjs(state?.data?.startDate) : null,
    endDate: id ? dayjs(state?.data?.endDate) : null,
    weekDay: id ? state?.data?.weekDay : [],
    slot: id ? state?.data?.slot : [],
    tags: id ? state?.data?.tags : "",
    perDayAdCount: id ? state?.data?.perDayAd : "",
    totalAdsCount: id ? state?.data?.totalAdPlay : "",
    type: id ? state?.data?.type : "Fixed cost",
    mediaType: id ? state?.data?.mediaType : "",
    gender: id ? state?.data?.gender : [],
    age: id ? state?.data?.age : [],
    state: id ? state?.data?.state?._id : "",
    district: id
      ? state?.type === "viewToEdit"
        ? state?.data?.district?._id
        : state?.data?.district
      : "",
    coordinates: id ? state?.data?.coordinates : [],
    category: id
      ? state?.data?.category?.length
        ? state?.data?.category
        : []
      : [],
    newsId: id ? state?.data?.newsId : [],
    amount: id ? state?.data?.budget : "",
    adMobId: state?.data?.adMobId ? state?.data?.adMobId : "",
    imageVideo: "",
    imageUrl: state?.data?.image ? state?.data?.image : "",
    link: id ? state?.data?.link : "",
    checked: id ? state?.data?.skip : false,
    adsPart: [
      {
        id: 1,
        label: "Pre",
        checked: state?.data?.adsPart?.includes("Pre") || false,
      },
      {
        id: 2,
        label: "Mid",
        checked: state?.data?.adsPart?.includes("Mid") || false,
      },
      {
        id: 3,
        label: "Post",
        checked: state?.data?.adsPart?.includes("Post") || false,
      },
    ],
  };

  const [advertiserList, setAdvertiserList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [agesList, setAgesList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [slotsList, setSlotsList] = useState([]);
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [weekDays, setWeekDays] = useState([]);

  useEffect(() => {
    handleGetState();
    handleGetAd();
    handleGetCategory();
    handleGetAges();
    handleGetNews();
    handleGetSlots();
    handleGetDistrict();
    handleGetTag();
  }, []);
  useEffect(() => {
    setStartDate(id ? dayjs(state?.data?.startDate) : "");
    setEndDate(id ? dayjs(state?.data?.endDate) : "");
  }, [id]);
  const handleGetNews = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_NEWS).then((res) => {
      if (res.status === 200) {
        setNewsList(
          res.data?.filter(
            (item) =>
              item?.isPublish &&
              item?.isActive &&
              !item.sectionToShow?.includes("660c00dd0b2c9ea28bd1df33")
          )
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetState = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_STATE).then((res) => {
      if (res.status === 200) {
        const activeState = res?.data?.filter((state) => state?.isActive);
        setStateList(activeState);
      }
    });
  };
  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res.status === 200) {
        const category = res?.data?.filter((data) => data?.isActive);
        setCategoryList(category);
      }
    });
  };
  const handleGetAd = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res?.status === 200) {
        setAdvertiserList(
          res?.data?.filter((item) => item?.isActive && !item?.isDraft)
        );
      }
    });
  };
  const handleGetAges = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.AGE_LIST).then((res) => {
      if (res?.status === 200) {
        setAgesList(
          res?.data?.filter((data) => data?.isActive)?.map((data) => data?.age)
        );
      }
    });
  };
  const handleGetSlots = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SLOT_LIST).then((res) => {
      if (res?.status === 200) {
        setSlotsList(
          res?.data?.filter((data) => data?.isActive)?.map((data) => data?.slot)
        );
      }
    });
  };
  const renderImg = (values) => {
    if (values.imageVideo instanceof Blob) {
      return URL.createObjectURL(values.imageVideo);
    } else if (!values.imageVideo && values?.imageUrl) {
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`;
    } else {
      return PagesIndex.Svg.fileupload;
    }
  };
  const handleAddEditAd = (values) => {
    setButtonSpinner(true);
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
    }
    formData.append("tags", JSON.stringify(values?.tags));
    formData.append("advId", values?.advertiser);
    formData.append("newsId", JSON.stringify(values?.newsId));
    formData.append("type", values?.type);
    formData.append("skip", values?.checked ? values?.checked : false);
    formData.append("mediaType", values?.mediaType);
    formData.append("slot", JSON.stringify(values?.slot));
    formData.append(
      "startDate",
      PagesIndex.moment(new Date(values?.startDate)).format("YYYY-MM-DD")
    );
    formData.append(
      "endDate",
      PagesIndex.moment(new Date(values?.endDate)).format("YYYY-MM-DD")
    );
    formData.append("weekDay", JSON.stringify(values?.weekDay));
    formData.append("adMobId", values?.adMobId);
    formData.append("perDayAd", values?.perDayAdCount);
    formData.append("totalAdPlay", values?.totalAdsCount);
    formData.append("gender", JSON.stringify(values?.gender));
    formData.append("age", JSON.stringify(values?.age));
    // formData.append("state", values?.state);
    formData.append("link", values?.link);
    formData.append("category", JSON.stringify(values?.category));
    formData.append("district", values?.district ? values?.district : null);
    formData.append("coordinates", JSON.stringify(values?.coordinates));
    formData.append("budget", values?.amount);
    if (values?.imageVideo) {
      formData.append("profile", values?.imageVideo);
    }
    if (values?.adsPart?.length) {
      values?.adsPart
        ?.filter((adsPart) => adsPart?.checked)
        ?.forEach((adsPart, index) => {
          formData.append(`adsPart[${index}]`, adsPart?.label);
        });
    } else {
      formData.append(`adsPart`, []);
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_ADVERTISEMENT, formData)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          navigate("/admin/ads-management");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  useEffect(() => {
    getWeekDays();
  }, [startDate, endDate]);
  const getWeekDays = () => {
    const weekdays = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    let current = start;
    while (current <= end) {
      weekdays.push(current.toLocaleDateString("en-US", { weekday: "long" }));

      current.setDate(current.getDate() + 1);
    }
    weekdays.sort((a, b) => {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return days.indexOf(a) - days.indexOf(b);
    });
    setWeekDays([...new Set([...weekdays])]);
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {id ? "Edit" : "Add"} Advertisement
        </Index.Typography>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleAddEditAd}
        initialValues={initialValues}
        validationSchema={
          id
            ? PagesIndex.advertisementEditSchema
            : PagesIndex.advertisementSchema
        }
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              {/* <ScrollIntoView /> */}
              <Index.Box className="common-card add-ads-card">
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Schedule of Advertisement
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="grid-row">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            Client<span className="astrick-sing">*</span>
                            {adminDetails?.roleId?.permissions?.includes(
                              "clients_add"
                            ) && (
                              <span className="option-condition-text">
                                (
                                <PagesIndex.Link
                                  to="/admin/add-client"
                                  target="_blank"
                                  className="add-new-page-link"
                                >
                                  <span>Add Client</span>
                                </PagesIndex.Link>
                                )
                              </span>
                            )}
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-control"
                                  options={advertiserList || []}
                                  id="advertiser"
                                  value={
                                    values?.advertiser
                                      ? advertiserList?.find(
                                          (o) => o._id === values.advertiser
                                        ) ?? null
                                      : null
                                  }
                                  disableClearable
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  onChange={(e, value) => {
                                    setFieldValue("advertiser", value?._id);
                                  }}
                                  getOptionLabel={(option) => option?.compName}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.compName}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      {...params}
                                      size="small"
                                      name="advertiser"
                                      placeholder="Select Client"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.advertiser && touched.advertiser
                              ? errors.advertiser
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main admin-radio-main">
                          <Index.FormHelperText className="admin-form-lable">
                            Type<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            id="type"
                            name="type"
                            className="admin-radiogroup"
                            value={values?.type}
                            onChange={handleChange}
                          >
                            <Index.FormControlLabel
                              value="Fixed cost"
                              control={<Index.Radio className="admin-radio" />}
                              label="Fixed cost"
                            />
                            <Index.FormControlLabel
                              value="On Click, On View"
                              control={<Index.Radio className="admin-radio" />}
                              label="On Click, On View"
                            />
                          </Index.RadioGroup>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.type && touched.type ? errors.type : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Amount
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="amount"
                              fullWidth
                              id="amount"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "e" ||
                                  e.key === "E" ||
                                  e.key === "-" ||
                                  e.key === "." ||
                                  e.key === "+"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onWheelCapture={(e) => {
                                e.target.blur();
                              }}
                              className="admin-form-control"
                              placeholder="Enter Amount"
                              onChange={(e) => {
                                const regex = /^[0-9]*$/;
                                if (
                                  regex.test(e.target.value) &&
                                  e.target.value <= 10000000
                                ) {
                                  handleChange(e);
                                }
                              }}
                              value={values?.amount}
                              inputProps={{ maxLength: 8, min: "0" }}
                            />
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.amount && touched.amount
                              ? errors.amount
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box admin-date-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            Start Date<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box
                            className="admin-form-group"
                            id="startDate"
                          >
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.DatePicker
                                open={openCalenderStart}
                                onClose={() => setOpenCalenderStart(false)}
                                className="admin-form-control admin-date-picker-control"
                                value={values.startDate}
                                minDate={dayjs().add(0, "day")}
                                onChange={(date) => {
                                  setStartDate(date);
                                  setFieldValue("startDate", date);
                                  setEndDate("");
                                  setFieldValue("endDate", null);
                                }}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    readOnly: true,
                                    onClick: () => setOpenCalenderStart(true),
                                  },
                                  inputAdornment: {
                                    onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                                  },
                                }}
                                format="DD/MM/YYYY"
                              />
                            </PagesIndex.LocalizationProvider>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.startDate && touched.startDate
                              ? errors.startDate
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box admin-date-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            End Date<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group" id="endDate">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DatePicker
                                open={openCalenderEnd}
                                onClose={() => setOpenCalenderEnd(false)}
                                className="admin-form-control admin-date-picker-control"
                                value={values.endDate} // Use '01/01/2000' as the default value
                                minDate={
                                  values.startDate
                                    ? dayjs(values.startDate).add(0, "day")
                                    : dayjs().add(0, "day")
                                }
                                onChange={(date) => {
                                  setEndDate(date);
                                  setFieldValue("endDate", date);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                    onClick: () => setOpenCalenderEnd(true),
                                  },
                                  inputAdornment: {
                                    onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                                  },
                                }}
                                format="DD/MM/YYYY"
                              />
                            </PagesIndex.LocalizationProvider>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.endDate && touched.endDate
                              ? errors.endDate
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Weekdays<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed"
                                  options={
                                    weekDays?.length ? ["All", ...weekDays] : []
                                  }
                                  id="weekDay"
                                  multiple
                                  disableCloseOnSelect
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={values?.weekDay}
                                  onChange={(e, selectedOptions) => {
                                    if (selectedOptions?.includes("All")) {
                                      setFieldValue("weekDay", [...weekDays]);
                                    } else {
                                      setFieldValue(
                                        "weekDay",
                                        selectedOptions.map((option) => option)
                                      );
                                    }
                                  }}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item}>
                                      {item}
                                    </li>
                                  )}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      name="weekDay"
                                      placeholder={
                                        values?.weekDay?.length
                                          ? ""
                                          : "Select Weekdays"
                                      }
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.weekDay && touched.weekDay
                              ? errors.weekDay
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Time Bands<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed"
                                  options={["All", ...slotsList]}
                                  id="slot"
                                  multiple
                                  disableCloseOnSelect
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={values?.slot}
                                  onChange={(e, selectedOptions) => {
                                    if (selectedOptions?.includes("All")) {
                                      setFieldValue("slot", [...slotsList]);
                                    } else {
                                      setFieldValue(
                                        "slot",
                                        selectedOptions.map((option) => option)
                                      );
                                    }
                                  }}
                                  getOptionLabel={(option) => option}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item}>
                                      {item}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      name="slot"
                                      placeholder={
                                        values?.slot?.length
                                          ? ""
                                          : "Select Time band"
                                      }
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.slot && touched.slot ? errors.slot : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Per Day Ad<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="perDayAdCount"
                              fullWidth
                              id="perDayAdCount"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "e" ||
                                  e.key === "E" ||
                                  e.key === "-" ||
                                  e.key === "." ||
                                  e.key === "+"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onWheelCapture={(e) => {
                                e.target.blur();
                              }}
                              className="admin-form-control"
                              placeholder="Enter Per Day Ad"
                              onChange={(e) => {
                                const regex = /^[0-9]*$/;
                                if (
                                  regex.test(e.target.value) &&
                                  e.target.value <= 10000000
                                ) {
                                  handleChange(e);
                                }
                              }}
                              value={values?.perDayAdCount}
                              inputProps={{ maxLength: 8, min: "0" }}
                            />
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.perDayAdCount && touched.perDayAdCount
                              ? errors.perDayAdCount
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Total Ads To Play
                          </Index.FormHelperText>
                          <Index.Box className="ads-info-flex">
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                name="totalAdsCount"
                                fullWidth
                                id="totalAdsCount"
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "e" ||
                                    e.key === "E" ||
                                    e.key === "-" ||
                                    e.key === "." ||
                                    e.key === "+"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                onWheelCapture={(e) => {
                                  e.target.blur();
                                }}
                                className="admin-form-control"
                                placeholder="Enter Total Ads To Play"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  if (
                                    regex.test(e.target.value) &&
                                    e.target.value <= 1000000
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                value={values?.totalAdsCount}
                                inputProps={{ maxLength: 8, min: "0" }}
                              />
                            </Index.Box>
                            <Index.Tooltip
                              title="This is the total Ads to be played of the advertiser in total so once this count is reached then this ad of the advertiser won't play in the user app"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <img
                                src={PagesIndex.Svg.infoicon}
                                className="info-icon"
                                alt="information"
                              />
                            </Index.Tooltip>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.totalAdsCount && touched.totalAdsCount
                              ? errors.totalAdsCount
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            Media Type<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control">
                                <Index.Select
                                  id="mediaType"
                                  name="mediaType"
                                  className="admin-dropdown-select"
                                  value={values?.mediaType}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue("imageVideo", "");
                                    setFieldValue("imageUrl", "");
                                    setFieldTouched("mediaType", false);
                                    setFieldTouched("imageVideo", false);
                                    setFieldTouched("imageUrl", false);
                                  }}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.mediaType
                                      ? undefined
                                      : () => "Select Media Type"
                                  }
                                >
                                  <Index.MenuItem
                                    value={"adMob"}
                                    className="admin-menuitem"
                                  >
                                    Ad Mob
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value={"image"}
                                    className="admin-menuitem"
                                  >
                                    Image
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value={"video"}
                                    className="admin-menuitem"
                                  >
                                    Video
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.mediaType && touched.mediaType
                              ? errors.mediaType
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        {values.mediaType !== "adMob" ? (
                          <Index.Box className="add-ads-input-main admin-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Advertisement Redirect Link
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                name="link"
                                fullWidth
                                id="link"
                                className="admin-form-control"
                                placeholder="Enter Advertisement Redirect Link"
                                onChange={handleChange}
                                value={values?.link}
                              />
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.link && touched.link ? errors.link : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        ) : (
                          ""
                        )}
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        {values.mediaType === "adMob" ? (
                          <Index.Box className="add-ads-input-main admin-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Ad Mob Id<span className="astrick-sing">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.TextField
                                name="adMobId"
                                fullWidth
                                id="adMobId"
                                className="admin-form-control"
                                placeholder="Enter Ad Mob Id"
                                onChange={handleChange}
                                value={values?.adMobId}
                                inputProps={{ maxLength: 50 }}
                              />
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.adMobId && touched.adMobId
                                ? errors.adMobId
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        ) : (
                          <Index.Box className="admin-input-box add-ads-input-main admin-radio-main">
                            <Index.FormHelperText className="admin-form-lable">
                              Upload Ad<span className="astrick-sing">*</span>
                              {values.mediaType === "image" && (
                                <span className="option-condition-text">
                                  (Image must be 135px(Width) * 100px(Height))
                                </span>
                              )}
                            </Index.FormHelperText>
                            <Index.Box className="file-upload-main thumbnail-box">
                              <Index.Button
                                variant="contained"
                                component="label"
                                className="file-upload-button"
                              >
                                {(values?.imageVideo || values?.imageUrl) &&
                                values?.mediaType === "video" ? (
                                  <video
                                    ref={videoRef}
                                    loop={true}
                                    autoPlay="autoplay"
                                    muted
                                    className={
                                      values?.imageVideo || values?.imageUrl
                                        ? "upload-img"
                                        : "upload-profile-img"
                                    }
                                  >
                                    <source src={renderImg(values)} />
                                  </video>
                                ) : (
                                  <img
                                    src={renderImg(values)}
                                    alt="upload"
                                    className={
                                      values?.imageVideo || values?.imageUrl
                                        ? "upload-img"
                                        : "upload-profile-img"
                                    }
                                  />
                                )}
                                <input
                                  hidden
                                  accept={
                                    values.mediaType === "image"
                                      ? "image/*"
                                      : "video/*"
                                  }
                                  id="image"
                                  name="image"
                                  type="file"
                                  value={""}
                                  onChange={(e) => {
                                    videoRef.current?.load();
                                    setFieldValue(
                                      "imageVideo",
                                      e.target.files[0]
                                    );
                                  }}
                                />
                              </Index.Button>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.imageVideo && touched.imageVideo
                                ? errors.imageVideo
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-card add-ads-card">
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Manage whom to show
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="grid-row">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Gender<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed"
                                  options={["All", "Male", "Female", "Other"]}
                                  id="gender"
                                  multiple
                                  disableCloseOnSelect
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={values?.gender}
                                  onChange={(e, selectedOptions) => {
                                    if (selectedOptions?.includes("All")) {
                                      setFieldValue("gender", [
                                        "Male",
                                        "Female",
                                        "Other",
                                      ]);
                                    } else {
                                      setFieldValue(
                                        "gender",
                                        selectedOptions.map((option) => option)
                                      );
                                    }
                                  }}
                                  getOptionLabel={(option) => option}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item}>
                                      {item}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      name="gender"
                                      placeholder={
                                        values?.gender?.length
                                          ? ""
                                          : "Select Gender"
                                      }
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.gender && touched.gender
                              ? errors.gender
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Age Groups<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className=" admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed"
                                  options={["All", ...agesList]}
                                  id="age"
                                  multiple
                                  disableCloseOnSelect
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={values?.age}
                                  onChange={(e, selectedOptions) => {
                                    if (selectedOptions?.includes("All")) {
                                      setFieldValue("age", [...agesList]);
                                    } else {
                                      setFieldValue(
                                        "age",
                                        selectedOptions.map((option) => option)
                                      );
                                    }
                                  }}
                                  getOptionLabel={(option) => option}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item}>
                                      {item}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      name="age"
                                      placeholder={
                                        values?.age?.length
                                          ? ""
                                          : "Select Age Group"
                                      }
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.age && touched.age ? errors.age : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            State<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control">
                                <Index.Select
                                  name="state"
                                  className="admin-dropdown-select "
                                  value={values?.state}
                                  onChange={handleChange}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.state
                                      ? undefined
                                      : () => "Select State"
                                  }
                                >
                                  {stateList?.map((data) => {
                                    return (
                                      <Index.MenuItem
                                        key={data?._id}
                                        value={data?._id}
                                        className="admin-menuitem"
                                      >
                                        {data?.stateName}
                                      </Index.MenuItem>
                                    );
                                  })}
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.state && touched.state
                              ? errors.state
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box> */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            District
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Autocomplete
                                  id="district"
                                  className="admin-auto-complete-control"
                                  options={(districtData || []).sort((a, b) =>
                                    a?.name?.localeCompare(b?.name)
                                  )}
                                  // disableClearable={true}
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={
                                    values?.district && districtData
                                      ? districtData.find(
                                          (o) => o._id === values.district
                                        ) || null
                                      : null
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("district", value?._id);
                                  }}
                                  getOptionLabel={(option) => option?.name}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      name="district"
                                      className="admin-form-control"
                                      {...params}
                                      placeholder="Select District"
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                              {touched.district && errors.district && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.district}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Tags
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.FormControl className="admin-form-control admin-auto-complete-control">
                              <Index.Autocomplete
                                id="tags"
                                className="admin-auto-complete-filed"
                                options={tagData || []}
                                multiple
                                disableCloseOnSelect
                                freeSolo
                                closeText={""}
                                openText={""}
                                clearText={""}
                                getOptionSelected={(option, value) =>
                                  option._id === value._id
                                }
                                value={
                                  values?.tags
                                    ? tagData.filter((tag) =>
                                        values?.tags?.includes(tag._id)
                                      )
                                    : []
                                }
                                onChange={(e, values) => {
                                  if (!e.key) {
                                    setFieldValue(
                                      "tags",
                                      values.map((tag) => tag._id)
                                    );
                                  }
                                }}
                                getOptionLabel={(option) => option?.tag}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.tag}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    name="tags"
                                    fullWidth
                                    className="admin-form-control"
                                    {...params}
                                    size="small"
                                    placeholder={
                                      values?.tags?.length ? "" : "Enter Tag"
                                    }
                                    variant="outlined"
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        event.preventDefault(); // Prevent form submission
                                        const inputValue =
                                          event.target.value.trim();
                                        if (
                                          inputValue &&
                                          !values?.tags?.includes(inputValue)
                                        ) {
                                          setFieldValue("tags", [
                                            ...(values?.tags
                                              ? values.tags
                                              : []),
                                            inputValue,
                                          ]);
                                        }
                                        if (
                                          !tagData.find(
                                            (tag) => tag._id === inputValue
                                          )
                                        ) {
                                          setTagData((prev) => [
                                            ...prev,
                                            {
                                              _id: inputValue,
                                              tag: inputValue,
                                            },
                                          ]);
                                        }
                                        event.target.value = "";
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Index.FormControl>
                            {touched.tags && errors.tags && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.tags}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box add-ads-input-main input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Genres/Categories
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.FormControl className="admin-form-control admin-auto-complete-control">
                              <Index.Autocomplete
                                className="admin-auto-complete-filed"
                                options={categoryList}
                                name="category"
                                multiple
                                closeText={""}
                                openText={""}
                                clearText={""}
                                disableCloseOnSelect
                                value={
                                  values?.category
                                    ? categoryList.filter((data) =>
                                        values?.category?.some(
                                          (cat) => cat === data?._id
                                        )
                                      )
                                    : []
                                }
                                onChange={(e, selectedOptions) => {
                                  setFieldValue(
                                    "category",
                                    selectedOptions.map((option) => option?._id)
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  option?.categoryName
                                }
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.categoryName}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    className="admin-form-control"
                                    placeholder={
                                      values?.category?.length
                                        ? ""
                                        : "Select Genre/Category"
                                    }
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                              {touched.category && errors.category && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.category}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Video
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed"
                                  options={[...newsList]}
                                  name="newsId"
                                  multiple
                                  disableCloseOnSelect
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={newsList?.filter((data) =>
                                    values?.newsId?.some(
                                      (cat) => cat === data._id
                                    )
                                  )}
                                  onChange={(e, selectedOptions) => {
                                    setFieldValue(
                                      "newsId",
                                      selectedOptions.map(
                                        (option) => option?._id
                                      )
                                    );
                                  }}
                                  getOptionLabel={(option) =>
                                    `${option?.newsNo || "NC"}-${
                                      option?.newsTitle
                                    }`
                                  }
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.newsNo || "NC"}-{item?.newsTitle}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      placeholder={
                                        values?.newsId?.length
                                          ? ""
                                          : "Select Video"
                                      }
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.newsId && touched.newsId
                              ? errors.newsId
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        {" "}
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.Box
                            className="admin-news-check-main"
                            id="checked"
                          >
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={values?.checked}
                                onChange={(e) =>
                                  setFieldValue("checked", !values?.checked)
                                }
                                name="checked"
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Allow Skip Advertise
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        {" "}
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Ad Position<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box
                            className="admin-news-check-main"
                            id="checked"
                          >
                            {values?.adsPart?.map((item, index) => {
                              return (
                                <Index.Box
                                  className="admin-checkbox-main"
                                  key={item?.label}
                                >
                                  <BpCheckbox
                                    checked={item?.checked}
                                    onChange={(e) => {
                                      let updatedChecked = values.adsPart.map(
                                        (item, idx) => ({
                                          ...item,
                                          checked:
                                            idx === index
                                              ? e.target.checked
                                              : item.checked,
                                        })
                                      );
                                      setFieldValue(`adsPart`, updatedChecked);
                                    }}
                                    name={`adsPart[${index}]`}
                                  />
                                  <Index.Typography className="admin-checkbox-lable">
                                    {item?.label}
                                  </Index.Typography>
                                </Index.Box>
                              );
                            })}
                          </Index.Box>
                          {touched.adsPart && errors.adsPart && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.adsPart}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-ads-input-main admin-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            User Location
                          </Index.FormHelperText>
                          <PagesIndex.Location
                            initialValues={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.Box
                            className="admin-news-check-main"
                            id="checked"
                          >
                            <MapComp coordinates={values?.coordinates} />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-user-btn-flex">
                          <Index.Box className="border-btn-main">
                            <Index.Button
                              className="border-btn"
                              disabled={buttonSpinner}
                              onClick={() => navigate("/admin/ads-management")}
                            >
                              Discard
                            </Index.Button>
                          </Index.Box>
                          <Index.Box className="btn-main-primary">
                            <Index.Button
                              className={`${
                                id ? "update-btn" : "save-btn"
                              } admin-save-user-btn btn-primary`}
                              type="submit"
                              disabled={buttonSpinner}
                            >
                              {buttonSpinner ? (
                                <PagesIndex.Spinner />
                              ) : (
                                <>
                                  <img
                                    src={PagesIndex.Svg.save}
                                    className="admin-user-save-icon"
                                    alt="Save"
                                  />
                                  <span>{id ? "Update" : "Add"}</span>
                                </>
                              )}
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          );
        }}
      </PagesIndex.Formik>
    </Index.Box>
  );
}

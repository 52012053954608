import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { styled } from "@mui/material/styles";
import "./bannerManagement.responsive.css";

// for modal design
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BannerManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [selectuserData, setSelectuserData] = useState();
  const [loading, setLoading] = useState(true);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const formRef = useRef();
  const initialValues = {
    isEdit: !!id,
    title: id ? selectuserData?.title : "",
    image: "",
    imageUrl: id ? selectuserData?.image : "",
    type: id
      ? selectuserData?.internalLink
        ? "internal"
        : selectuserData?.externalLink
        ? "external"
        : "internal"
      : "internal",
    internalLink: id ? selectuserData?.internalLink?._id : "",
    externalLink: id ? selectuserData?.externalLink : "",
  };

  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  const [newsList, setNewsList] = useState([]);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectuserData(data);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleGetBanner = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_BANNER).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        let data = res?.data?.sort((a, b) => a?.title?.localeCompare(b?.title));
        setData(data);
        setFilteredData(data);
      }
    });
  };
  const handleGetNews = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_NEWS).then((res) => {
      if (res.status === 200) {
        setNewsList(
          res.data?.filter(
            (item) =>
              item?.isPublish &&
              item?.isActive &&
              !item.sectionToShow?.includes("660c00dd0b2c9ea28bd1df33")
          )
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    handleGetBanner();
    handleGetNews();
  }, []);

  const handleAddEditBanner = (values) => {
    setButtonSpinner(true);
    const formdata = new FormData();
    if (id) {
      formdata.append("id", id);
    }
    formdata.append("title", values?.title);
    formdata.append("internalLink", values?.internalLink);
    formdata.append("externalLink", values?.externalLink);
    if (values?.image) {
      formdata.append("image", values?.image);
    }

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_BANNER, formdata)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetBanner();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteBanner = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_BANNER, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetBanner();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter((data) =>
      data?.title
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    handleGetBanner();
    setOpenStatusModal(false);
  };

  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.ISACTIVE_BANNER, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false);
        if (res.status === 200) {
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
          handleGetBanner();
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Box className="admin-table-data-flex">
                <img
                  src={
                    data?.image
                      ? process.env.REACT_APP_IMAGE_ENDPOINT + data?.image
                      : PagesIndex.Svg.noimgfound
                  }
                  className="admin-table-data-img"
                  alt="Banner"
                ></img>
              </Index.Box>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.title}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdAt
                  ? PagesIndex.moment(data?.createdAt).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                  : "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes("banner_edit") ||
              adminDetails?.roleId?.permissions?.includes("banner_delete")) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-switch-main">
                  <Index.FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    className="admin-switch-lable"
                    checked={data?.isActive}
                    onChange={() => {
                      handleOpenStatusModal(data?._id);
                    }}
                  />
                </Index.Box>
              </Index.TableCell>
            )}

            {(adminDetails?.roleId?.permissions?.includes("banner_edit") ||
              adminDetails?.roleId?.permissions?.includes("banner_delete")) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  {adminDetails?.roleId?.permissions?.includes(
                    "banner_edit"
                  ) && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          setId(data?._id);
                          handleOpen("Edit", data?._id, data);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}

                  {adminDetails?.roleId?.permissions?.includes(
                    "banner_delete"
                  ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  // for img
  const renderImage = (values) => {
    const isUploadImg = values?.imageUrl || values.image;
    const isImageBlob = values.image instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        className={isUploadImg ? "upload-img" : "upload-profile-img"}
        src={src}
        alt="banner "
      />
    );
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Banner List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {adminDetails?.roleId?.permissions?.includes("banner_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      setId("");
                      handleOpen("Add");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Banner</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box
            className={`admin-category-table-main ${
              loading ? "" : "page-table-main"
            }`}
          >
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                      >
                        Thumbnail
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="30%"
                      >
                        Title
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="30%"
                      >
                        Date & Time
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                        align="right"
                      >
                        Created By
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                        align="right"
                      >
                        Updated By
                      </Index.TableCell>

                      {adminDetails?.roleId?.permissions?.includes(
                        "banner_edit"
                      ) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="6%"
                          align="right"
                        >
                          Status
                        </Index.TableCell>
                      )}

                      {(adminDetails?.roleId?.permissions?.includes(
                        "banner_edit"
                      ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "banner_delete"
                        )) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="4%"
                          align="right"
                        >
                          Action
                        </Index.TableCell>
                      )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {renderContent()}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditBanner}
          initialValues={initialValues}
          validationSchema={PagesIndex.bannerAddEditSchema}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Banner
                  </Index.Typography>
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Title<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="title"
                        fullWidth
                        id="title"
                        className="admin-form-control"
                        placeholder="Enter Title"
                        onChange={handleChange}
                        value={values?.title}
                        error={errors.title && touched.title}
                        inputProps={{ maxLength: 40 }}
                      />
                      {touched.title && errors.title && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.title}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Thumbnail<span className="astrick-sing">*</span>
                      <span className="option-condition-text">
                        (Image must be 1400px(Width) * 250px(Height))
                      </span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="file-upload-main thumbnail-box">
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="file-upload-button"
                        >
                          {renderImage(values)}
                          <input
                            hidden
                            accept="image/*"
                            id="image"
                            name="image"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("image", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                        {touched.image && errors.image && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.image}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Type
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="admin-dropdown-box">
                        <Index.FormControl className="admin-form-control">
                          <Index.Select
                            className="admin-dropdown-select"
                            name="type"
                            fullWidth
                            id="type"
                            value={values?.type}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("internalLink", "");
                              setFieldValue("externalLink", "");
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={
                              values?.type ? undefined : () => "Select Type"
                            }
                          >
                            <Index.MenuItem
                              value="internal"
                              className="admin-menuitem"
                            >
                              Internal
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="external"
                              className="admin-menuitem"
                            >
                              External
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                      {touched.type && errors.type && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.type}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  {values?.type === "internal" && (
                    <Index.Box className="admin-input-box input-complete-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Internal Link
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Box className="admin-dropdown-box">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              className="admin-auto-complete-filed"
                              options={[...newsList]}
                              name="internalLink"
                              disableCloseOnSelect
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={newsList?.find(
                                (data) => values?.internalLink === data._id
                              )}
                              onChange={(e, selectedOptions) => {
                                setFieldValue(
                                  "internalLink",
                                  selectedOptions?._id
                                );
                              }}
                              getOptionLabel={(option) =>
                                `${option?.newsNo || "NC"}-${option?.newsTitle}`
                              }
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.newsNo || "NC"}-{item?.newsTitle}
                                </li>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder={
                                    values?.internalLink?.length
                                      ? ""
                                      : "Select Internal Link"
                                  }
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.internalLink && touched.internalLink
                          ? errors.internalLink
                          : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  )}
                  {values?.type === "external" && (
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        External Link<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="externalLink"
                          fullWidth
                          id="externalLink"
                          className="admin-form-control"
                          placeholder="Enter External Link"
                          onChange={handleChange}
                          value={values?.externalLink}
                          error={errors.externalLink && touched.externalLink}
                        />
                        {touched.externalLink && errors.externalLink && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.externalLink}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  )}
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${
                          id ? "update-btn" : "save-btn"
                        } admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this banner? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteBanner}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"banner"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function WebsiteScheduleManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const formRef = useRef();
  const videoRef = useRef();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [selectedScheduleData, setSelectedScheduleData] = useState();
  const [loading, setLoading] = useState(true);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const initialValues = {
    platformId: "66449a43ff5d25d454d3b145",
    clientId: id ? selectedScheduleData?.clientId?._id : "",
    startDate: id ? PagesIndex.dayjs(selectedScheduleData?.startDate) : null,
    endDate: id ? PagesIndex.dayjs(selectedScheduleData?.endDate) : null,
    type: id ? selectedScheduleData?.type : "",
    redirectUrl: id ? selectedScheduleData?.redirectUrl : "",
    adType: id ? selectedScheduleData?.adType : "",
    elementId: id ? selectedScheduleData?.elementId?._id : "",
    creativeId: id
      ? selectedScheduleData?.creativeId
        ? selectedScheduleData?.creativeId?._id
        : ""
      : "",
    imageVideo: "",
    imageUrl: id
      ? selectedScheduleData?.image
        ? selectedScheduleData?.image
        : ""
      : "",
    code: id ? selectedScheduleData?.code : "",
  };
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  const [advertiserList, setAdvertiserList] = useState([]);
  const [elementList, setElementList] = useState([]);
  const [creativeList, setCreativeList] = useState([]);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const handleGetSchedule = useCallback(() => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_WEBSITE_SCHEDULE_LIST +
        "/" +
        "66449a43ff5d25d454d3b145"
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  }, []);

  const handleGetAdvertiser = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res?.status === 200) {
        setAdvertiserList(
          res?.data?.filter((item) => item?.isActive && !item?.isDraft)
        );
      }
    });
  }, []);
  const handleGetElement = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ELEMENTS).then((res) => {
      if (res?.status === 200) {
        setElementList(
          res?.data?.filter(
            (item) =>
              item?.isActive &&
              item?.programTypeId?._id === "66449a43ff5d25d454d3b145"
          )
        );
      }
    });
  }, []);
  const handleGetCreative = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CREATIVES).then((res) => {
      if (res?.status === 200) {
        setCreativeList(
          res?.data?.filter(
            (item) =>
              item?.isActive &&
              item?.platformId?._id === "66449a43ff5d25d454d3b145"
          )
        );
      }
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    handleGetAdvertiser();
    handleGetSchedule();
    handleGetElement();
    handleGetCreative();
  }, [
    handleGetSchedule,
    handleGetAdvertiser,
    handleGetElement,
    handleGetCreative,
  ]);
  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectedScheduleData(data);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleAddEditSchedule = (values) => {
    setButtonSpinner(true);
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
    }
    formData.append("platformId", values?.platformId);
    formData.append("clientId", values?.clientId);
    formData.append("type", values?.type);
    formData.append("adType", values?.adType);
    formData.append("image", values?.imageVideo);
    formData.append("creativeId", values?.creativeId);
    formData.append("elementId", values?.elementId);
    formData.append("startDate", values?.startDate);
    formData.append("endDate", values?.endDate);
    formData.append("redirectUrl", values?.redirectUrl);

    PagesIndex.apiPostHandler(
      PagesIndex.Api.ADD_EDIT_WEBSITE_SCHEDULE_LIST,
      formData
    )
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetSchedule();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteSchedule = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_SCHEDULE, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetSchedule();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.clientId?.compName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.elementId?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.type
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        `${PagesIndex.moment(data?.startDate).format(
          "DD/MM/YYYY"
        )} ${PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}`
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(data?.startDate)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        PagesIndex.moment(data?.endDate)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.updatedBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.clientId?.compName}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {" "}
                  {/* <span className="news-id-text">{data?.slotId?.slotNo}</span> */}
                  {data?.clientId?.compName}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${PagesIndex.moment(data?.startDate).format(
                  "DD/MM/YYYY"
                )} to ${PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {PagesIndex.moment(data?.startDate).format("DD/MM/YYYY")} to{" "}
                  {PagesIndex.moment(data?.endDate).format("DD/MM/YYYY")}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.elementId?.name}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.adType}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.type}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${data?.updatedBy?.name} - ${PagesIndex.moment(
                  data?.updatedAt
                ).format("DD/MM/YYYY hh:mm A")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.createdBy?.name} -{" "}
                  {PagesIndex.moment(data?.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${data?.updatedBy?.name} - ${PagesIndex.moment(
                  data?.updatedAt
                ).format("DD/MM/YYYY hh:mm A")}`}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.updatedBy?.name} -{" "}
                  {PagesIndex.moment(data?.updatedAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            {(adminDetails?.roleId?.permissions?.includes("website_ad_edit") ||
              adminDetails?.roleId?.permissions?.includes(
                "website_ad_delete"
              )) && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  {adminDetails?.roleId?.permissions?.includes(
                    "website_ad_edit"
                  ) && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          handleOpen("Edit", data?._id, data);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                  {/* <Index.Tooltip
                    title="View"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        navigate(`/admin/view-slot-schedule/${data?._id}`);
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.yelloweye}
                        className="admin-icon"
                        alt="View"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip> */}
                  {/* {adminDetails?.roleId?.permissions?.includes(
                    "website_ad_delete"
                  ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )} */}
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };

  const renderImg = (values) => {
    if (values.imageVideo instanceof Blob) {
      return URL.createObjectURL(values.imageVideo);
    } else if (!values.imageVideo && values?.imageUrl) {
      return `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`;
    } else {
      return PagesIndex.Svg.fileupload;
    }
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Website Schedule List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {adminDetails?.roleId?.permissions?.includes("website_ad_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      setId("");
                      handleOpen("Add");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Schedule</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className={`admin-schedulae-table-main page-table-main`}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="8%"
                      >
                        Client Name
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Schedule Dates
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="7%"
                      >
                        Element
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                      >
                        Advertisement Type
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="5%"
                      >
                        Type
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Created By
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Updated By
                      </Index.TableCell>

                      {(adminDetails?.roleId?.permissions?.includes(
                        "website_ad_edit"
                      ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "website_ad_delete"
                        )) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                          align="right"
                        >
                          Action
                        </Index.TableCell>
                      )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {renderContent()}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditSchedule}
          initialValues={initialValues}
          validationSchema={
            id
              ? PagesIndex.websiteScheduleEditSchema
              : PagesIndex.websiteScheduleAddSchema
          }
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Schedule
                  </Index.Typography>
                </Index.Box>

                <Index.Box
                  component={"form"}
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Client<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={advertiserList || []}
                        id="clientId"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.clientId
                            ? advertiserList?.find(
                                (o) => o._id === values?.clientId
                              )
                            : values?.clientId || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("clientId", value?._id);
                        }}
                        getOptionLabel={(option) => option?.compName}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.compName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="clientId"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Client"
                          />
                        )}
                      />
                      {touched.clientId && errors.clientId && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.clientId}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-date-input-main ">
                    <Index.FormHelperText className="admin-form-lable">
                      <span>Start Date</span>
                      <span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <PagesIndex.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <PagesIndex.DatePicker
                          open={openCalenderStart}
                          onClose={() => setOpenCalenderStart(false)}
                          className="admin-form-control admin-date-picker-control"
                          value={values.startDate} // Use '01/01/2000' as the default value
                          onChange={(date) => {
                            setFieldValue("startDate", date);
                            setFieldValue("endDate", null);
                          }}
                          minDate={PagesIndex.dayjs().add(0, "days")}
                          slotProps={{
                            popper: {
                              className: "date-picker-body",
                            },
                            textField: {
                              readOnly: true,
                              onClick: () => setOpenCalenderStart(true),
                            },
                            inputAdornment: {
                              onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                            },
                          }}
                          format="DD/MM/YYYY"
                        />
                      </PagesIndex.LocalizationProvider>
                      <Index.FormHelperText className="admin-error-text">
                        {errors.startDate &&
                          touched.startDate &&
                          errors.startDate}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-date-input-main ">
                    <Index.FormHelperText className="admin-form-lable">
                      <span>End Date</span>
                      <span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <PagesIndex.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <PagesIndex.DatePicker
                          open={openCalenderEnd}
                          onClose={() => setOpenCalenderEnd(false)}
                          className="admin-form-control admin-date-picker-control"
                          value={values.endDate} // Use '01/01/2000' as the default value
                          onChange={(date) => {
                            setFieldValue("endDate", date);
                          }}
                          minDate={
                            values.startDate
                              ? PagesIndex.dayjs(values.startDate).add(0, "day")
                              : PagesIndex.dayjs().add(0, "day")
                          }
                          slotProps={{
                            popper: {
                              className: "date-picker-body",
                            },
                            textField: {
                              readOnly: true,
                              onClick: () => setOpenCalenderEnd(true),
                            },
                            inputAdornment: {
                              onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                            },
                          }}
                          format="DD/MM/YYYY"
                        />
                      </PagesIndex.LocalizationProvider>
                      <Index.FormHelperText className="admin-error-text">
                        {errors.endDate && touched.endDate && errors.endDate}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Type<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="admin-dropdown-box">
                        <Index.FormControl className="admin-form-control">
                          <Index.Select
                            name="type"
                            className="admin-dropdown-select "
                            value={values?.type}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={
                              values?.type ? undefined : () => "Select Type"
                            }
                          >
                            <Index.MenuItem
                              value="impression"
                              className="admin-menuitem"
                            >
                              Impression
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="click"
                              className="admin-menuitem"
                            >
                              Click
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                        {touched.type && errors.type && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.type}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  {values?.type === "click" && (
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Redirect URL<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="redirectUrl"
                          fullWidth
                          id="redirectUrl"
                          className="admin-form-control"
                          placeholder="Enter Redirect URL"
                          onChange={handleChange}
                          value={values?.redirectUrl}
                          inputProps={{ maxLength: 500 }}
                        />
                      </Index.Box>
                      {errors.redirectUrl && touched.redirectUrl && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.redirectUrl}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  )}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Element<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={elementList || []}
                        id="elementId"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.elementId
                            ? elementList?.find(
                                (o) => o._id === values?.elementId
                              )
                            : values?.elementId || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("elementId", value?._id);
                        }}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="elementId"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Element"
                          />
                        )}
                      />
                      {touched.elementId && errors.elementId && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.elementId}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Advertisement Type<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="admin-dropdown-box">
                        <Index.FormControl className="admin-form-control">
                          <Index.Select
                            name="adType"
                            className="admin-dropdown-select "
                            value={values?.adType}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={
                              values?.adType
                                ? undefined
                                : () => "Select Advertisement Type"
                            }
                          >
                            <Index.MenuItem
                              value="image"
                              className="admin-menuitem"
                            >
                              Image
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="video"
                              className="admin-menuitem"
                            >
                              Video
                            </Index.MenuItem>
                            <Index.MenuItem
                              value="code"
                              className="admin-menuitem"
                            >
                              Code
                            </Index.MenuItem>
                            {/* <Index.MenuItem
                                  value="Program"
                                  className="admin-menuitem"
                                  disabled
                                >
                                  Program
                                </Index.MenuItem> */}
                          </Index.Select>
                        </Index.FormControl>
                        {touched.adType && errors.adType && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.adType}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {(values?.adType === "image" ||
                    values?.adType === "video") && (
                    <>
                      <Index.Box className="admin-input-box admin-modal-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Creative<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.Autocomplete
                            className="admin-auto-complete-control"
                            options={
                              creativeList?.filter(
                                (data) =>
                                  data?.clientId?._id === values?.clientId &&
                                  data?.elementId?._id === values?.elementId
                              ) || []
                            }
                            id="creativeId"
                            closeText={""}
                            openText={""}
                            clearText={""}
                            value={
                              values?.creativeId
                                ? creativeList?.find(
                                    (o) => o._id === values?.creativeId
                                  )
                                : values?.creativeId || null
                            }
                            onChange={(e, value) => {
                              if (value) {
                                setFieldValue("creativeId", value?._id);
                              } else {
                                setFieldValue("creativeId", "");
                              }
                            }}
                            getOptionLabel={(option) =>
                              `${option?.code}-${option?.name}`
                            }
                            renderOption={(props, item) => (
                              <li {...props} key={item?._id}>
                                {item?.code}-{item?.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <Index.TextField
                                fullWidth
                                className="admin-form-control"
                                name="creativeId"
                                {...params}
                                size="small"
                                variant="outlined"
                                placeholder="Select Creative"
                              />
                            )}
                          />
                          {errors.creativeId && touched.creativeId && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.creativeId}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box className="admin-input-box add-ads-input-main admin-radio-main">
                          <Index.FormHelperText className="admin-form-lable">
                            Upload Ad<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="file-upload-main thumbnail-box">
                            {values?.imageVideo && <Index.Button className="video-close-btn" onClick={() => {
                              setFieldValue("imageVideo", "")
                              setFieldValue("imageUrl", "")
                            }}>
                              <img src={PagesIndex.Svg.close} alt="close" className="video-close-img" />
                            </Index.Button>}
                            <Index.Button
                              variant="contained"
                              component="label"
                              className="file-upload-button"
                            >
                              {(values?.imageVideo || values?.imageUrl) &&
                                values?.adType === "video" ? (
                                <video
                                  ref={videoRef}
                                  loop={true}
                                  autoPlay="autoplay"
                                  muted
                                  className={
                                    values?.imageVideo || values?.imageUrl
                                      ? "upload-img"
                                      : "upload-profile-img"
                                  }
                                >
                                  <source src={renderImg(values)} />
                                </video>
                              ) : (
                                <img
                                  src={renderImg(values)}
                                  alt="upload"
                                  className={
                                    values?.imageVideo || values?.imageUrl
                                      ? "upload-img"
                                      : "upload-profile-img"
                                  }
                                />
                              )}
                              <input
                                hidden
                                accept={
                                  values.adType === "image"
                                    ? "image/*"
                                    : "video/*"
                                }
                                id="image"
                                name="image"
                                type="file"
                                value={""}
                                onChange={(e) => {
                                  videoRef.current?.load();
                                  setFieldValue("imageVideo", e.target.files[0]);
                                }}
                              />
                            </Index.Button>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.imageVideo && touched.imageVideo
                              ? errors.imageVideo
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box> */}
                    </>
                  )}
                  {values?.adType === "code" && (
                    <Index.Box className="add-ads-input-main admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Code<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="code"
                          fullWidth
                          id="code"
                          className="admin-form-control"
                          placeholder="Enter Code"
                          onChange={handleChange}
                          value={values?.code}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Index.Box>
                      {errors.code && touched.code && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.code}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  )}
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="border-btn-main">
                      <Index.Button
                        className="border-btn"
                        onClick={() => handleClose()}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className={`${
                          id ? "update-btn" : "save-btn"
                        } admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this schedule? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteSchedule}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

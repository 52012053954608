import React, { useEffect, useState, useCallback, useRef } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const colorTheme = [
  "#111827",
  "#6b7280",
  "#c85803",
  "#000",
  "#b90005",
  "#3c0000",
  "#717171",
  "#dbd5ce",
  "#5cb37d",
  "#adb5bd",
  "#ffa447",
  "#59B4C3",
  "#D04848",
  "#173ab8",
];

export default function Advertisments(props) {
  const { id } = props;
  const formRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openLogModal, setOpenLogModal] = useState(false);
  const [logLodear, setLogLodear] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const [adData, setAdData] = useState([]);
  const [adNo, setAdNo] = useState("");

  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);

  const initialValues = {
    startDate: null,
    endDate: null,
    type: "",
  };

  // const handleSearchData = (value) => {
  //   let filteredData = data?.filter((data) => {
  //     let selectType = data?.type ? data?.type
  //       ?.toLowerCase()
  //       .includes(value?.type?.trim()?.toLowerCase()) : true;

  //     let createdAtDate = PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY");
  //     const momentStartDate = PagesIndex.moment(new Date(value?.startDate));
  //     const momentEndDate = PagesIndex.moment(new Date(value?.endDate));

  //     const formattedStartDate = momentStartDate.format("DD/MM/YYYY");
  //     const formattedEndDate = momentEndDate.format("DD/MM/YYYY");

  //     let startDate = PagesIndex.moment(formattedStartDate, 'DD/MM/YYYY');
  //     let endDate = PagesIndex.moment(formattedEndDate, 'DD/MM/YYYY');
  //     let createdAt = PagesIndex.moment(createdAtDate, 'DD/MM/YYYY');

  //     let filterStartDate = startDate.isValid() ? value?.startDate ? startDate.isSameOrBefore(createdAt) : true : true;
  //     let filterEndDate = endDate.isValid() ? value?.endDate ? createdAt.isSameOrBefore(endDate) : true : true;

  //     return selectType && filterStartDate && filterEndDate;
  //   });
  //   // Set the filtered data
  //   setFilterData(filteredData);
  // };

  const handleSearchData = (value) => {
    let filteredData = data?.filter((data) => {
      let selectType = data?.type
        ? data.type.toLowerCase().includes(value?.type?.trim()?.toLowerCase())
        : true;
      let createdAtDate = PagesIndex.moment(data?.createdAt).format(
        "DD/MM/YYYY"
      );
      const [momentStartDate, momentEndDate] = [
        PagesIndex.moment(new Date(value?.startDate)),
        PagesIndex.moment(new Date(value?.endDate)),
      ];
      const [formattedStartDate, formattedEndDate] = [
        momentStartDate.format("DD/MM/YYYY"),
        momentEndDate.format("DD/MM/YYYY"),
      ];
      let [startDate, endDate, createdAt] = [
        PagesIndex.moment(formattedStartDate, "DD/MM/YYYY"),
        PagesIndex.moment(formattedEndDate, "DD/MM/YYYY"),
        PagesIndex.moment(createdAtDate, "DD/MM/YYYY"),
      ];
      let filterStartDate = startDate.isValid()
        ? value?.startDate
          ? startDate.isSameOrBefore(createdAt)
          : true
        : true;
      let filterEndDate = endDate.isValid()
        ? value?.endDate
          ? createdAt.isSameOrBefore(endDate)
          : true
        : true;
      return selectType && filterStartDate && filterEndDate;
    });
    setFilterData(filteredData);
  };

  const generateRandomColors = (numColors) => {
    const colors = [];
    const usedIndexes = new Set();

    while (colors.length < numColors) {
      const randomIndex = Math.floor(Math.random() * colorTheme.length);
      if (!usedIndexes.has(randomIndex)) {
        colors.push(colorTheme[randomIndex]);
        usedIndexes.add(randomIndex);
      }
    }

    return colors;
  };

  const handleOpenLogModal = (client_id, adNo) => {
    setOpenLogModal(true);
    setLogLodear(true);
    setAdNo(adNo);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.ADVERTISERWISE_AD_LIST + "/" + client_id
    ).then((res) => {
      if (res.status === 200) {
        setData(res?.data);
        setFilterData(res?.data);
        setLogLodear(false);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const geUserGraphData = useCallback(() => {
    setLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_USER_GRAPH_DATA + "/" + id
    ).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setAdData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  }, [id]);

  useEffect(() => {
    geUserGraphData();
  }, [geUserGraphData]);

  const renderData = () => {
    return filterData
      .slice()
      .reverse()
      .map((data) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <>
              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.createdAt
                    ? PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")
                    : "-"}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.newsId?.newsNo ? data?.newsId?.newsNo : "-"}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell
                component="td"
                variant="td"
                scope="row"
                className="table-td"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.type ? data?.type : "View"}
                </Index.Typography>
              </Index.TableCell>

              <Index.TableCell component="td" variant="td" className="table-td">
                {data?.userId?.firstName
                  ? data?.userId?.firstName + " " + data?.userId?.lastName
                  : "-"}
              </Index.TableCell>
            </>
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filterData?.length) {
      return renderData();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  // for category slider
  const slidercategory = {
    slidercategory: {
      0: {
        items: 1,
        margin: 0,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4,
      },
      1200: {
        items: 5,
      },
      1500: {
        items: 7,
      },
    },
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateCSV = async () => {
    const headers = ["Date", "News Id", "Type", "User Name"];
    let modifiedData = data?.map((data) => {
      let objData = [
        data?.createdAt
          ? PagesIndex.moment(data?.createdAt).format("DD/MM/YYYY")
          : "-",
        data?.newsId?.newsNo ? data?.newsId?.newsNo?.replaceAll(",", "|") : "-",
        data?.type ? data?.type?.replaceAll(",", "|") : "-",
        data?.userId?.firstName
          ? data?.userId?.firstName?.replaceAll(",", "|")
          : "-",
      ];
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_User_Activity_List .csv`,
      "text/csv"
    );
  };

  return (
    <Index.Box>
      {adData?.length > 0 && (
        <>
          <Index.Box className="chart-title-main">
            <Index.Typography
              className="chart-title"
              component="h2"
              variant="h2"
            >
              Content Views Distribution
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dash-slider-row">
            <Index.Box className="calegory-slider-main">
              <OwlCarousel
                className="owl-theme calegory-slider-owl"
                margin={10}
                responsive={slidercategory.slidercategory}
                loop={false}
                nav={true}
                width={250}
                dots={false}
                stagePadding={0}
                navText={[
                  "<img src=" + PagesIndex.Svg.previousarrow + " >",
                  "<img src=" + PagesIndex.Svg.nextarrow + " >",
                ]}
              >
                {adData?.length > 0
                  ? adData?.map((data) => {
                      const totalAdPlay = parseFloat(
                        data?.totalAdPlay ? data?.totalAdPlay : 0
                      );
                      const noOfAdsPlayed = parseFloat(
                        data?.noOfAdsPlayed ? data?.noOfAdsPlayed : 0
                      );
                      const noOfAdsClicked = parseFloat(
                        data?.noOfAdsClicked ? data?.noOfAdsClicked : 0
                      );
                      const perDayAd = parseFloat(
                        data?.perDayAd ? data?.perDayAd : 0
                      );
                      const noOfAdsPlayedForToday = parseFloat(
                        data?.noOfAdsPlayedForToday
                          ? data?.noOfAdsPlayedForToday
                          : 0
                      );
                      const colors = generateRandomColors(4);

                      const options = {
                        chart: {
                          width: "100%",
                          type: "donut",
                        },
                        stroke: {
                          width: 0,
                        },
                        dataLabels: {
                          style: {
                            fontSize: "10px",
                            fontWeight: 100,
                          },
                          formatter: function (val) {
                            return;
                          },
                        },
                        legend: {
                          position: "bottom",
                        },
                        noData: {
                          text: "No data available",
                          align: "center",
                          verticalAlign: "middle",
                          offsetX: 0,
                          offsetY: 0,
                          style: {
                            color: undefined,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                          },
                        },
                        responsive: [
                          {
                            breakpoint: 1375,
                            options: {
                              chart: {
                                width: "100%",
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                        // plotOptions: {
                        //   pie: {
                        //     startAngle: -90,
                        //     endAngle: 90
                        //   }
                        // },
                        series: [noOfAdsPlayed, noOfAdsClicked, totalAdPlay],
                        labels: ["Played", "Clicked", "Total Played"],
                      };

                      return (
                        <div className="item" key={data?._id}>
                          <Index.Box className="chart-main-box flex-full common-card">
                            <Index.Box className="chart-box">
                              <PagesIndex.ReactApexChart
                                options={options}
                                series={options.series}
                                type="donut"
                                height={300}
                                width={options.chart.width}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "40%",
                                  left: "53%",
                                  transform: "translate(-50%, -50%)",
                                  fontSize: "10px",
                                }}
                              >
                                <span className="news-id-text">
                                  {data?.adsNo}
                                </span>
                              </div>
                            </Index.Box>
                          </Index.Box>
                        </div>
                      );
                    })
                  : null}
              </OwlCarousel>
            </Index.Box>
          </Index.Box>
        </>
      )}

      <Index.Box className="dash-slider-row">
        <Index.Box className="analytic-title-flex dash-live-news-title-flex">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              Ads Played List
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="dash-slider-row">
        <Index.Box className="page-table-main common-card">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? (
              <PagesIndex.Loader />
            ) : (
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Advertisement No.
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Media Type
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="7%"
                    >
                      Budget
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="8%"
                    >
                      Type
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Per Day Ad
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="7%"
                    >
                      Total Ads to Play
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="8%"
                    >
                      No. Of Times Ad Played
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="2%"
                      align="right"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {adData?.length ? (
                    adData
                      ?.slice(
                        (currentPage - 1) * 10,
                        (currentPage - 1) * 10 + 10
                      )
                      ?.map((data) => {
                        return (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={data?._id}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td border-btn-main"
                            >
                              <span className="news-id-text">
                                {" "}
                                {data?.adsNo || "-"}
                              </span>
                            </Index.TableCell>

                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                            >
                              <Index.Box className="admin-table-data-flex">
                                {data?.mediaType}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.budget || "-"}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.type || "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.perDayAd || "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.totalAdPlay || "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {" "}
                              {data?.noOfAdsPlayed}
                            </Index.TableCell>

                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              <Index.Tooltip
                                title="View"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Box className="admin-table-data-btn-flex">
                                  <Index.Button
                                    className="admin-table-data-btn"
                                    onClick={() =>
                                      handleOpenLogModal(data?._id, data?.adsNo)
                                    }
                                  >
                                    <img
                                      src={PagesIndex.Svg.yelloweye}
                                      className="admin-icon"
                                      alt="View"
                                    ></img>
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                  ) : (
                    <PagesIndex.DataNotFound />
                  )}
                </Index.TableBody>
              </Index.Table>
            )}
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-pagination-main">
        {adData.length ? (
          <Index.Pagination
            page={currentPage}
            count={Math.ceil(adData.length / 10)}
            onChange={(event, newPage) => setCurrentPage(newPage)}
            variant="outlined"
            shape="rounded"
            className="admin-pagination"
          />
        ) : (
          ""
        )}
      </Index.Box>

      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              User Activity List <span className="title-text">({adNo})</span>
            </Index.Typography>

            {filterData?.length > 0 && (
              <Index.Box className="admin-export-flex">
                <Index.Box className="admin-save-btn-main btn-main-secondary">
                  <Index.Button
                    className="admin-save-user-btn btn-secondary"
                    onClick={generateCSV}
                  >
                    <img
                      src={PagesIndex.Svg.exporticon}
                      className="admin-plus-icon"
                      alt="Save"
                    ></img>
                    <span>Export CSV</span>
                  </Index.Button>
                </Index.Box>
                <Index.Button
                  className="modal-close-btn"
                  onClick={handleCloseLogModal}
                >
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
            )}
          </Index.Box>

          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleSearchData}
            initialValues={initialValues}
            validationSchema={PagesIndex.userActivityListFilterSchema}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box className="grid-row">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box advertiser-filter-input ">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>Status</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  id="type"
                                  name="type"
                                  value={values?.type}
                                  onChange={handleChange}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    values?.type
                                      ? undefined
                                      : () => "Select Type"
                                  }
                                >
                                  <Index.MenuItem
                                    value="View"
                                    className="admin-drop-menuitem"
                                  >
                                    View
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value={"deactive"}
                                    className="admin-drop-menuitem"
                                  >
                                    Deactive
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.type && touched.type ? errors.type : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 4",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>From Date</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={PagesIndex.AdapterDayjs}
                              className="date-localization-main"
                            >
                              <PagesIndex.DatePicker
                                open={openCalenderStart}
                                onClose={() => setOpenCalenderStart(false)}
                                className="admin-form-control admin-date-picker-control"
                                value={values.startDate}
                                onChange={(date) => {
                                  setFieldValue("startDate", date);
                                  setFieldValue("endDate", null);
                                }}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    readOnly: true,
                                    onClick: () => setOpenCalenderStart(true),
                                  },
                                  inputAdornment: {
                                    onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                                  },
                                }}
                                format="DD/MM/YYYY"
                              />
                            </PagesIndex.LocalizationProvider>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.startDate && touched.startDate
                              ? errors.startDate
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 4",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>To Date</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={PagesIndex.AdapterDayjs}
                              className="date-localization-main"
                            >
                              <PagesIndex.DatePicker
                                open={openCalenderEnd}
                                onClose={() => setOpenCalenderEnd(false)}
                                className="admin-form-control admin-date-picker-control"
                                value={values.endDate}
                                minDate={dayjs(values.startDate).add(0, "day")}
                                disableFuture
                                disabled={!values?.startDate}
                                onChange={(date) => {
                                  setFieldValue("endDate", date);
                                }}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    readOnly: true,
                                    onClick: () => setOpenCalenderEnd(true),
                                  },
                                  inputAdornment: {
                                    onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                                  },
                                }}
                                format="DD/MM/YYYY"
                              />
                            </PagesIndex.LocalizationProvider>
                          </Index.Box>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.endDate && touched.endDate
                              ? errors.endDate
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 1",
                          md: "span 1",
                          lg: "span 1",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box">
                          <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                          <Index.Box className="admin-search-btn-main btn-main-primary ">
                            <Index.Button
                              className="admin-search-btn btn-primary"
                              type="submit"
                            >
                              <img
                                src={PagesIndex.Svg.searchwhite}
                                className="admin-icon"
                                alt="Save"
                              ></img>
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>

          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className={`page-table-main`}>
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {logLodear ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            Date
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="25%"
                          >
                            News Id
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="15%"
                          >
                            Type
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            User Name
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}

Advertisments.propTypes = {
  id: PropTypes.string.isRequired,
};

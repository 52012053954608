import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { styled } from "@mui/material/styles";
import axios from "axios";
import dayjs from "dayjs";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}
export default function UploadVideo() {
  const videoRef = useRef();
  const formRef = useRef();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  // add Upload Video modal
  const [categoryData, setCategoryData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [serverLinkData, setServerLinkData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [openUploadVideo, setOpenUploadVideo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("Copy");
  const [loading, setLoading] = useState(false);
  const [internalServerLink, setInternalServerLink] = useState("");
  const [valueTabs, setValueTabs] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const initialValuesSearch = {
    startDate: null,
    endDate: null,
    tag: null,
    isSubmit: false,
  };
  const initialValues = {
    title: selectedId ? selectedData?.title : "",
    category: selectedId
      ? selectedData?.category?.map((data) => data?._id)
      : [],
    tags: selectedId ? selectedData?.tags?.map((data) => data?._id) : [],
    description: selectedId ? selectedData?.description : "",
    video: "",
    videoUrl: selectedId ? selectedData?.video : "",
    checked: selectedId ? selectedData?.isThirtyDeleted : false,
    serverLink: selectedId ? selectedData?.link : "",
  };
  const handleOpenUploadVideo = () => {
    setOpenUploadVideo(true);
  };
  const handleCloseUploadVideo = () => {
    setSelectedId("");
    setOpenUploadVideo(false);
  };

  // add Upload Video modal
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (id) => {
    setSelectedId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setSelectedId("");
    setOpenDelete(false);
  };
  const [openRestore, setOpenRestore] = useState(false);
  const handleOpenRestore = (id) => {
    setSelectedId(id);
    setOpenRestore(true);
  };
  const handleCloseRestore = () => {
    setSelectedId("");
    setOpenRestore(false);
  };
  const handleGetInernalServerLink = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_SERVER).then((res) => {
      if (res.status === 200) {
        const activeLinks = res?.data?.filter((link) => link?.isActive);
        setServerLinkData(activeLinks);
      }
    });
  };
  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res.status === 200) {
        let data = res?.data?.sort((a, b) =>
          a?.categoryName?.localeCompare(b?.categoryName)
        );
        setCategoryData(data);
      }
    });
  };
  const handleGetTag = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_TAG).then((res) => {
      if (res.status === 200) {
        const activeTags = res?.data?.filter((tag) => tag?.isActive);
        setTagData(activeTags);
      }
    });
  };
  const getSettingData = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SETTING_DATA).then((res) => {
      if (res.status === 200) {
        setInternalServerLink(res?.data?.[0]?.internalUrl);
      }
    });
  };
  useEffect(() => {
    handleGetTag();
    handleGetCategory();
    getSettingData();
    handleGetInernalServerLink();
  }, []);
  const renderImageVideo = (values) => {
    const isUploadVid = values?.videoUrl || values.video;
    const isVideoBlob = values.video instanceof Blob;
    const imageVid = values?.videoUrl
      ? `${values?.link}/api/images/${values?.videoUrl}`
      : null;
    let src;

    if (isVideoBlob) {
      src = URL.createObjectURL(values.video);
    } else if (isUploadVid) {
      src = imageVid;
    } else {
      src = PagesIndex.Svg.fileupload;
    }
    if (isUploadVid) {
      return (
        <video
          ref={videoRef}
          className="upload-img"
          loop={true}
          autoPlay="autoplay"
          muted
        >
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <img
          className={isUploadVid ? "upload-img" : "upload-profile-img"}
          src={src}
          alt="category "
        />
      );
    }
  };

  const handleGetVideos = useCallback(() => {
    const urlencoded = new URLSearchParams();
    if (formRef?.current?.values?.isSubmit) {
      if (formRef?.current?.values?.startDate) {
        urlencoded.append(
          "from",
          PagesIndex.moment(
            new Date(formRef?.current?.values?.startDate)
          ).format("YYYY-MM-DD")
        );
      }
      if (formRef?.current?.values?.endDate) {
        urlencoded.append(
          "to",
          PagesIndex.moment(new Date(formRef?.current?.values?.endDate)).format(
            "YYYY-MM-DD"
          )
        );
      }
      if (formRef?.current?.values?.tag) {
        urlencoded.append("tag", formRef?.current?.values?.tag);
      }
    }
    urlencoded.append(
      "viewAll",
      adminDetails?.roleId?.permissions?.includes("upload_video_view_all")
    );
    urlencoded.append("search", searchValue);
    urlencoded.append("flag", valueTabs);
    urlencoded.append("pageSize", 10);
    urlencoded.append("pageNumber", currentPage);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_UPLOADED_VIDEOS,
      urlencoded
    ).then((res) => {
      if (res.status === 200) {
        setVideoData(res.data);
      }
    });
  }, [valueTabs, currentPage, searchValue]);

  useEffect(() => {
    handleGetVideos();
  }, [handleGetVideos]);

  const handleAddVideo = (values) => {
    setLoading(true);
    const formData = new FormData();
    if (selectedId) {
      formData.append("id", selectedId);
    }
    formData.append("uploadBy", adminDetails?._id);
    formData.append("title", values?.title);
    formData.append("description", values?.description);
    formData.append("category", JSON.stringify(values?.category));
    formData.append("tags", JSON.stringify(values?.tags));
    formData.append("isThirtyDeleted", values?.checked);
    formData.append("link", values?.serverLink);
    formData.append("video", values?.video);
    axios
      .post(`${internalServerLink}/api/upload-video`, formData)
      .then((res) => {
        setLoading(false);
        handleGetVideos();
        handleCloseUploadVideo();
        PagesIndex.toasterSuccess(res?.data?.message);
      })
      .catch((err) => {
        setLoading(false);
        PagesIndex.toasterError(err?.response?.data?.message);
      });
  };
  const handleDeleteVideo = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("deletedBy", adminDetails?._id);
    urlEncoded.append("id", selectedId);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_UPLOADED_VIDEOS, urlEncoded)
      .then((res) => {
        setLoading(false);
        handleGetVideos();
        handleCloseDelete();
        PagesIndex.toasterSuccess(res?.message);
      })
      .catch((err) => {
        setLoading(false);
        PagesIndex.toasterError(err?.response?.data?.message);
      });
  };
  const handleDeleteArchiveVideo = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", selectedId);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.HARD_DELETE_UPLOADED_VIDEOS,
      urlEncoded
    )
      .then((res) => {
        setLoading(false);
        handleGetVideos();
        handleCloseDelete();
        PagesIndex.toasterSuccess(res?.message);
      })
      .catch((err) => {
        setLoading(false);
        PagesIndex.toasterError(err?.response?.data?.message);
      });
  };
  const handleRestoreArchiveVideo = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", selectedId);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.RESTORE_ARCHIVED_VIDEOS,
      urlEncoded
    )
      .then((res) => {
        setLoading(false);
        handleGetVideos();
        handleCloseRestore();
        PagesIndex.toasterSuccess(res?.message);
      })
      .catch((err) => {
        setLoading(false);
        PagesIndex.toasterError(err?.response?.data?.message);
      });
  };
  const handleChangeTabs = (event, newValue) => {
    setCurrentPage(1);
    setValueTabs(newValue);
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Internal server video List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setSearchValue(e.target.value);
                    }}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {adminDetails?.roleId?.permissions?.includes(
              "upload_video_add"
            ) && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={handleOpenUploadVideo}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Upload Video</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
        <Index.Box className="filter-card common-card">
          <Index.Box className="filter-flex">
            <Index.Box className="grid-row">
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleGetVideos}
                initialValues={initialValuesSearch}
                innerRef={formRef}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} noValidate="novalidate">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>From Date</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <PagesIndex.LocalizationProvider
                                dateAdapter={PagesIndex.AdapterDayjs}
                                className="date-localization-main"
                              >
                                <PagesIndex.DatePicker
                                  open={openCalenderStart}
                                  onClose={() => setOpenCalenderStart(false)}
                                  className="admin-form-control admin-date-picker-control"
                                  value={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                    setFieldValue("endDate", null);
                                  }}
                                  slotProps={{
                                    popper: {
                                      className: "date-picker-body",
                                    },
                                    textField: {
                                      readOnly: true,
                                      onClick: () => setOpenCalenderStart(true),
                                    },
                                    inputAdornment: {
                                      onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                />
                              </PagesIndex.LocalizationProvider>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.startDate && touched.startDate
                                ? errors.startDate
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input admin-date-input-main">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>To Date</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <PagesIndex.LocalizationProvider
                                dateAdapter={PagesIndex.AdapterDayjs}
                                className="date-localization-main"
                              >
                                <PagesIndex.DatePicker
                                  open={openCalenderEnd}
                                  onClose={() => setOpenCalenderEnd(false)}
                                  className="admin-form-control admin-date-picker-control"
                                  value={values.endDate}
                                  minDate={dayjs(values.startDate).add(
                                    0,
                                    "day"
                                  )}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  slotProps={{
                                    popper: {
                                      className: "date-picker-body",
                                    },
                                    textField: {
                                      readOnly: true,
                                      onClick: () => setOpenCalenderEnd(true),
                                    },
                                    inputAdornment: {
                                      onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                />
                              </PagesIndex.LocalizationProvider>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.endDate && touched.endDate
                                ? errors.endDate
                                : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 3",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              <span>Tag</span>
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  className="admin-auto-complete-filed admin-dropdown-select admin-check-dropdown-select"
                                  options={tagData || []}
                                  name="tag"
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  disableClearable
                                  value={
                                    values?.tag &&
                                    tagData &&
                                    tagData?.find((o) => o._id === values?.tag)
                                      ? tagData?.find(
                                          (o) => o._id === values?.tag
                                        )
                                      : values?.tag
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("tag", value?._id);
                                  }}
                                  getOptionLabel={(option) => option?.tag}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.tag}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      placeholder={"Select Tag"}
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                            </Index.Box>
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.tag && touched.tag ? errors.tag : null}
                            </PagesIndex.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box advertiser-filter-input input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              {/* <span>Tag</span> */}
                            </Index.FormHelperText>
                          </Index.Box>
                          <Index.Box className="filter-btns upload-video-filter-btn">
                            <Index.Tooltip
                              title="Search"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  type="submit"
                                  disabled={
                                    !(
                                      formRef?.current?.values?.endDate ||
                                      formRef?.current?.values?.startDate ||
                                      formRef?.current?.values?.tag
                                    )
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    setFieldValue("isSubmit", true)
                                  }
                                >
                                  <img
                                    src={PagesIndex.Svg.searchwhite}
                                    className="admin-icons"
                                    alt="Save"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                            <Index.Tooltip
                              title="Reset"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  onClick={() => {
                                    formRef?.current?.resetForm();
                                    setCurrentPage(1);
                                    setTimeout(() => {
                                      handleGetVideos();
                                    }, 1000);
                                  }}
                                >
                                  <img
                                    src={PagesIndex.Svg.reset}
                                    className="admin-icons"
                                    alt="Reset"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        className="filter-row"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="filter-btns">
                            <Index.Tooltip
                              title="Search"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  type="submit"
                                  disabled={
                                    !(
                                      formRef?.current?.values?.endDate ||
                                      formRef?.current?.values?.startDate ||
                                      formRef?.current?.values?.tag
                                    )
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    setFieldValue("isSubmit", true)
                                  }
                                >
                                  <img
                                    src={PagesIndex.Svg.searchwhite}
                                    className="admin-icons"
                                    alt="Save"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                            <Index.Tooltip
                              title="Reset"
                              arrow
                              placement="top"
                              className="admin-tooltip"
                            >
                              <Index.Box className="btn-main-primary admin-icons-btn-main">
                                <Index.Button
                                  className="btn-primary admin-icons-btn"
                                  onClick={() => {
                                    formRef?.current?.resetForm();
                                    setCurrentPage(1);
                                    setTimeout(() => {
                                      handleGetVideos();
                                    }, 1000);
                                  }}
                                >
                                  <img
                                    src={PagesIndex.Svg.reset}
                                    className="admin-icons"
                                    alt="Reset"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.Tooltip>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-card">
          <Index.Box className="admin-tabs-main-box">
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={valueTabs}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab label="Uploaded" className="admin-tab" />
                {adminDetails?.roleId?.permissions?.includes(
                  "archive_video_view"
                ) && <Index.Tab label="Archived" className="admin-tab" />}
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={valueTabs} index={0} className="admin-tabpanel">
              <Index.Box className="admin-tabpanel-main">
                <Index.Box className="page-table-main">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="3%"
                          >
                            Title
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Description
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Category
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Tags
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Uploaded At
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="2%"
                            align="right"
                          >
                            Video Url
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="right"
                            width="2%"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {videoData?.paginatedResults?.length ? (
                          videoData?.paginatedResults?.map((data) => {
                            return (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                key={data?._id}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.title || "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.description}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.category?.length
                                      ? data?.category
                                          .map((value) => value?.categoryName)
                                          ?.join(", ")
                                      : "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.tags?.length
                                      ? data?.tags
                                          .map((value) => value?.tag)
                                          ?.join(", ")
                                      : "-"}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.uploadBy?.name}-
                                    {PagesIndex.moment(data?.uploadedAt).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Box className="admin-table-data-btn-flex">
                                    <Index.Tooltip
                                      title={`${data?.link}/api/images/${data?.video}`}
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                      onMouseLeave={() => {
                                        setTimeout(() => {
                                          setMessage("Copy");
                                        }, 1000);
                                      }}
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => {
                                          setMessage("Copied ✓");
                                          navigator.clipboard.writeText(
                                            `${data?.link}/api/images/${data?.video}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.copyicon}
                                          className="copy-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.Box>
                                  {/* <Index.Typography className="admin-table-data-text">
                                    {" "}
                                    {`${internalServerLink}/api/images/${data?.video}`}
                                  </Index.Typography> */}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Box className="admin-table-data-btn-flex">
                                    {(adminDetails?.roleId?.permissions?.includes(
                                      "upload_video_edit"
                                    ) ||
                                      adminDetails?.roleId?.permissions?.includes(
                                        "upload_video_delete"
                                      )) && (
                                      <Index.Tooltip
                                        title="Edit"
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                      >
                                        <Index.Button
                                          className="admin-table-data-btn"
                                          onClick={() => {
                                            setSelectedId(data?._id);
                                            setSelectedData(data);
                                            handleOpenUploadVideo();
                                          }}
                                        >
                                          <img
                                            src={PagesIndex.Svg.blueedit}
                                            className="admin-icon"
                                            alt="Edit"
                                          ></img>
                                        </Index.Button>
                                      </Index.Tooltip>
                                    )}
                                    {adminDetails?.roleId?.permissions?.includes(
                                      "upload_video_delete"
                                    ) && (
                                      <Index.Tooltip
                                        title="Delete"
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                      >
                                        <Index.Button
                                          className="admin-table-data-btn"
                                          onClick={() =>
                                            handleOpenDelete(data?._id)
                                          }
                                        >
                                          <img
                                            src={PagesIndex.Svg.trash}
                                            className="admin-icon"
                                            alt="Delete"
                                          ></img>
                                        </Index.Button>
                                      </Index.Tooltip>
                                    )}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <PagesIndex.DataNotFound />
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
                <Index.Box className="admin-pagination-main">
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(videoData?.totalCount / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={valueTabs} index={1} className="admin-tabpanel">
              <Index.Box className="admin-tabpanel-main">
                <Index.Box className="page-table-main">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="3%"
                          >
                            Title
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Description
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Category
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Tags
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                          >
                            Uploaded At
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="right"
                            width="2%"
                          >
                            Video Url
                          </Index.TableCell>
                          {/* <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="right"
                            width="2%"
                          >
                            Action
                          </Index.TableCell> */}
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {videoData?.paginatedResults?.length ? (
                          videoData?.paginatedResults?.map((data) => {
                            return (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                key={data?._id}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.title || "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.description || "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.category?.length
                                      ? data?.category
                                          .map((value) => value?.categoryName)
                                          ?.join(", ")
                                      : "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.tags?.length
                                      ? data?.tags
                                          .map((value) => value?.tag)
                                          ?.join(", ")
                                      : "-"}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Typography className="admin-table-data-text">
                                    {data?.uploadBy?.name}-
                                    {PagesIndex.moment(data?.uploadedAt).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Box className="admin-table-data-btn-flex">
                                    <Index.Tooltip
                                      title={`${data?.link}/api/images/${data?.video}`}
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                      onMouseLeave={() => {
                                        setTimeout(() => {
                                          setMessage("Copy");
                                        }, 1000);
                                      }}
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => {
                                          setMessage("Copied ✓");
                                          navigator.clipboard.writeText(
                                            `${data?.link}/api/images/${data?.video}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.copyicon}
                                          className="copy-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                    {adminDetails?.roleId?.permissions?.includes(
                                      "archive_video_delete"
                                    ) && (
                                      <Index.Tooltip
                                        title="Restore"
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                      >
                                        <Index.Button
                                          className="admin-table-data-btn"
                                          onClick={() =>
                                            handleOpenRestore(data?._id)
                                          }
                                        >
                                          <img
                                            src={PagesIndex.Svg.restore}
                                            className="admin-icon"
                                            alt="Delete"
                                          ></img>
                                        </Index.Button>
                                      </Index.Tooltip>
                                    )}
                                    {adminDetails?.roleId?.permissions?.includes(
                                      "archive_video_delete"
                                    ) && (
                                      <Index.Tooltip
                                        title="Delete"
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                      >
                                        <Index.Button
                                          className="admin-table-data-btn"
                                          onClick={() =>
                                            handleOpenDelete(data?._id)
                                          }
                                        >
                                          <img
                                            src={PagesIndex.Svg.trash}
                                            className="admin-icon"
                                            alt="Delete"
                                          ></img>
                                        </Index.Button>
                                      </Index.Tooltip>
                                    )}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <PagesIndex.DataNotFound />
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
                <Index.Box className="admin-pagination-main">
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(videoData?.totalCount / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openUploadVideo}
        onClose={handleCloseUploadVideo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          // enableReinitialize
          onSubmit={handleAddVideo}
          initialValues={initialValues}
          validationSchema={
            setSelectedId
              ? PagesIndex.editVideoSchema
              : PagesIndex.uploadVideoSchema
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {" "}
                    Upload Video
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseUploadVideo}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Title<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="title"
                        fullWidth
                        id="title"
                        className="admin-form-control"
                        placeholder="Enter Title"
                        value={values?.title}
                        onChange={handleChange}
                        inputProps={{ maxLength: 30 }}
                      />
                      {touched.title && errors.title && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.title}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Category
                    </Index.FormHelperText>
                    <Index.Box className="admin-input-box input-complete-box">
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          className="admin-auto-complete-control"
                          options={categoryData || []}
                          id="category"
                          multiple
                          disableCloseOnSelect
                          closeText={""}
                          openText={""}
                          clearText={""}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          value={
                            values?.category
                              ? categoryData.filter((tag) =>
                                  values?.category?.includes(tag._id)
                                )
                              : []
                          }
                          onChange={(e, values) => {
                            if (!e.key) {
                              setFieldValue(
                                "category",
                                values.map((data) => data._id)
                              );
                            }
                          }}
                          getOptionLabel={(option) => option?.categoryName}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.categoryName}
                            </li>
                          )}
                          filterOptions={(options, state) => {
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element?.categoryName
                                  .toLowerCase()
                                  .startsWith(state.inputValue.toLowerCase())
                              )
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              {...params}
                              size="small"
                              name="category"
                              placeholder={
                                values?.category?.length
                                  ? ""
                                  : "Select Category"
                              }
                              variant="outlined"
                            />
                          )}
                        />
                        {touched.category && errors.category && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.category}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box view-order-lable-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Tags
                    </Index.FormHelperText>
                    <Index.Box className="admin-input-box input-complete-box">
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          className="admin-auto-complete-control"
                          options={tagData || []}
                          id="tags"
                          multiple
                          disableCloseOnSelect
                          closeText={""}
                          openText={""}
                          clearText={""}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          value={
                            values?.tags
                              ? tagData.filter((tag) =>
                                  values?.tags?.includes(tag._id)
                                )
                              : []
                          }
                          onChange={(e, values) => {
                            if (!e.key) {
                              setFieldValue(
                                "tags",
                                values.map((tag) => tag._id)
                              );
                            }
                          }}
                          getOptionLabel={(option) => option?.tag}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.tag}
                            </li>
                          )}
                          filterOptions={(options, state) => {
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element?.tag
                                  .toLowerCase()
                                  .startsWith(state.inputValue.toLowerCase())
                              )
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              {...params}
                              size="small"
                              name="tags"
                              placeholder={
                                values?.tags?.length ? "" : "Select Tag"
                              }
                              variant="outlined"
                            />
                          )}
                        />
                        {touched.tags && errors.tags && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.tags}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Server Link
                    </Index.FormHelperText>
                    <Index.Box className="admin-input-box input-complete-box">
                      <Index.Box className="admin-form-group">
                        <Index.Autocomplete
                          className="admin-auto-complete-control"
                          options={serverLinkData || []}
                          id="serverLink"
                          closeText={""}
                          openText={""}
                          clearText={""}
                          getOptionSelected={(option, value) =>
                            option.name === value.name
                          }
                          value={
                            values?.serverLink
                              ? serverLinkData.find(
                                  (tag) => values?.serverLink === tag.name
                                )
                              : { _id: "", name: "" }
                          }
                          onChange={(e, values) => {
                            if (!e.key) {
                              setFieldValue("serverLink", values.name);
                            }
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.name}
                            </li>
                          )}
                          filterOptions={(options, state) => {
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element?.name
                                  .toLowerCase()
                                  .startsWith(state.inputValue.toLowerCase())
                              )
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              {...params}
                              size="small"
                              name="serverLink"
                              placeholder={
                                values?.serverLink?.length
                                  ? ""
                                  : "Select Server Link"
                              }
                              variant="outlined"
                            />
                          )}
                        />
                        {touched.serverLink && errors.serverLink && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.serverLink}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Description<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        // minRows={3}
                        placeholder="Enter description"
                        className="admin-form-control-textarea"
                        id="description"
                        name="description"
                        onChange={handleChange}
                        value={values?.description}
                        error={errors.description && touched.description}
                        maxLength={250}
                      />
                      {touched.description && errors.description && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.description}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Video<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Box className="file-upload-main thumbnail-box">
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="file-upload-button"
                        >
                          {renderImageVideo(values)}
                          <input
                            hidden
                            accept="video/mp4"
                            id="video"
                            name="video"
                            type="file"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("videoUrl", "");
                                setFieldValue("video", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                      </Index.Box>
                      {touched.video && errors.video && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.video}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-checkbox-main">
                    <BpCheckbox
                      checked={values?.checked}
                      onChange={(e) =>
                        setFieldValue("checked", !values?.checked)
                      }
                      name="checked"
                    />
                    <Index.Typography className="admin-checkbox-lable">
                      Delete after 30 days
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        type="submit"
                        className="admin-save-user-btn btn-primary"
                        disabled={loading}
                      >
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        ></img>
                        <span>
                          {loading ? <PagesIndex.Spinner /> : "Upload"}
                        </span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              {valueTabs === 0
                ? "Are you sure you want to Archive this video ? Video will move in Achieved section."
                : "Do you really want to delete this video? This process cannot be undone."}
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={() => {
                  if (valueTabs === 0) {
                    handleDeleteVideo();
                  }
                  if (valueTabs === 1) {
                    handleDeleteArchiveVideo();
                  }
                }}
                disabled={loading}
              >
                {loading ? <PagesIndex.Spinner /> : <span>Delete</span>}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <Index.Modal
        open={openRestore}
        onClose={handleCloseRestore}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            {/* <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box> */}
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to restore this video
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseRestore}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={() => {
                  handleRestoreArchiveVideo();
                }}
                disabled={loading}
              >
                {loading ? <PagesIndex.Spinner /> : <span>Restore</span>}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./elementManagement.Reponsive.css";

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function CreativeManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const videoRef = useRef();
  const [data, setData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [id, setId] = useState("");
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [selectedCreativeData, setSelectedCreativeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [elementsData, setElementsData] = useState([]);
  const [programTypeData, setProgramTypeData] = useState([]);
  const initialValues = {
    clientId: id ? selectedCreativeData?.clientId?._id : "",
    platform: id ? selectedCreativeData?.platformId?._id : "",
    elements: id ? selectedCreativeData?.elementId?._id : "",
    code: id
      ? selectedCreativeData?.code
      : `NC-${PagesIndex.moment().format("MMMYY").toUpperCase()}-${
          data?.[0]?.code?.split("-")?.[2] < 9
            ? "0" + (parseInt(data?.[0]?.code?.split("-")?.[2]) + 1)
            : parseInt(data?.[0]?.code?.split("-")?.[2]) + 1
        }`,
    name: id ? selectedCreativeData?.name : "",
    duration: id ? selectedCreativeData?.duration : "",
    imageVideo: "",
    imageUrl: id ? selectedCreativeData?.image : "",
  };
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [filterLog, setFilterLog] = useState([]);

  const [openLogModal, setOpenLogModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.creative_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        let data = res?.data?.activityLog?.filter(
          (data) =>
            data?.currentProgramTypeId?._id !==
              data?.previousProgramTypeId?._id ||
            data?.previousElement !== data?.currentElement ||
            data?.previousStatus !== data?.currentStatus
        );
        setFilterLog(data);
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    handleGetClients();
    handleGetCreatives();
    handleGetElements();
    handleGetProgramType();
  }, []);
  const handleGetClients = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res.status === 200) {
        const activeZone = res?.data?.filter(
          (item) => item?.isActive && !item?.isDraft
        );

        setClientData(activeZone);
      }
    });
  };
  const handleGetProgramType = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM_TYPE).then((res) => {
      if (res.status === 200) {
        const activeZone = res?.data?.filter((channel) => channel?.isActive);

        setProgramTypeData(activeZone);
      }
    });
  };
  const handleOpen = (op, id, data) => {
    setOpen(true);
    setAddOrEdit(op);
    setId(id);
    setSelectedCreativeData(data);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleGetElements = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ELEMENTS).then((res) => {
      if (res?.status === 200) {
        setElementsData(res?.data?.filter((item) => item?.isActive));
      }
    });
  };
  const handleGetCreatives = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CREATIVES).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };
  const handleGetCreativesNew = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CREATIVES).then((res) => {
      if (res.status === 200) {
        formRef?.current?.setFieldValue(
          "code",
          `NC-${PagesIndex.moment().format("MMMYY").toUpperCase()}-${
            res.data?.[0]?.code?.split("-")?.[2] < 9
              ? "0" + (parseInt(res.data?.[0]?.code?.split("-")?.[2]) + 1)
              : parseInt(res.data?.[0]?.code?.split("-")?.[2]) + 1
          }`
        );
      }
    });
  };
  const handleAddEditCreative = (values) => {
    setButtonSpinner(true);
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
    }
    formData.append("clientId", values?.clientId);
    formData.append("platformId", values?.platform);
    formData.append("elementId", values?.elements);
    formData.append("code", values?.code);
    formData.append("name", values?.name);
    if (values?.duration !== null) {
      formData.append("duration", values?.duration);
    }
    formData.append("image", values?.imageVideo);

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_CREATIVE, formData)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetCreatives();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteCreative = () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_CREATIVE, urlEncoded).then(
      (res) => {
        if (res.status === 200) {
          handleCloseDelete();
          handleGetCreatives();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let filteredData = data.filter(
      (data) =>
        data?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.clientId?.compName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.platformId?.typeName
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.code
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.updatedBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase()) ||
        data?.createdBy?.name
          ?.toLowerCase()
          .includes(e?.target?.value?.trim()?.toLowerCase())
    );
    setCurrentPage(1);
    setFilteredData(filteredData);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  // const handleStatus = () => {
  //   const urlEncoded = new URLSearchParams();
  //   urlEncoded.append("id", id);
  //   PagesIndex.apiPostHandler(
  //     PagesIndex.Api.ACTIVE_DEACTIVE_ELEMENT,
  //     urlEncoded
  //   ).then((res) => {
  //     if (res.status === 200) {
  //       handleCloseStatusModal();
  //       PagesIndex.toasterSuccess(res?.message);
  //       handleGetCreatives();
  //     } else {
  //       PagesIndex.toasterError(res?.message);
  //     }
  //   });
  // };

  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.name}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  <span className="news-id-text">{data?.code}</span>
                  {data?.name}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.platformId?.name}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.platformId?.typeName || "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.clientId?.compName}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.clientId?.compName}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.duration ? `${data?.duration} (Sec)` : "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${data?.createdBy?.name}-${PagesIndex.moment(
                  data?.createdAt
                ).format("DD/MM/YYYY hh:mm A")}`}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.createdBy?.name || "-"}
                  {"-"}
                  {PagesIndex.moment(data?.createdAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={`${data?.updatedBy?.name}-${PagesIndex.moment(
                  data?.updatedAt
                ).format("DD/MM/YYYY hh:mm A")}`}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.updatedBy?.name || "-"}-
                  {PagesIndex.moment(data?.updatedAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>

            {adminDetails?.roleId?.permissions?.includes("creative_edit") && (
              <Index.TableCell
                component="td"
                variant="td"
                align="right"
                className="table-td"
              >
                <Index.Box className="admin-table-data-btn-flex ">
                  {adminDetails?.roleId?.permissions?.includes(
                    "creative_edit"
                  ) && (
                    <Index.Tooltip
                      title="Edit"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          handleOpen("Edit", data?._id, data);
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.blueedit}
                          className="admin-icon"
                          alt="Edit"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                  {adminDetails?.roleId?.permissions?.includes(
                    "creative_delete"
                  ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
                </Index.Box>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data) => {
        let updatedInfo = "-";
        if (
          data?.currentProgramTypeId?._id !==
            data?.previousProgramTypeId?._id ||
          data?.currentElement !== data?.previousElement ||
          data?.previousStatus !== data?.currentStatus
        ) {
          if (data?.updatedBy?.name) {
            updatedInfo = `${data?.updatedBy?.name}-${
              data?.dateTime
                ? PagesIndex.moment(data?.dateTime).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "-"
            }`;
          }
        }
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            {updatedInfo !== "-" && (
              <>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={`${
                      data?.currentElement !== data?.previousElement
                        ? `${data?.updatedBy?.name} has updated element name from ${data?.previousElement} to ${data?.currentElement}`
                        : ""
                    }`}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.currentElement !== data?.previousElement && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated element name from{" "}
                          <span className="span-bold">
                            {data?.previousElement}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.currentElement + " "}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                  <Index.Tooltip
                    title={`${
                      data?.previousProgramTypeId?._id !==
                      data?.currentProgramTypeId?._id
                        ? `${data?.updatedBy?.name} has updated program type from ${data?.previousProgramTypeId?.typeName} to ${data?.currentProgramTypeId?.typeName}`
                        : ""
                    } `}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.currentProgramTypeId?._id !==
                        data?.previousProgramTypeId?._id && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated program type from{" "}
                          <span className="span-bold">
                            {data?.previousProgramTypeId?.typeName}
                          </span>{" "}
                          to{" "}
                          <span className="span-bold">
                            {data?.currentProgramTypeId?.typeName}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                  <Index.Tooltip
                    title={`${
                      data?.previousStatus !== data?.currentStatus
                        ? `${data?.updatedBy?.name} has updated status from ${
                            !data?.currentStatus ? "Active" : "Deactive"
                          } to ${data?.currentStatus ? "Active" : "Deactive"} `
                        : ""
                    }`}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {data?.previousStatus !== data?.currentStatus && (
                        <span>
                          <span className="span-bold">
                            {data?.updatedBy?.name}
                          </span>{" "}
                          has updated status from{" "}
                          <span className="span-bold">
                            {!data?.currentStatus ? "Active" : "Deactive"}
                          </span>{" "}
                          to&nbsp;
                          <span className="span-bold">
                            {data?.currentStatus ? "Active" : "Deactive"}
                          </span>
                        </span>
                      )}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell>

                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  {updatedInfo}
                </Index.TableCell>
              </>
            )}
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading &&
          (!activityLog ||
            activityLog.length === 0 ||
            filterLog.length === 0) && <PagesIndex.DataNotFound />}
      </>
    );
  };

  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  const renderImageVideo = (values) => {
    const isUploadImg = values?.imageUrl || values.imageVideo;
    const isImageBlob = values.imageVideo instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.imageVideo);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }
    if (values.imageVideo.type === "video/mp4" || imageUrl?.includes(".mp4")) {
      return (
        <video
          ref={videoRef}
          className="upload-img"
          loop={true}
          autoPlay="autoplay"
          muted
        >
          <source src={src} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <img
          className={isUploadImg ? "upload-img" : "upload-profile-img"}
          src={src}
          alt="category "
        />
      );
    }
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-list-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Creatives List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {adminDetails?.roleId?.permissions?.includes("creative_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      setId("");
                      handleOpen("Add");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Creative</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box
            className={`admin-distric-table-main ${
              loading ? "" : "page-table-main"
            }`}
          >
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Creative Name
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Platform
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="15%"
                      >
                        Client
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Duration
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Created By
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="20%"
                      >
                        Updated By
                      </Index.TableCell>

                      {adminDetails?.roleId?.permissions?.includes(
                        "creative_edit"
                      ) && (
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                          width="5%"
                          align="right"
                        >
                          Action
                        </Index.TableCell>
                      )}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {renderContent()}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddEditCreative}
          initialValues={initialValues}
          validationSchema={
            id ? PagesIndex.creativeEditSchema : PagesIndex.creativeAddSchema
          }
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Creative
                  </Index.Typography>
                </Index.Box>

                <Index.Box
                  component={"form"}
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Code<span className="astrick-sing">*</span>{" "}
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="code"
                        fullWidth
                        id="code"
                        className="admin-form-control"
                        placeholder="Enter Code"
                        onChange={handleChange}
                        value={values?.code}
                        disabled
                        inputProps={{ maxLength: 10 }}
                        // error={errors.code && touched.code}
                      />
                      {touched.code && errors.code && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.code}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                    <Index.Box className="border-btn-main">
                      <Index.Button
                        className="border-btn"
                        onClick={() => handleGetCreativesNew()}
                        disabled={buttonSpinner}
                      >
                        Refresh
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Client<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={clientData || []}
                        id="clientId"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.clientId
                            ? clientData?.find(
                                (o) => o._id === values?.clientId
                              )
                            : values?.clientId || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("clientId", value?._id);
                        }}
                        getOptionLabel={(option) => option?.compName}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.compName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="clientId"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Client"
                          />
                        )}
                      />
                      {touched.clientId && errors.clientId && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.clientId}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Platform<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={
                          programTypeData?.filter(
                            (data) =>
                              !data.typeName?.toLowerCase().includes("ott")
                          ) || []
                        }
                        name="platform"
                        id="platform"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.platform
                            ? programTypeData?.find(
                                (o) => o._id === values?.platform
                              )
                            : values?.platform || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("platform", value?._id);
                          setFieldValue("elements", "");
                        }}
                        getOptionLabel={(option) => option?.typeName}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.typeName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="platform"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Platform"
                          />
                        )}
                      />
                      {touched.platform && errors.platform && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.platform}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Element<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={
                          elementsData?.filter(
                            (data) =>
                              data?.programTypeId?._id === values?.platform
                          ) || []
                        }
                        name="elements"
                        id="elements"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.elements
                            ? elementsData?.find(
                                (o) => o._id === values?.elements
                              )
                            : values?.elements || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("elements", value?._id);
                        }}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="programType"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Element"
                          />
                        )}
                      />
                      {touched.elements && errors.elements && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.elements}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Creative Name<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="name"
                        fullWidth
                        id="name"
                        className="admin-form-control"
                        placeholder="Enter Creative Name"
                        onChange={handleChange}
                        value={values?.name}
                        inputProps={{ maxLength: 100 }}
                        // error={errors.name && touched.name}
                      />
                      {touched.name && errors.name && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.name}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  {values?.platform === "664360b5b539341a1a081253" ? (
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Duration<span className="astrick-sing">*</span>
                        <span className="option-condition-text">
                          (in seconds)
                        </span>
                        <span className="option-condition-text">
                          (Max Value: 600 Seconds)
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="duration"
                          fullWidth
                          id="duration"
                          className="admin-form-control"
                          placeholder="Enter Duration"
                          onChange={(e) => {
                            const regex = /^[0-9]*$/;
                            if (
                              regex.test(e.target.value) &&
                              e.target.value <= 600
                            ) {
                              handleChange(e);
                            }
                          }}
                          value={values?.duration}
                          // error={errors.duration && touched.duration}
                        />
                        {touched.duration && errors.duration && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.duration}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    <></>
                  )}
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Content
                      {/* <span className="astrick-sing">*</span> */}
                      <span className="option-condition-text">
                        (Image/Video)
                      </span>
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-main thumbnail-box">
                      {values?.imageVideo || values?.imageUrl ? (
                        <Index.Box className="file-upload-main thumbnail-box admin-file-browse-btn-main ">
                          <Index.Box className="upload-news-img-box">
                            {renderImageVideo(values)}
                          </Index.Box>
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="admin-file-browse-btn"
                          >
                            <img
                              src={PagesIndex.Svg.whiteedit}
                              className="admin-browse-icon-img"
                              alt="upload img"
                            ></img>
                            <input
                              hidden
                              accept="image/*, video/mp4"
                              id="imageVideo"
                              name="imageVideo"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  videoRef.current?.load();
                                  setFieldValue(
                                    "imageVideo",
                                    e.target.files[0]
                                  );
                                  setFieldValue("imageUrl", "");
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      ) : (
                        <Index.Box className="file-upload-main thumbnail-box">
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-button"
                          >
                            {renderImageVideo(values)}
                            <input
                              hidden
                              accept="image/*, video/mp4"
                              id="imageVideo"
                              name="imageVideo"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  videoRef.current?.load();
                                  setFieldValue(
                                    "imageVideo",
                                    e.target.files[0]
                                  );
                                  setFieldValue("imageUrl", "");
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      )}
                    </Index.Box>
                    {touched.imageVideo && errors.imageVideo && (
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.imageVideo}
                      </PagesIndex.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="border-btn-main">
                      <Index.Button
                        className="border-btn"
                        onClick={() => handleClose()}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className={`${
                          id ? "update-btn" : "save-btn"
                        } admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this creative? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="admin-modal-delete-btn btn"
                onClick={handleDeleteCreative}
              >
                Delete
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main distric-activity-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="60%"
                          >
                            Activity Log
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="30%"
                          >
                            Updated Date & Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* <PagesIndex.ChangeStatusModal
        data={"element"}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      /> */}
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import dayjs from "dayjs";

// for modal design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function SponsorManagement() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const navigate = PagesIndex.useNavigate();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [clientData, setClientData] = useState([]);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const initialValues = {
    name: id ? selectedData?.name?._id : "",
    email: id ? selectedData?.email : "",
    number: id ? selectedData?.mobile : "",
    categories: id ? selectedData?.category?.map((data) => data?._id) : [],
    district: id ? selectedData?.district?.map((data) => data?._id) : [],
    news: id ? selectedData?.news?.map((data) => data?._id) : [],
    startDate: id ? dayjs(selectedData?.startDate) : null,
    endDate: id ? dayjs(selectedData?.endDate) : null,
    sponsorLogo: "",
    sponsorLogoUrl: id ? selectedData?.logo : "",
    isEdit: !!id,
  };
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  // add user modal
  const [open, setOpen] = useState(false);
  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryData, setCategoryData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [openLogModal, setOpenLogModal] = useState(false);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ACTIVITY_LOG + "/" + id).then(
      (res) => {
        setActivityLoading(false);
        if (res.status === 200) {
          setActivityLog(res.data?.activityLog);
        }
      }
    );
  };
  useEffect(() => {
    setLoading(true);
    handleGetSponsors();
    handleGetCategories();
    handleGetDistrict();
    handleGetDistrict();
    handleGetNews();
    handleGetClients();
  }, []);
  const handleGetClients = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res.status === 200) {
        const activeZone = res?.data?.filter(
          (item) => item?.isActive && !item?.isDraft
        );

        setClientData(activeZone);
      }
    });
  };
  const handleOpen = (op, data) => {
    setOpen(true);
    if (data) {
      setSelectedData(data);
    }
    setAddOrEdit(op);
  };
  const handleClose = () => {
    formRef.current.resetForm();
    setId("");
    setSelectedData({});
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };
  const handleGetNews = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_NEWS).then((res) => {
      if (res.status === 200) {
        setNewsData(
          res.data?.filter(
            (item) =>
              item?.isPublish &&
              item?.isActive &&
              !item.sectionToShow?.includes("660c00dd0b2c9ea28bd1df33")
          )
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetCategories = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res?.status === 200) {
        setCategoryData(res?.data?.filter((data) => data?.isActive));
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetDistrict = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_DISTRICTMASTER).then((res) => {
      if (res?.status === 200) {
        setDistrictData(res?.data?.filter((data) => data?.isActive));
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetSponsors = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SPONSOR).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res.data);
        setFilteredData(res.data);
      }
    });
  };
  const handleAddEditSponsor = (values) => {
    setButtonSpinner(true);
    const urlEncoded = new FormData();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("name", values?.name);
    urlEncoded.append("email", values?.email);
    urlEncoded.append("mobile", values?.number);
    urlEncoded.append("category", JSON.stringify(values?.categories));
    urlEncoded.append("district", JSON.stringify(values?.district));
    urlEncoded.append("news", JSON.stringify(values?.news));
    urlEncoded.append(
      "startDate",
      PagesIndex.moment(new Date(values?.startDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append(
      "endDate",
      PagesIndex.moment(new Date(values?.endDate)).format("YYYY-MM-DD")
    );
    if (values?.sponsorLogo) {
      urlEncoded.append("profile", values?.sponsorLogo);
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_SPONSOR, urlEncoded)
      .then((res) => {
        setButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          handleClose();
          handleGetSponsors();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const renderImage = (values) => {
    const isUploadImg = values?.sponsorLogoUrl || values.sponsorLogo;
    const isImageBlob = values.sponsorLogo instanceof Blob;
    const imageUrl = values?.sponsorLogoUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.sponsorLogoUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.sponsorLogo);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        className={isUploadImg ? "upload-img" : "upload-profile-img"}
        src={src}
        alt="zone"
      />
    );
  };
  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let result = data.filter((data) => {
      let name = data?.name?.compName
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());
      let email = data?.email
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());
      let mobile = data?.mobile
        ?.toLowerCase()
        ?.includes(e?.target?.value?.trim()?.toLowerCase());

      return email || name || mobile;
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  const handleDeleteSponsor = () => {
    setDeleteButtonSpinner(true);
    PagesIndex.apiGetHandler(PagesIndex.Api.DELETE_SPONSOR + "/" + id).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetSponsors();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.ACTION_SPONSOR, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false);
        if (res.status === 200) {
          handleGetSponsors();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const renderTableRows = (setFieldValue) => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                <Index.Tooltip
                  title={data?.name?.compName}
                  arrow
                  placement="top"
                  className="admin-tooltip"
                >
                  {data?.name?.compName}
                </Index.Tooltip>
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.email}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                {data?.email}
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.mobile}
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.createdBy?.name || "-"}
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.updatedBy?.name || "-"}
            </Index.TableCell>

            {(adminDetails?.roleId?.permissions?.includes("sponsor_edit") ||
              adminDetails?.roleId?.permissions?.includes(
                "sponsor_delete"
              )) && (
              <Index.TableCell
                component="td"
                variant="td"
                className="table-td"
                align="right"
              >
                <Index.Tooltip
                  title={data?.isActive ? "Active" : "Deactive"}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => {
                        handleOpenStatusModal(data?._id);
                      }}
                    />
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>
            )}

            <Index.TableCell
              component="td"
              variant="td"
              align="right"
              className="table-td"
            >
              <Index.Box className="admin-table-data-btn-flex ">
                {adminDetails?.roleId?.permissions?.includes(
                  "sponsor_edit"
                ) && (
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => {
                        setId(data?._id);
                        handleOpen("Edit", data);
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.blueedit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
                {adminDetails?.roleId?.permissions?.includes("sponsor_log") && (
                  <Index.Tooltip
                    title="Log"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenLogModal(data?._id)}
                    >
                      <img
                        src={PagesIndex.Svg.logicon}
                        className="admin-icon"
                        alt="Log"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}

                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => navigate(`/admin/view-sponsor/${data?._id}`)}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>

                {adminDetails?.roleId?.permissions?.includes(
                  "sponsor_delete"
                ) && (
                  <Index.Tooltip
                    title="Delete"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenDelete(data?._id)}
                    >
                      <img
                        src={PagesIndex.Svg.trash}
                        className="admin-icon"
                        alt="Trash"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderContent = (setFieldValue) => {
    if (filteredData?.length) {
      return renderTableRows(setFieldValue);
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data, index) => {
        let updatedInfo = "";
        if (data?.updatedBy?.name) {
          updatedInfo = `${data?.updatedBy?.name}-${
            data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                  "DD/MM/YYYY hh:mm:ss A"
                )
              : "-"
          }`;
        } else {
          updatedInfo = "-";
        }
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {data?.actionType === "Updated"
                  ? "Sponsor updated"
                  : data?.actionType}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              {updatedInfo}
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title="View"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() =>
                    navigate(`/admin/sponsor/sponsor-activity-log`, {
                      state: {
                        log: activityLog,
                        index: index,
                      },
                    })
                  }
                >
                  <img
                    src={PagesIndex.Svg.yelloweye}
                    className="admin-icon"
                    alt="View"
                  ></img>
                </Index.Button>
              </Index.Tooltip>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading && (!activityLog || activityLog.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEditSponsor}
      initialValues={initialValues}
      validationSchema={PagesIndex.sponsorAddSchema}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <Index.Box className="admin-dashboard-content admin-user-list-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
              <Index.Typography
                className="admin-page-title admin-user-list-page-title"
                component="h2"
                variant="h2"
              >
                Sponsor List
              </Index.Typography>
              <Index.Box className="admin-userlist-btn-flex">
                <Index.Box className="admin-search-main">
                  <Index.Box className="admin-search-box">
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Search"
                        value={searchValue}
                        onChange={requestSearch}
                      />
                      <img
                        src={PagesIndex.Svg.search}
                        className="admin-search-grey-img admin-icon"
                        alt="search"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {adminDetails?.roleId?.permissions?.includes("sponsor_add") && (
                  <Index.Box className="admin-userlist-inner-btn-flex">
                    <Index.Box className="admin-adduser-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-adduser-btn btn-primary"
                        onClick={() => {
                          setFieldValue("isEdit", false);
                          handleOpen("Add");
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="admin-plus-icon"
                          alt="plus"
                        />
                        <span>Add Sponsor</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="common-card">
              <Index.Box
                className={`page-table-main ${
                  loading ? "" : "sponser-table-main"
                }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {loading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Sponsor Name
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Email Id
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Mobile Number
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Created By
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                          >
                            Updated By
                          </Index.TableCell>

                          {(adminDetails?.roleId?.permissions?.includes(
                            "sponsor_edit"
                          ) ||
                            adminDetails?.roleId?.permissions?.includes(
                              "sponsor_delete"
                            )) && (
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              width="5%"
                              align="right"
                            >
                              Status
                            </Index.TableCell>
                          )}

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="5%"
                            align="right"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderContent(setFieldValue)}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="admin-pagination-main">
                {filteredData?.length ? (
                  <Index.Pagination
                    page={currentPage}
                    count={Math.ceil(filteredData?.length / 10)}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    variant="outlined"
                    shape="rounded"
                    className="admin-pagination"
                  />
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form
              onSubmit={handleSubmit}
              noValidate="novalidate"
              id="scrollDiv"
            >
              {/* <ScrollIntoView /> */}
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Sponsor
                  </Index.Typography>
                  {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Box> */}
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Client<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.Autocomplete
                        className="admin-auto-complete-control"
                        options={clientData || []}
                        id="name"
                        closeText={""}
                        openText={""}
                        clearText={""}
                        value={
                          values?.name
                            ? clientData?.find((o) => o._id === values?.name)
                            : values?.name || null
                        }
                        onChange={(e, value) => {
                          setFieldValue("name", value?._id);
                        }}
                        getOptionLabel={(option) => option?.compName}
                        renderOption={(props, item) => (
                          <li {...props} key={item?._id}>
                            {item?.compName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Index.TextField
                            fullWidth
                            className="admin-form-control"
                            name="name"
                            {...params}
                            size="small"
                            variant="outlined"
                            placeholder="Select Client"
                          />
                        )}
                      />
                      {touched.name && errors.name && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.name}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Email Id<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="email"
                        fullWidth
                        id="email"
                        className="admin-form-control"
                        placeholder="Enter Email Id"
                        onChange={handleChange}
                        value={values?.email}
                        error={errors.email && touched.email}
                        inputProps={{ maxLength: 50 }}
                      />
                      <PagesIndex.FormHelperText className="admin-error-text">
                        {errors.email && touched.email ? errors.email : null}
                      </PagesIndex.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Mobile Number<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="number"
                        fullWidth
                        id="number"
                        className="admin-form-control"
                        placeholder="Enter Mobile Number"
                        onChange={(e) => {
                          const regex = /^[0-9]*$/;
                          if (regex.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        value={values?.number}
                        inputProps={{ maxLength: 10 }}
                        error={errors.number && touched.number}
                      />
                      {touched.number && errors.number && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.number}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Categories
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                        <Index.Autocomplete
                          className="admin-auto-complete-filed"
                          options={categoryData || []}
                          id="categories"
                          multiple
                          disableCloseOnSelect
                          closeText={""}
                          openText={""}
                          clearText={""}
                          value={
                            values?.categories
                              ? categoryData.filter((data) =>
                                  values?.categories?.some(
                                    (cat) => cat === data?._id
                                  )
                                )
                              : []
                          }
                          onChange={(e, selectedOptions) => {
                            setFieldValue(
                              "categories",
                              selectedOptions.map((option) => option._id)
                            );
                          }}
                          getOptionLabel={(option) => option?.categoryName}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.categoryName}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              name="categories"
                              placeholder={
                                values?.categories?.length
                                  ? ""
                                  : "Select Category"
                              }
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />

                        {touched.categories && errors.categories && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.categories}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                    <Index.FormHelperText className="admin-form-lable">
                      District
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                        <Index.Autocomplete
                          className="admin-auto-complete-filed"
                          options={(districtData || []).sort((a, b) =>
                            a?.name?.localeCompare(b?.name)
                          )}
                          id="district"
                          multiple
                          disableCloseOnSelect
                          closeText={""}
                          openText={""}
                          clearText={""}
                          value={
                            values?.district
                              ? districtData.filter((data) =>
                                  values?.district?.some(
                                    (cat) => cat === data?._id
                                  )
                                )
                              : []
                          }
                          onChange={(e, selectedOptions) => {
                            setFieldValue(
                              "district",
                              selectedOptions.map((option) => option._id)
                            );
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              name="district"
                              placeholder={
                                values?.district?.length
                                  ? ""
                                  : "Select District"
                              }
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />

                        {touched.district && errors.district && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.district}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Video
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                        <Index.Autocomplete
                          className="admin-auto-complete-filed"
                          options={newsData || []}
                          id="news"
                          multiple
                          disableCloseOnSelect
                          closeText={""}
                          openText={""}
                          clearText={""}
                          value={
                            values?.news
                              ? newsData.filter((data) =>
                                  values?.news?.some((cat) => cat === data?._id)
                                )
                              : []
                          }
                          onChange={(e, selectedOptions) => {
                            setFieldValue(
                              "news",
                              selectedOptions.map((option) => option._id)
                            );
                          }}
                          getOptionLabel={(option) =>
                            `${option?.newsNo || "NC"}-${option?.newsTitle}`
                          }
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.newsNo || "NC"}-{item?.newsTitle}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              name="news"
                              placeholder={
                                values?.news?.length ? "" : "Select Video"
                              }
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />

                        {touched.news && errors.news && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.news}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                    <Index.FormHelperText className="admin-form-lable">
                      Start Date<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group" id="startDate">
                      <PagesIndex.LocalizationProvider
                        dateAdapter={PagesIndex.AdapterDayjs}
                        className="date-localization-main"
                      >
                        <PagesIndex.DatePicker
                          open={openCalenderStart}
                          onClose={() => setOpenCalenderStart(false)}
                          className="admin-form-control admin-date-picker-control"
                          value={values.startDate}
                          minDate={dayjs().add(0, "day")}
                          onChange={(date) => {
                            setFieldTouched("startDate", false);
                            setFieldValue("startDate", date);
                            setFieldValue("endDate", null);
                          }}
                          slotProps={{
                            popper: {
                              className: "date-picker-body",
                            },
                            textField: {
                              readOnly: true,
                              onClick: () => setOpenCalenderStart(true),
                            },
                            inputAdornment: {
                              onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                            },
                          }}
                          format="DD/MM/YYYY"
                        />
                      </PagesIndex.LocalizationProvider>
                    </Index.Box>
                    <PagesIndex.FormHelperText className="admin-error-text">
                      {errors.startDate && touched.startDate
                        ? errors.startDate
                        : null}
                    </PagesIndex.FormHelperText>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                    <Index.FormHelperText className="admin-form-lable">
                      End Date<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group" id="endDate">
                      <PagesIndex.LocalizationProvider
                        dateAdapter={PagesIndex.AdapterDayjs}
                        className="date-localization-main"
                      >
                        <PagesIndex.DatePicker
                          open={openCalenderEnd}
                          onClose={() => setOpenCalenderEnd(false)}
                          className="admin-form-control admin-date-picker-control"
                          value={values.endDate}
                          minDate={
                            values.startDate
                              ? dayjs(values.startDate).add(0, "day")
                              : dayjs().add(0, "day")
                          }
                          onChange={(date) => {
                            setFieldValue("endDate", date);
                          }}
                          slotProps={{
                            popper: {
                              className: "date-picker-body",
                            },
                            textField: {
                              readOnly: true,
                              onClick: () => setOpenCalenderEnd(true),
                            },
                            inputAdornment: {
                              onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                            },
                          }}
                          format="DD/MM/YYYY"
                        />
                      </PagesIndex.LocalizationProvider>
                    </Index.Box>
                    <PagesIndex.FormHelperText className="admin-error-text">
                      {errors.endDate && touched.endDate
                        ? errors.endDate
                        : null}
                    </PagesIndex.FormHelperText>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-add-webseries-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Sponsor Logo<span className="astrick-sing">*</span>
                      <span className="option-condition-text">
                        (Image must be 100px(Width) * 50px(Height))
                      </span>
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-main thumbnail-box">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-button"
                      >
                        {renderImage(values)}
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          id="sponsorLogo"
                          name="sponsorLogo"
                          onChange={(e) => {
                            if (e.target.files?.length !== 0) {
                              setFieldValue("sponsorLogo", e.target.files[0]);
                            }
                          }}
                        />
                      </Index.Button>
                      {touched.sponsorLogo && errors.sponsorLogo && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.sponsorLogo}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleClose}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${
                          id ? "update-btn" : "save-btn"
                        } admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          <>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>

          <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-delete-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="admin-modal-circle-main">
                  <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                  />
                </Index.Box>
                <Index.Typography
                  className="admin-delete-modal-title"
                  component="h2"
                  variant="h2"
                >
                  Are you sure?
                </Index.Typography>
                <Index.Typography
                  className="admin-delete-modal-para admin-common-para"
                  component="p"
                  variant="p"
                >
                  Do you really want to delete this sponsor? This process cannot
                  be undone.
                </Index.Typography>
                <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                  <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseDelete}
                    disabled={deleteButtonSpinner}
                  >
                    Cancel
                  </Index.Button>
                  <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleDeleteSponsor}
                    disabled={deleteButtonSpinner}
                  >
                    {deleteButtonSpinner ? (
                      <PagesIndex.Spinner />
                    ) : (
                      <span>Delete</span>
                    )}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openLogModal}
            onClose={handleCloseLogModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <Index.Box
              sx={style}
              className="admin-log-modal-inner-main admin-modal-inner"
            >
              <Index.Box className="admin-modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="admin-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {" "}
                  Activity Log
                </Index.Typography>
                <Index.Button
                  className="modal-close-btn"
                  onClick={handleCloseLogModal}
                >
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                  />
                </Index.Button>
              </Index.Box>
              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                <Index.Box className="common-card">
                  <Index.Box className="page-table-main sponser-activity-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      {activityLoading ? (
                        <PagesIndex.Loader />
                      ) : (
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="50%"
                              >
                                Activity Log
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="40%"
                              >
                                Updated Date & Time
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                width="10%"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {renderActivityLogContent()}
                          </Index.TableBody>
                        </Index.Table>
                      )}
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.ChangeStatusModal
            data={"sponsor"}
            statusButtonSpinner={statusButtonSpinner}
            openStatusModal={openStatusModal}
            handleCloseStatusModal={handleCloseStatusModal}
            handleChangeStatus={handleStatus}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
}

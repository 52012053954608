import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation, useParams } from "react-router-dom";
import { FieldArray, getIn } from "formik";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function AddSalesOrder() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRefOrder = useRef();
  const formRefMain = useRef();
  const formRefDoc = useRef();
  const params = useParams();
  const location = useLocation();
  const { id } = params;
  const orderList = location?.state?.orderList;
  const navigate = PagesIndex.useNavigate();
  const [data, setData] = useState(id ? orderList?.orderDetails : []);
  const [docData, setDocData] = useState(id ? orderList?.document : []);
  const [deletedDoc, setDeletedDoc] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [draftButtonSpinner, setDraftButtonSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [programData, setProgramData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [editId, setEditId] = useState(null);
  const [openUploadDocument, setOpenUploadDocument] = useState(false);

  const [advertisorData, setAdvertisorData] = useState([]);
  const [elements, setElements] = useState([]);
  const [adminUser, setAdminUser] = useState([]);
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const [openCalenderStartModal, setOpenCalenderStartModal] = useState(false);
  const [openCalenderEndModal, setOpenCalenderEndModal] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [slotData, setSlotData] = useState([]);
  const [openCalender, setOpenCalender] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [creativeList, setCreativeList] = useState([]);
  let deletedImage = deletedDoc?.filter((item) => item !== "");
  const totalAmount = data?.reduce((total, item) => {
    return total + parseFloat(item?.amount || 0, 10);
  }, 0);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setSlotData([]);
    formRefOrder?.current?.resetForm();
    setDefaultData({});
    setEditId(null);
    setOpen(false);
  };

  const handleOpenUploadDocument = () => {
    setOpenUploadDocument(true);
  };
  const handleCloseUploadDocument = () => {
    formRefDoc?.current?.resetForm();
    setOpenUploadDocument(false);
  };

  const renderImage = (values) => {
    const isUploadImg = values.image;
    const isImageBlob = values.image instanceof Blob;
    let src;
    let image = values.image.type?.includes("image");
    let video = values.image.type?.includes("video");
    let xmlimage = values.image.type?.includes("xml");
    if (image == false && video == false) {
      src = PagesIndex.Svg.pdfIcon;
    } else if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else {
      src = PagesIndex.Svg.fileupload;
    }
    return (
      <img
        className={
          image && !xmlimage && isUploadImg
            ? "upload-img"
            : "upload-profile-img"
        }
        src={src}
        alt="document"
      />
    );
  };
  const initialValues = {
    platform: defaultData?.platform ? defaultData?.platform : "",
    elements: defaultData?.elements ? defaultData?.elements : "",
    // elementId: defaultData?.elementId ? defaultData?.elementId : "",
    totalSeconds: defaultData?.totalSeconds ? defaultData?.totalSeconds : 0,
    rate: defaultData?.rate ? defaultData?.rate : "",
    discountrate: defaultData?.discountrate ? defaultData?.discountrate : 0,
    remarks: defaultData?.remarks ? defaultData?.remarks : "",
    remainingUnits: defaultData?.remainingUnits
      ? defaultData?.remainingUnits
      : "",
    unit: defaultData?.unit ? defaultData?.unit : "",
    amount: defaultData?.amount ? parseFloat(defaultData?.amount) : 0,
    program: defaultData?.program ? defaultData?.program : [],
    type: defaultData?.type ? defaultData?.type : "dateRange",
    schedule: defaultData?.schedule ? defaultData?.schedule : "",
    // creative: defaultData?.creative ? defaultData?.creative : "",
    startDate: defaultData?.startDate
      ? PagesIndex.dayjs(defaultData?.startDate)
      : startDate,
    endDate: defaultData?.endDate
      ? PagesIndex.dayjs(defaultData?.endDate)
      : endDate,
    slots: defaultData?.slots ? defaultData?.slots : [],
    selectedDates:
      defaultData?.selectedDates?.length && defaultData?.type === "date"
        ? defaultData?.selectedDates
        : [
            {
              date: [],
              slots: [],
            },
          ],
    saveType: "",
  };

  const clientInitialValues = {
    client: id ? orderList?.client?._id : "",
    startDate:
      id && orderList?.startDate
        ? PagesIndex.dayjs(orderList?.startDate)
        : null,
    endDate:
      id && orderList?.endDate ? PagesIndex.dayjs(orderList?.endDate) : null,
    // approvelPerson: id ? orderList?.approvalId?._id : "",
    campaignName: id ? orderList?.campaignName : "",
  };

  const initialValuesDocumentUpload = {
    name: "",
    image: "",
  };
  useEffect(() => {
    handleGetAdvertiserList();
    handleGetSubAdmins();
    handleGetProgramType();
    handleGetElements();
    handleGetProgram();
  }, []);
  const handleGetSchedule = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SCHEDULES).then((res) => {
      if (res.status === 200) {
        const activeSlots = res?.data?.filter((slot) => slot?.isActive);
        setScheduleData(activeSlots);
      }
    });
  }, []);
  // const handleGetCreative = useCallback(() => {
  //   PagesIndex.apiGetHandler(PagesIndex.Api.GET_CREATIVES).then((res) => {
  //     if (res?.status === 200) {
  //       setCreativeList(res?.data?.filter((item) => item?.isActive));
  //     }
  //   });
  // }, []);
  useEffect(() => {
    handleGetSchedule();
    // handleGetCreative();
  }, [handleGetSchedule]);

  const handleGetAdvertiserList = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ADVERTISER).then((res) => {
      if (res?.status === 200) {
        setAdvertisorData(
          res?.data?.filter((item) => item?.isActive && !item?.isDraft)
        );
      }
    });
  };
  const handleGetElements = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ELEMENTS).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        let filter = res?.data?.filter((item) => item?.isActive);
        const sortedElements = filter?.sort((a, b) =>
          a?.name?.localeCompare(b?.name)
        );
        setElements(sortedElements);
      }
    });
  };
  const handleDownloadVideo = async (data) => {
    const a = document.createElement("a");
    if (data?.file?.name) {
      const file = URL.createObjectURL(data?.file);
      a.setAttribute("download", data?.file?.name); // Replace with the desired filename for the downloaded file
      a.href = file;
      a.click();
      window.URL.revokeObjectURL(file);
    } else {
      a.setAttribute("download", data?.documentsName); // Replace with the desired filename for the downloaded file
      a.href = process.env.REACT_APP_IMAGE_ENDPOINT + data?.file;
      a.click();
      window.URL.revokeObjectURL(
        process.env.REACT_APP_IMAGE_ENDPOINT + data?.file
      );
    }
  };
  const handleGetProgramType = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM_TYPE).then((res) => {
      if (res.status === 200) {
        const programType = res?.data?.filter(
          (programType) => programType?.isActive
        );
        setPlatformData(programType);
      }
    });
  };
  const handleGetProgram = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PROGRAM).then((res) => {
      if (res.status === 200) {
        const program = res?.data?.filter((program) => program?.isActive);
        setProgramData(program);
      }
    });
  };
  const handleGetSubAdmins = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SUBADMIN).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setAdminUser(res.data);
      }
    });
  };

  const handleAddModalData = (values) => {
    if (editId !== null) {
      // Edit existing item
      const updatedData = data?.map((item, index) =>
        index === editId ? values : item
      );
      setData(updatedData);
    } else {
      // Add new item
      let obj = values;
      if (values?.type === "dateRange") {
        delete obj.selectedDates;
      }
      if (values?.type === "date") {
        delete obj.slots;
        delete obj.startDate;
        delete obj.endDate;
      }
      setData((prev) => [...prev, obj]);
    }
    handleClose();
    setEditId(null);
  };

  const handleAddDocument = (values) => {
    // Add new item
    let obj = {
      name: values?.name,
      file: values?.image,
    };
    setDocData((prev) => [...prev, obj]);
    handleCloseUploadDocument();
  };

  const handleAddData = (values) => {
    if (data?.length <= 0) {
      handleOpen();
    } else {
      if (values?.type === "add") {
        setButtonSpinner(true);
      } else {
        setDraftButtonSpinner(true);
      }

      const formData = new FormData();
      if (id) {
        formData.append("id", id);
      }
      formData.append("client", values?.client);
      formData.append("isDraft", values?.type === "add" ? false : true);
      formData.append("campaignName", values?.campaignName);
      formData.append("startDate", values?.startDate);
      formData.append("endDate", values?.endDate);
      formData.append("totalAmount", totalAmount);
      formData.append("orderDetails", JSON.stringify(data));
      formData.append("deletedImage", JSON.stringify(deletedImage));
      docData?.forEach((data, index) => {
        formData.append(data?.name, data?.file);
      });

      PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_SALES_ORDER, formData)
        .then((res) => {
          setDraftButtonSpinner(false);
          setButtonSpinner(false);
          if (res.status === 201 || res.status === 200) {
            navigate("/admin/sales-order-list");
            PagesIndex.toasterSuccess(res?.message);
            handleCloseUploadDocument();
          } else {
            PagesIndex.toasterError(res?.message);
          }
        })
        .catch((err) => {
          PagesIndex.toasterError(err?.message);
        });
    }
  };

  const handleImageDelete = (url, id) => {
    const updatedData = docData?.filter((item, index) => index !== id);
    setDeletedDoc((pre) => [...pre, url]);
    setDocData(updatedData);
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {id ? "Edit" : "Add"} Deal
        </Index.Typography>
      </Index.Box>

      <>
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleAddData}
          initialValues={clientInitialValues}
          validationSchema={PagesIndex.salesOrder}
          innerRef={formRefMain}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box className="common-card add-sales-card">
                <Index.Box className="">
                  <Index.Box className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Client<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.Autocomplete
                              className="admin-auto-complete-control"
                              options={advertisorData || []}
                              id="client"
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={
                                values?.client
                                  ? advertisorData?.find(
                                      (o) => o?._id === values?.client
                                    ) ?? null
                                  : null
                              }
                              onChange={(e, value) => {
                                setFieldValue("client", value?._id);
                              }}
                              getOptionLabel={(option) =>
                                `${option?.firstName}-${option?.compName}`
                              }
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.firstName}-{item?.compName}
                                </li>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  name="client"
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  placeholder="Select Client"
                                />
                              )}
                            />
                            {touched.client && errors.client && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.client}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-event-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Deal Name<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              name="campaignName"
                              fullWidth
                              id="campaignName"
                              className="admin-form-control"
                              placeholder="Enter Deal Name"
                              onChange={handleChange}
                              value={values?.campaignName}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Index.Box>
                          {errors.campaignName && touched.campaignName && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.campaignName}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-date-input-main ">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>Start Date</span>
                            <span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.DatePicker
                                open={openCalenderStart}
                                onClose={() => setOpenCalenderStart(false)}
                                className="admin-form-control admin-date-picker-control"
                                value={values.startDate} // Use '01/01/2000' as the default value
                                onChange={(date) => {
                                  setFieldTouched("startDate", false);
                                  setFieldValue("startDate", date);
                                  setFieldValue("endDate", null);
                                  setStartDate(date);
                                  setEndDate(null);
                                }}
                                minDate={PagesIndex.dayjs()}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    readOnly: true,
                                    onClick: () => setOpenCalenderStart(true),
                                  },
                                  inputAdornment: {
                                    onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                                  },
                                }}
                                format="DD/MM/YYYY"
                              />
                            </PagesIndex.LocalizationProvider>
                            <Index.FormHelperText className="admin-error-text">
                              {errors.startDate &&
                                touched.startDate &&
                                errors.startDate}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-date-input-main ">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>End Date</span>
                            <span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.DatePicker
                                open={openCalenderEnd}
                                onClose={() => setOpenCalenderEnd(false)}
                                className="admin-form-control admin-date-picker-control"
                                value={values.endDate} // Use '01/01/2000' as the default value
                                onChange={(date) => {
                                  setFieldValue("endDate", date);
                                  setEndDate(date);
                                }}
                                minDate={
                                  values.startDate
                                    ? PagesIndex.dayjs(values.startDate).add(
                                        0,
                                        "day"
                                      )
                                    : PagesIndex.dayjs().add(0, "day")
                                }
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    readOnly: true,
                                    onClick: () => setOpenCalenderEnd(true),
                                  },
                                  inputAdornment: {
                                    onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                                  },
                                }}
                                format="DD/MM/YYYY"
                              />
                            </PagesIndex.LocalizationProvider>
                            <Index.FormHelperText className="admin-error-text">
                              {errors.endDate &&
                                touched.endDate &&
                                errors.endDate}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="other-flex">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h2"
                        variant="h2"
                      >
                        Order Details
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className="btn-primary"
                        onClick={() => {
                          handleOpen();
                        }}
                        disabled={!(values.startDate && values.endDate)}
                      >
                        <img
                          src={PagesIndex.Svg.plus}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>Add Order</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="common-card">
                    <Index.Box
                      className={`admin-client-table-main page-table-main`}
                    >
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        {loading ? (
                          <PagesIndex.Loader />
                        ) : (
                          <Index.Table
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="2%"
                                >
                                  Sr. No.
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Platform
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Element
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Total Units
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Rate
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Selling Rate
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                >
                                  Amount
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  width="5%"
                                  align="right"
                                >
                                  Action
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {loading ? (
                                <PagesIndex.Loader />
                              ) : (
                                <TableData
                                  data={data}
                                  currentPage={1}
                                  setData={setData}
                                  setDefaultData={setDefaultData}
                                  handleOpen={handleOpen}
                                  setEditId={setEditId}
                                  totalAmount={totalAmount}
                                  scheduleData={scheduleData}
                                  setSlotData={setSlotData}
                                />
                              )}

                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                ></Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                ></Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                ></Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                ></Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                ></Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="right"
                                >
                                  <Index.Box className="total-text">
                                    Total Amount:
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Box className="total-text">
                                    {" "}
                                    {parseFloat(totalAmount)?.toLocaleString(
                                      "en-US"
                                    )}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                ></Index.TableCell>
                              </Index.TableRow>
                            </Index.TableBody>
                          </Index.Table>
                        )}
                      </Index.TableContainer>
                    </Index.Box>

                    <Index.Box className="upload-document-row">
                      {adminDetails?.roleId?.permissions?.includes(
                        "sales_order_add"
                      ) && (
                        <>
                          <Index.Box className="admin-userlist-inner-btn-flex upload-document-btn-end">
                            <Index.Box className="admin-sub-title-main">
                              <Index.Typography
                                className="admin-sub-title"
                                component="h2"
                                variant="h2"
                              >
                                List of Attachment
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-adduser-btn-main btn-main-primary document-upload-btn-main">
                              <Index.Button
                                className="admin-adduser-btn document-upload-btn"
                                onClick={() => {
                                  handleOpenUploadDocument();
                                }}
                              >
                                <img
                                  src={PagesIndex.Svg.darkplus}
                                  className="admin-plus-icon"
                                  alt="plus"
                                />
                                <span>Upload Documents</span>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                          {docData?.map((url, index) => {
                            return (
                              <Index.Box
                                className="document-data-flex"
                                key={url?.file || url?.file?.name}
                              >
                                <Index.Box className="document-content-main">
                                  <Index.Box className="document-lable-flex">
                                    <Index.Typography className="document-id-text">
                                      {url?.name?.toUpperCase()}
                                    </Index.Typography>
                                    <Index.Typography className="document-lable-text">
                                      {url?.file?.name || url?.file}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className="document-content-main">
                                  <Index.Box className="document-delete-main">
                                    <Index.Tooltip
                                      title="Download"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => handleDownloadVideo(url)}
                                      >
                                        <img
                                          src={PagesIndex.Svg.download}
                                          className="admin-icon"
                                          alt="Download"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                    <Index.Tooltip
                                      title="Delete"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="document-delete-btn"
                                        onClick={() =>
                                          handleImageDelete(
                                            !url?.file?.name ? url.file : "",
                                            index
                                          )
                                        }
                                      >
                                        <img
                                          src={PagesIndex.Svg.trash}
                                          className="admin-icon"
                                          alt="Delete"
                                        ></img>
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            );
                          })}
                          {/* <Index.Box className="approw-person-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 4",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-add-event-input-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Approvel Person
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <Index.Autocomplete
                                        className="admin-auto-complete-control"
                                        options={adminUser || []}
                                        id="approvelPerson"
                                        closeText={""}
                                        openText={""}
                                        clearText={""}
                                        value={
                                          values?.approvelPerson
                                            ? adminUser?.find(
                                                (o) =>
                                                  o?._id ===
                                                  values?.approvelPerson
                                              ) ?? null
                                            : null
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            "approvelPerson",
                                            value?._id
                                          );
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.name
                                        }
                                        renderOption={(props, item) => (
                                          <li {...props} key={item?._id}>
                                            {item?.name}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            className="admin-form-control"
                                            name="programType"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            placeholder="Select Approvel Person"
                                          />
                                        )}
                                      />
                                      {touched.approvelPerson &&
                                        errors.approvelPerson && (
                                          <PagesIndex.FormHelperText className="admin-error-text">
                                            {errors.approvelPerson}
                                          </PagesIndex.FormHelperText>
                                        )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box> */}
                        </>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-user-btn-flex">
                <Index.Box className="border-btn-main">
                  <Index.Button
                    className="border-btn"
                    onClick={() => navigate("/admin/sales-order-list")}
                    disabled={buttonSpinner || draftButtonSpinner}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                {!id && (
                  <Index.Box className="border-btn-main">
                    <Index.Button
                      className="border-btn save-draft-btn"
                      type="submit"
                      disabled={buttonSpinner || draftButtonSpinner}
                    >
                      {draftButtonSpinner ? (
                        <PagesIndex.Spinner />
                      ) : (
                        "Save As Draft"
                      )}
                    </Index.Button>
                  </Index.Box>
                )}
                <Index.Box className="btn-main-primary">
                  <Index.Button
                    className={`${id ? "update-btn" : "save-btn"} btn-primary`}
                    type="submit"
                    disabled={buttonSpinner || draftButtonSpinner}
                    onClick={() => setFieldValue("type", "add")}
                  >
                    {buttonSpinner ? (
                      <PagesIndex.Spinner />
                    ) : (
                      <>
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>{id ? "Update Deal" : "Save Deal"}</span>
                      </>
                    )}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>

        <Index.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="admin-modal"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddModalData}
            initialValues={initialValues}
            validationSchema={PagesIndex.salesOrderModal}
            innerRef={formRefOrder}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner order-details-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {editId !== null ? "Edit" : "Add"} Order Details
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    className="admin-modal-hgt-scroll cus-scrollbar"
                    id="scrollDiv"
                  >
                    {/* <ScrollIntoView /> */}

                    <Index.Box className="grid-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-modal-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Platform<span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.Autocomplete
                                  className="admin-auto-complete-control"
                                  options={platformData || []}
                                  id="platform"
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={
                                    values?.platform
                                      ? platformData?.find(
                                          (o) =>
                                            o?.typeName === values?.platform
                                        )
                                      : values?.platform || null
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("platform", value?.typeName);
                                    setFieldValue("elements", "");
                                    // setFieldValue("elementId", "");
                                    setFieldValue("unit", "");
                                    setFieldValue("rate", "");
                                    setFieldValue("discountrate", "");
                                    setFieldValue("totalSeconds", "");
                                    setFieldValue("amount", "");
                                    setFieldValue(
                                      "schedule",
                                      scheduleData
                                        ?.filter((data) => {
                                          if (value?.typeName === "TV FCT") {
                                            return data?.type === "FCT";
                                          }
                                          if (
                                            value?.typeName === "TV Non FCT"
                                          ) {
                                            return data?.type === "NonFCT";
                                          }
                                        })
                                        .find((data) => data?.isActive)?._id
                                    );
                                    setSlotData(
                                      scheduleData
                                        ?.filter((data) => {
                                          if (value?.typeName === "TV FCT") {
                                            return data?.type === "FCT";
                                          }
                                          if (
                                            value?.typeName === "TV Non FCT"
                                          ) {
                                            return data?.type === "NonFCT";
                                          }
                                        })
                                        .find((data) => data?.isActive)?.slotId
                                        ?.slot || []
                                    );
                                  }}
                                  getOptionLabel={(option) => option?.typeName}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.typeName}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      name="platform"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                      placeholder="Select Platform"
                                    />
                                  )}
                                />
                                {touched.platform && errors.platform && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.platform}
                                  </PagesIndex.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-modal-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Element<span className="astrick-sing">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.Autocomplete
                                  className="admin-auto-complete-control"
                                  options={
                                    elements?.filter(
                                      (data) =>
                                        data?.programTypeId?.typeName ===
                                        values?.platform
                                    ) || []
                                  }
                                  id="elements"
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={
                                    values?.elements
                                      ? elements?.find(
                                          (o) => o?.name === values?.elements
                                        )
                                      : values?.elements || null
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("elements", value?.name);
                                    // setFieldValue("elementId", value?._id);
                                    setFieldValue("unit", value?.unit);
                                    setFieldValue("rate", value?.rate);
                                    setFieldValue("discountrate", value?.rate);
                                    setFieldValue("totalSeconds", "");
                                    setFieldValue("amount", "");
                                    if (
                                      value?.name === "Break" &&
                                      values?.platform === "TV FCT"
                                    ) {
                                      setFieldValue("unit", "seconds");
                                    }
                                  }}
                                  getOptionLabel={(option) => option?.name}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {`${item?.name}`}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      className="admin-form-control"
                                      name="elements"
                                      {...params}
                                      size="small"
                                      variant="outlined"
                                      placeholder="Select Element"
                                    />
                                  )}
                                />
                                {touched.elements && errors.elements && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.elements}
                                  </PagesIndex.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-modal-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Rate<span className="astrick-sing">*</span>{" "}
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.TextField
                                  name="rate"
                                  fullWidth
                                  id="rate"
                                  className="admin-form-control"
                                  placeholder="0"
                                  disabled
                                  // disabled={
                                  //   !(
                                  //     values?.elements === "Break" &&
                                  //     values?.platform === "TV FCT"
                                  //   )
                                  // }
                                  onChange={(e) => {
                                    const regex = /^\d*(\.)?(\d{0,2})?$/;
                                    if (regex.test(e.target.value)) {
                                      handleChange(e);
                                    }
                                  }}
                                  value={values?.rate}
                                  error={errors.rate && touched.rate}
                                  maxLength={8}
                                />
                                {touched.rate && errors.rate && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.rate}
                                  </PagesIndex.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-modal-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Selling Rate
                                <span className="astrick-sing">*</span>
                                <span className="option-condition-text">
                                  {values?.unit === "seconds"
                                    ? `(Per 10 seconds)`
                                    : ""}
                                </span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.TextField
                                  name="discountrate"
                                  fullWidth
                                  id="discountrate"
                                  className="admin-form-control"
                                  // placeholder="0%"
                                  placeholder="0"
                                  onChange={(e) => {
                                    const regex = /^\d*(\.)?(\d{0,2})?$/;
                                    if (regex.test(e.target.value)) {
                                      handleChange(e);
                                      if (values?.unit === "seconds") {
                                        setFieldValue(
                                          "amount",
                                          (values?.totalSeconds *
                                            e.target.value) /
                                            10
                                        );
                                      } else {
                                        setFieldValue(
                                          "amount",
                                          values?.totalSeconds * e.target.value
                                        );
                                      }
                                    }
                                  }}
                                  value={values?.discountrate}
                                />
                              </Index.Box>
                              {touched.discountrate && errors.discountrate && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.discountrate}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-modal-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Total
                                {values?.unit ? (
                                  <span className="option-condition-text">
                                    ({values?.unit})
                                  </span>
                                ) : (
                                  ""
                                )}{" "}
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.TextField
                                  name="totalSeconds"
                                  fullWidth
                                  id="totalSeconds"
                                  className="admin-form-control"
                                  placeholder="0"
                                  onChange={(e) => {
                                    const regex = /^[0-9]*$/;
                                    if (regex.test(e.target.value)) {
                                      if (
                                        values?.unit === "seconds" &&
                                        +e.target.value <=
                                          PagesIndex.dayjs(
                                            formRefMain.current.values?.endDate
                                          )
                                            .add(1, "days")
                                            .diff(
                                              PagesIndex.dayjs(
                                                formRefMain.current.values
                                                  .startDate
                                              ),
                                              "seconds"
                                            )
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          "remainingUnits",
                                          e.target.value
                                        );
                                        setFieldValue(
                                          "amount",
                                          (values?.discountrate *
                                            e.target.value) /
                                            10
                                        );
                                      } else if (
                                        (values?.unit === "days" &&
                                          +e.target.value <=
                                            PagesIndex.dayjs(
                                              formRefMain.current.values
                                                ?.endDate
                                            )
                                              .add(1, "days")
                                              .diff(
                                                PagesIndex.dayjs(
                                                  formRefMain.current.values
                                                    .startDate
                                                ),
                                                "days"
                                              )) ||
                                        values?.unit === "exposer"
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          "remainingUnits",
                                          e.target.value
                                        );
                                        setFieldValue(
                                          "amount",
                                          values?.discountrate * e.target.value
                                        );
                                      }
                                    }
                                  }}
                                  value={values?.totalSeconds}
                                  error={
                                    errors.totalSeconds && touched.totalSeconds
                                  }
                                />
                                {touched.totalSeconds &&
                                  errors.totalSeconds && (
                                    <PagesIndex.FormHelperText className="admin-error-text">
                                      {errors.totalSeconds}
                                    </PagesIndex.FormHelperText>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-modal-input-box">
                              <Index.FormHelperText className="admin-form-lable">
                                Amount
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.TextField
                                  name="amount"
                                  fullWidth
                                  id="amount"
                                  className="admin-form-control"
                                  placeholder="0"
                                  value={parseFloat(values?.amount || 0)}
                                  onChange={(e) => {
                                    const regex = /^\d*(\.)?(\d{0,2})?$/;
                                    if (regex.test(e.target.value)) {
                                      if (
                                        values?.unit === "seconds" &&
                                        +e.target.value <=
                                          PagesIndex.dayjs(
                                            formRefMain.current.values?.endDate
                                          )
                                            .add(1, "days")
                                            .diff(
                                              PagesIndex.dayjs(
                                                formRefMain.current.values
                                                  .startDate
                                              ),
                                              "seconds"
                                            )
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          "totalSeconds",
                                          Math.ceil(
                                            (e.target.value /
                                              values?.discountrate) *
                                              10
                                          )
                                        );
                                        setFieldValue(
                                          "remainingUnits",
                                          Math.ceil(
                                            (e.target.value /
                                              values?.discountrate) *
                                              10
                                          )
                                        );
                                      } else if (
                                        (values?.unit === "days" &&
                                          +(
                                            e.target.value /
                                            values?.discountrate
                                          ) <=
                                            PagesIndex.dayjs(
                                              formRefMain.current.values
                                                ?.endDate
                                            )
                                              .add(1, "days")
                                              .diff(
                                                PagesIndex.dayjs(
                                                  formRefMain.current.values
                                                    .startDate
                                                ),
                                                "days"
                                              )) ||
                                        values?.unit === "exposer"
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          "totalSeconds",
                                          Math.ceil(
                                            e.target.value /
                                              values?.discountrate
                                          )
                                        );
                                        setFieldValue(
                                          "remainingUnits",
                                          Math.ceil(
                                            e.target.value /
                                              values?.discountrate
                                          )
                                        );
                                      } else if (
                                        (values?.unit === "days" &&
                                          +(
                                            e.target.value /
                                            values?.discountrate
                                          ) >=
                                            PagesIndex.dayjs(
                                              formRefMain.current.values
                                                ?.endDate
                                            )
                                              .add(1, "days")
                                              .diff(
                                                PagesIndex.dayjs(
                                                  formRefMain.current.values
                                                    .startDate
                                                ),
                                                "days"
                                              )) ||
                                        values?.unit === "exposer"
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          "totalSeconds",
                                          PagesIndex.dayjs(
                                            formRefMain.current.values?.endDate
                                          )
                                            .add(1, "days")
                                            .diff(
                                              PagesIndex.dayjs(
                                                formRefMain.current.values
                                                  .startDate
                                              ),
                                              "days"
                                            )
                                        );
                                        setFieldValue(
                                          "remainingUnits",
                                          PagesIndex.dayjs(
                                            formRefMain.current.values?.endDate
                                          )
                                            .add(1, "days")
                                            .diff(
                                              PagesIndex.dayjs(
                                                formRefMain.current.values
                                                  .startDate
                                              ),
                                              "days"
                                            )
                                        );
                                      }
                                    }
                                  }}
                                />
                              </Index.Box>
                              {touched.amount && errors.amount && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.amount}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>

                          {/* {values?.platform === "TV FCT" ||
                          values?.platform === "TV Non FCT" ? (
                            <>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                                  <Index.FormHelperText className="admin-form-lable">
                                    Program{" "}
                                    <span className="option-condition-text">
                                      (Optional)
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="admin-form-group">
                                    <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                      <Index.Autocomplete
                                        className="admin-auto-complete-filed"
                                        options={programData || []}
                                        multiple
                                        id="program"
                                        closeText={""}
                                        openText={""}
                                        clearText={""}
                                        value={
                                          values?.program
                                            ? programData.filter((data) =>
                                                values?.program?.some(
                                                  (cat) => cat === data?._id
                                                )
                                              )
                                            : []
                                        }
                                        onChange={(e, selectedOptions) => {
                                          setFieldValue(
                                            "program",
                                            selectedOptions.map(
                                              (option) => option?._id
                                            )
                                          );
                                          const slots = [];
                                          selectedOptions.map((data) => {
                                            const start = PagesIndex.moment(
                                              data?.startTime
                                            ).set({
                                              date: 1,
                                              month: 1,
                                            });
                                            const end = PagesIndex.moment(
                                              data?.endTime
                                            ).set({
                                              date: 1,
                                              month: 1,
                                            });
                                            slotData
                                              ?.filter((item) => {
                                                const fromTime =
                                                  PagesIndex.moment(
                                                    item?.fromTime
                                                  ).set({ date: 1, month: 1 });
                                                const toTime =
                                                  PagesIndex.moment(
                                                    item?.toTime
                                                  ).set({ date: 1, month: 1 });
                                                return (
                                                  fromTime.isSameOrAfter(
                                                    start
                                                  ) &&
                                                  toTime.isSameOrAfter(start) &&
                                                  fromTime.isSameOrBefore(
                                                    end
                                                  ) &&
                                                  toTime.isSameOrBefore(end)
                                                );
                                              })
                                              .map((option) => {
                                                slots.push({
                                                  fromTime: option?.fromTime,
                                                  toTime: option.toTime,
                                                });
                                              });
                                          });
                                          setFieldValue("slots", slots);
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.programName
                                        }
                                        renderOption={(props, item) => (
                                          <li {...props} key={item?._id}>
                                            {item?.programName}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            className="admin-form-control"
                                            name="program"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            placeholder="Select Program"
                                          />
                                        )}
                                      />
                                      {touched.program && errors.program && (
                                        <PagesIndex.FormHelperText className="admin-error-text">
                                          {errors.program}
                                        </PagesIndex.FormHelperText>
                                      )}
                                    </Index.FormControl>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 8",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-input-box add-ads-input-main admin-radio-main">
                                  <Index.FormHelperText className="admin-form-lable">
                                    Date Type
                                    <span className="astrick-sing">*</span>
                                  </Index.FormHelperText>
                                  <Index.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    id="type"
                                    name="type"
                                    className="admin-radiogroup"
                                    value={values?.type}
                                    onChange={handleChange}
                                  >
                                    <Index.FormControlLabel
                                      value="dateRange"
                                      control={
                                        <Index.Radio className="admin-radio" />
                                      }
                                      label="Date Range"
                                    />
                                    <Index.FormControlLabel
                                      value="date"
                                      control={
                                        <Index.Radio className="admin-radio" />
                                      }
                                      label="Date"
                                    />
                                  </Index.RadioGroup>
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.type && touched.type
                                      ? errors.type
                                      : null}
                                  </PagesIndex.FormHelperText>
                                </Index.Box>
                              </Index.Box>

                              {values?.type === "dateRange" ? (
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 8",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-date-input-main ">
                                    <Index.FormHelperText className="admin-form-lable">
                                      <span>Start Date</span>
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <PagesIndex.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                      >
                                        <PagesIndex.DatePicker
                                          open={openCalenderStartModal}
                                          onClose={() =>
                                            setOpenCalenderStartModal(false)
                                          }
                                          className="admin-form-control admin-date-picker-control"
                                          value={values.startDate}
                                          onChange={(date) => {
                                            setFieldValue("startDate", date);
                                            setFieldValue("endDate", null);
                                          }}
                                          minDate={PagesIndex.dayjs(
                                            formRefMain?.current?.values
                                              ?.startDate
                                          )}
                                          maxDate={PagesIndex.dayjs(
                                            formRefMain?.current?.values
                                              ?.endDate
                                          )}
                                          slotProps={{
                                            popper: {
                                              className: "date-picker-body",
                                            },
                                            textField: {
                                              readOnly: true,
                                              onClick: () =>
                                                setOpenCalenderStartModal(true),
                                            },
                                          }}
                                          format="DD/MM/YYYY"
                                        />
                                      </PagesIndex.LocalizationProvider>
                                      <Index.FormHelperText className="admin-error-text">
                                        {errors.startDate &&
                                          touched.startDate &&
                                          errors.startDate}
                                      </Index.FormHelperText>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              ) : (
                                <></>
                              )}
                              {values?.type === "dateRange" ? (
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 8",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-date-input-main ">
                                    <Index.FormHelperText className="admin-form-lable">
                                      <span>End Date</span>
                                      <span className="astrick-sing">*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <PagesIndex.LocalizationProvider
                                        dateAdapter={Index.AdapterDayjs}
                                      >
                                        <PagesIndex.DatePicker
                                          open={openCalenderEndModal}
                                          onClose={() =>
                                            setOpenCalenderEndModal(false)
                                          }
                                          className="admin-form-control admin-date-picker-control"
                                          value={values.endDate} // Use '01/01/2000' as the default value
                                          onChange={(date) => {
                                            setFieldValue("endDate", date);
                                          }}
                                          minDate={
                                            values?.startDate
                                              ? PagesIndex.dayjs(
                                                  values?.startDate
                                                )
                                              : PagesIndex.dayjs(
                                                  formRefMain?.current?.values
                                                    ?.startDate
                                                )
                                          }
                                          maxDate={PagesIndex.dayjs(
                                            formRefMain?.current?.values
                                              ?.endDate
                                          )}
                                          slotProps={{
                                            popper: {
                                              className: "date-picker-body",
                                            },
                                            textField: {
                                              readOnly: true,
                                              onClick: () =>
                                                setOpenCalenderEndModal(true),
                                            },
                                          }}
                                          format="DD/MM/YYYY"
                                        />
                                      </PagesIndex.LocalizationProvider>
                                      <Index.FormHelperText className="admin-error-text">
                                        {errors.endDate &&
                                          touched.endDate &&
                                          errors.endDate}
                                      </Index.FormHelperText>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              ) : (
                                <></>
                              )}
                              {values?.type === "dateRange" ? (
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 8",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                                    <Index.FormHelperText className="admin-form-lable">
                                      Slots
                                    </Index.FormHelperText>
                                    <Index.Box className="admin-form-group">
                                      <Index.Autocomplete
                                        className="admin-auto-complete-control"
                                        options={slotData || []}
                                        id="slots"
                                        multiple
                                        disableCloseOnSelect
                                        closeText={""}
                                        openText={""}
                                        clearText={""}
                                        value={
                                          values?.slots
                                            ? slotData?.filter((data) =>
                                                values?.slots?.some(
                                                  (item) =>
                                                    item?.fromTime ===
                                                      data?.fromTime &&
                                                    item?.toTime ===
                                                      data?.toTime
                                                )
                                              )
                                            : []
                                        }
                                        onChange={(e, selectedOptions) => {
                                          setFieldValue(
                                            "slots",
                                            selectedOptions.map((option) => {
                                              return {
                                                fromTime: option?.fromTime,
                                                toTime: option.toTime,
                                              };
                                            })
                                          );
                                        }}
                                        getOptionLabel={(option) =>
                                          `${PagesIndex.moment(
                                            option?.fromTime
                                          ).format(
                                            "hh:mm:ss A"
                                          )}-${PagesIndex.moment(
                                            option?.toTime
                                          ).format("hh:mm:ss A")}`
                                        }
                                        renderOption={(props, item) => (
                                          <li {...props} key={item?._id}>
                                            {PagesIndex.moment(
                                              item?.fromTime
                                            ).format("hh:mm:ss A")}
                                            -
                                            {PagesIndex.moment(
                                              item?.toTime
                                            ).format("hh:mm:ss A")}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            fullWidth
                                            className="admin-form-control"
                                            name="slots"
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            placeholder="Select Slots"
                                          />
                                        )}
                                      />
                                      {touched.slots && errors.slots && (
                                        <PagesIndex.FormHelperText className="admin-error-text">
                                          {errors.slots}
                                        </PagesIndex.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )} */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    {values?.type === "date" ? (
                      <FieldArray name="selectedDates">
                        {({ push, remove }) => (
                          <Index.Box className="grid-row remark-row">
                            <Index.Box className="grid-main">
                              {values?.selectedDates?.length ? (
                                <Index.Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  columnGap={{
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                  }}
                                >
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 5",
                                      md: "span 5",
                                      lg: "span 4",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="event-add-flex">
                                      <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main add-event-date-input-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                          <span>Date</span>
                                          <span className="astrick-sing">
                                            *
                                          </span>
                                        </Index.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 5",
                                      md: "span 5",
                                      lg: "span 8",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="event-add-flex">
                                      <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main add-event-date-input-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                          <span>Slots</span>
                                        </Index.FormHelperText>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 12",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box
                                      display="grid"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                    >
                                      {values?.selectedDates?.map(
                                        (data, index) => {
                                          const date = `selectedDates[${index}].date`;
                                          const touchedDate = getIn(
                                            touched,
                                            date
                                          );
                                          const errorDate = getIn(errors, date);
                                          const slots = `selectedDates[${index}].slots`;
                                          const touchedSlots = getIn(
                                            touched,
                                            slots
                                          );
                                          const errorSlots = getIn(
                                            errors,
                                            slots
                                          );
                                          return (
                                            <React.Fragment key={data?._id}>
                                              <Index.Box
                                                gridColumn={{
                                                  xs: "span 12",
                                                  sm: "span 5",
                                                  md: "span 6",
                                                  lg: "span 4",
                                                }}
                                                className="grid-column"
                                              >
                                                <Index.Box className="admin-input-box admin-add-event-input-box admin-date-input-main add-event-date-input-box">
                                                  <Index.Box className="admin-form-group">
                                                    <DatePicker
                                                      className="admin-form-control admin-date-picker-control"
                                                      name={date}
                                                      value={data?.date}
                                                      multiple
                                                      plugins={[<DatePanel />]}
                                                      onChange={(e) => {
                                                        setFieldValue(date, e);
                                                      }}
                                                      minDate={
                                                        new Date(
                                                          formRefMain?.current?.values?.startDate
                                                        )
                                                      }
                                                      maxDate={
                                                        new Date(
                                                          formRefMain?.current?.values?.endDate
                                                        )
                                                      }
                                                    />
                                                    <PagesIndex.FormHelperText className="admin-error-text">
                                                      {errorDate && touchedDate
                                                        ? errorDate
                                                        : null}
                                                    </PagesIndex.FormHelperText>
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                              <Index.Box
                                                gridColumn={{
                                                  xs: "span 12",
                                                  sm: "span 6",
                                                  md: "span 6",
                                                  lg: "span 8",
                                                }}
                                                className="grid-column"
                                              >
                                                <>
                                                  <Index.Box className="event-add-flex">
                                                    <Index.Box className="admin-input-box admin-add-event-input-box input-complete-box">
                                                      <Index.Box className="admin-form-group">
                                                        <Index.Autocomplete
                                                          className="admin-auto-complete-control"
                                                          options={
                                                            slotData || []
                                                          }
                                                          id={slots}
                                                          multiple
                                                          disableCloseOnSelect
                                                          closeText={""}
                                                          openText={""}
                                                          clearText={""}
                                                          value={
                                                            data?.slots
                                                              ? slotData?.filter(
                                                                  (elem) =>
                                                                    data?.slots?.some(
                                                                      (item) =>
                                                                        item?.fromTime ===
                                                                          elem?.fromTime &&
                                                                        item?.toTime ===
                                                                          elem?.toTime
                                                                    )
                                                                )
                                                              : []
                                                          }
                                                          onChange={(
                                                            e,
                                                            selectedOptions
                                                          ) => {
                                                            setFieldValue(
                                                              slots,
                                                              selectedOptions.map(
                                                                (option) => ({
                                                                  fromTime:
                                                                    option?.fromTime,
                                                                  toTime:
                                                                    option?.toTime,
                                                                })
                                                              )
                                                            );
                                                          }}
                                                          getOptionLabel={(
                                                            option
                                                          ) =>
                                                            `${PagesIndex.moment(
                                                              option?.fromTime
                                                            ).format(
                                                              "hh:mm:ss A"
                                                            )}-${PagesIndex.moment(
                                                              option?.toTime
                                                            ).format(
                                                              "hh:mm:ss A"
                                                            )}`
                                                          }
                                                          renderOption={(
                                                            props,
                                                            item
                                                          ) => (
                                                            <li
                                                              {...props}
                                                              key={item?._id}
                                                            >
                                                              {PagesIndex.moment(
                                                                item?.fromTime
                                                              ).format(
                                                                "hh:mm:ss A"
                                                              )}
                                                              -
                                                              {PagesIndex.moment(
                                                                item?.toTime
                                                              ).format(
                                                                "hh:mm:ss A"
                                                              )}
                                                            </li>
                                                          )}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <Index.TextField
                                                              fullWidth
                                                              className="admin-form-control"
                                                              name={slots}
                                                              {...params}
                                                              size="small"
                                                              variant="outlined"
                                                              placeholder="Select Slots"
                                                            />
                                                          )}
                                                        />
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="btn-main-primary orange-btn-main add-row-flex">
                                                      {index ===
                                                      values?.selectedDates
                                                        ?.length -
                                                        1 ? (
                                                        <Index.Button
                                                          className="orange-btn add-row-btn"
                                                          onClick={() => {
                                                            push({
                                                              date: null,
                                                              slots: [],
                                                            });
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              PagesIndex.Svg
                                                                .plus
                                                            }
                                                            className="admin-incress-dicress-icon"
                                                            alt="Save"
                                                          />
                                                        </Index.Button>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {values?.selectedDates
                                                        ?.length > 1 && (
                                                        <Index.Button
                                                          className="btn-primary add-row-btn"
                                                          onClick={() =>
                                                            remove(index)
                                                          }
                                                        >
                                                          <img
                                                            src={
                                                              PagesIndex.Svg
                                                                .deleteicon
                                                            }
                                                            className="admin-incress-dicress-icon"
                                                            alt="Save"
                                                          />
                                                        </Index.Button>
                                                      )}
                                                    </Index.Box>
                                                  </Index.Box>
                                                  {touchedSlots &&
                                                    errorSlots && (
                                                      <PagesIndex.FormHelperText className="admin-error-text">
                                                        {errorSlots}
                                                      </PagesIndex.FormHelperText>
                                                    )}
                                                </>
                                              </Index.Box>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              ) : (
                                ""
                              )}
                            </Index.Box>
                          </Index.Box>
                        )}
                      </FieldArray>
                    ) : (
                      <></>
                    )}

                    <Index.Box className="grid-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-input-box admin-add-user-input">
                              <Index.FormHelperText className="admin-form-lable">
                                Remarks{" "}
                                <span className="option-condition-text">
                                  (Optional)
                                </span>
                              </Index.FormHelperText>
                              <Index.Box className="admin-form-group">
                                <Index.Box className="admin-dropdown-box">
                                  <Index.FormControl className="admin-form-control">
                                    <Index.TextField
                                      name="remarks"
                                      fullWidth
                                      id="fullWidth"
                                      className="admin-form-control"
                                      placeholder="Enter Remarks"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      value={values.remarks}
                                      inputProps={{ maxLength: 200 }}
                                    />
                                  </Index.FormControl>
                                </Index.Box>
                              </Index.Box>
                              {touched.remarks && errors.remarks && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.remarks}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="admin-discard-btn-main border-btn-main">
                        <Index.Button
                          className="admin-discard-user-btn border-btn"
                          onClick={handleClose}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-save-user-btn btn-primary"
                          type="submit"
                          onClick={() => setFieldValue("saveType", "add")}
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>
                            {editId !== null ? "Update Order" : "Save Order"}
                          </span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Modal>

        <Index.Modal
          open={openUploadDocument}
          onClose={handleCloseUploadDocument}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="admin-modal"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddDocument}
            initialValues={initialValuesDocumentUpload}
            validationSchema={PagesIndex.uploadSalesDocumentSchema}
            innerRef={formRefDoc}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Upload Document
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box
                    className="admin-modal-hgt-scroll cus-scrollbar"
                    id="scrollDiv"
                  >
                    {/* <ScrollIntoView /> */}

                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Document Type<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.Box className="admin-dropdown-box">
                          <Index.FormControl className="admin-form-control">
                            <Index.Select
                              name="name"
                              className="admin-dropdown-select "
                              value={values?.name}
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              renderValue={
                                values?.name
                                  ? undefined
                                  : () => "Select Document Type"
                              }
                            >
                              <Index.MenuItem
                                value="ro"
                                className="admin-menuitem"
                              >
                                RO
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="to"
                                className="admin-menuitem"
                              >
                                TO
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="others"
                                className="admin-menuitem"
                              >
                                Other
                              </Index.MenuItem>
                            </Index.Select>
                          </Index.FormControl>
                          {touched.name && errors.name && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.name}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Document<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      {values?.image ? (
                        <Index.Box className="file-upload-main thumbnail-box admin-file-browse-btn-main ">
                          <Index.Box className="upload-news-img-box">
                            {renderImage(values)}
                          </Index.Box>
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="admin-file-browse-btn"
                          >
                            <img
                              src={PagesIndex.Svg.whiteedit}
                              className="admin-browse-icon-img"
                              alt="upload img"
                            ></img>
                            <input
                              hidden
                              accept="image/*, .pdf"
                              id="image"
                              name="image"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue("image", e.target.files[0]);
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      ) : (
                        <Index.Box className="file-upload-main thumbnail-box">
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-button"
                          >
                            {renderImage(values)}
                            <input
                              hidden
                              accept="image/*, .pdf"
                              id="image"
                              name="image"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue("image", e.target.files[0]);
                                }
                              }}
                            />
                          </Index.Button>
                        </Index.Box>
                      )}
                      {touched.image && errors.image && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.image}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="admin-discard-btn-main border-btn-main">
                        <Index.Button
                          className="admin-discard-user-btn border-btn"
                          onClick={handleCloseUploadDocument}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className="admin-save-user-btn btn-primary"
                          type="submit"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>Upload</span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Modal>
      </>
    </Index.Box>
  );
}

const TableData = ({
  data,
  currentPage,
  setData,
  handleOpen,
  setDefaultData,
  setEditId,
  scheduleData,
  setSlotData,
}) => {
  const handleDelete = (id) => {
    const updatedData = data.filter((item, index) => index !== id);
    setData(updatedData);
  };

  return data?.length ? (
    data?.map((data, index) => {
      const startIndex = (currentPage - 1) * 10;
      const adjustedIndex = startIndex + index + 1;
      // let amount = data?.rate * (1 - (data?.discountrate / 100))
      let amount = data?.amount;
      return (
        <Index.TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          key={data?._id}
        >
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {adjustedIndex}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.platform ? data?.platform : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.elements ? data?.elements : "-"}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {data?.totalSeconds ? data?.totalSeconds : 0} ({data?.unit})
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.rate ? parseFloat(data?.rate)?.toLocaleString("en-Us") : 0}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.discountrate ? data?.discountrate : 0}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {amount ? amount?.toLocaleString("en-US") : 0}
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Box className="admin-table-data-btn-flex">
              <Index.Tooltip
                title="Edit"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() => {
                    let schData = scheduleData?.find(
                      (item) => item._id === data?.schedule
                    );
                    setSlotData(schData?.slotId?.slot);
                    setDefaultData(data);
                    handleOpen();
                    setEditId(index);
                  }}
                >
                  <img
                    src={PagesIndex.Svg.blueedit}
                    className="admin-icon"
                    alt="Edit"
                  ></img>
                </Index.Button>
              </Index.Tooltip>

              <Index.Tooltip
                title="Delete"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() => handleDelete(index)}
                >
                  <img
                    src={PagesIndex.Svg.trash}
                    className="admin-icon"
                    alt="Delete"
                  ></img>
                </Index.Button>
              </Index.Tooltip>
            </Index.Box>
          </Index.TableCell>
        </Index.TableRow>
      );
    })
  ) : (
    <PagesIndex.DataNotFound />
  );
};

import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function ViewScheduleTable() {
  const params = PagesIndex.useParams();
  const { id, page } = params;
  const location = PagesIndex.useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    PagesIndex.dayjs(state?.startDate)
  );
  const [endDate, setEndDate] = useState(
    PagesIndex.dayjs(state?.startDate).add(7, "days")
  );
  const [openCalenderStart, setOpenCalenderStart] = useState(false);
  const [openCalenderEnd, setOpenCalenderEnd] = useState(false);
  const handleGetReports = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "start",
      PagesIndex.moment(new Date(startDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append(
      "end",
      PagesIndex.moment(new Date(endDate)).format("YYYY-MM-DD")
    );
    PagesIndex.apiPostHandler(
      PagesIndex.Api.VIEW_SLOT_SCHEDULE + "/" + id,
      urlEncoded
    ).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        // setDataHeaders(res.data);
        setData(res.data);
        setFilteredData(res.data);
      } else {
        setData([]);
        setFilteredData([]);
      }
    });
  };
  useEffect(() => {
    let filteredData = data.filter((data) => {
      const search = searchValue
        ? data?.currentDate
            ?.toLowerCase()
            .includes(searchValue?.trim()?.toLowerCase()) ||
          data?.fromTime
            ?.toLowerCase()
            .includes(searchValue?.trim()?.toLowerCase()) ||
          data?.toTime
            ?.toLowerCase()
            .includes(searchValue?.trim()?.toLowerCase()) ||
          data?.adsCode
            ?.toLowerCase()
            .includes(searchValue?.trim()?.toLowerCase()) ||
          data?.clientName
            ?.toLowerCase()
            .includes(searchValue?.trim()?.toLowerCase()) ||
          data?.adsName
            ?.toLowerCase()
            .includes(searchValue?.trim()?.toLowerCase())
        : true;
      const searchDateRange =
        startDate && endDate
          ? PagesIndex.moment(
              PagesIndex.moment(data?.currentDate, "DD-MM-YYYY")
            ).isSameOrAfter(startDate) &&
            PagesIndex.moment(
              PagesIndex.moment(data?.currentDate, "DD-MM-YYYY")
            ).isSameOrBefore(endDate)
          : true;
      return search && searchDateRange;
    });
    setCurrentPage(1);
    setFilteredData(filteredData);
  }, [searchValue, startDate, endDate]);
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const generateCSV = async () => {
    let headers = [];
    if (page === "FCT") {
      headers = [
        "Date",
        "Slot Timing",
        "Priority",
        "Code",
        "Client",
        "Advertisement",
        "Element",
        "Duration",
      ];
    }
    if (page === "NonFCT") {
      headers = [
        "Date",
        "Slot Timing",
        "Priority",
        "Code",
        "Client",
        "Advertisement",
        "Element",
        "No. Of Times Play",
      ];
    }
    let modifiedData = filteredData.map((data, index) => {
      let objData = [];
      if (page === "FCT") {
        objData = [
          data?.currentDate ? data?.currentDate?.replaceAll(",", "|") : "-",
          data?.fromTime && data?.toTime
            ? `${data?.fromTime}-${data?.toTime}`
            : "-",
          data?.sequence ? data?.sequence : "-",
          data?.adsCode ? data?.adsCode?.toString()?.replaceAll(",", "|") : "-",
          data?.clientName ? data?.clientName?.replaceAll(",", "|") : "-",
          data?.adsName ? data?.adsName?.replaceAll(",", "|") : "-",
          data?.element ? data?.element?.replaceAll(",", "|") : "-",
          data?.adsSeconds
            ? data?.adsSeconds?.toString()?.replaceAll(",", "|")
            : "-",
        ];
      }
      if (page === "NonFCT") {
        objData = [
          data?.currentDate ? data?.currentDate?.replaceAll(",", "|") : "-",
          data?.fromTime && data?.toTime
            ? `${data?.fromTime}-${data?.toTime}`
            : "-",
          data?.sequence ? data?.sequence : "-",
          data?.adsCode ? data?.adsCode?.toString()?.replaceAll(",", "|") : "-",
          data?.clientName ? data?.clientName?.replaceAll(",", "|") : "-",
          data?.adsName ? data?.adsName?.replaceAll(",", "|") : "-",
          data?.element ? data?.element?.replaceAll(",", "|") : "-",
          data?.noOfTimesPlay
            ? data?.noOfTimesPlay?.toString()?.replaceAll(",", "|")
            : "-",
        ];
      }
      return objData.join(",");
    });

    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format(
        "DD-MM-YYYY hh:mm:ss A"
      )}_Slot_schedule_list.csv`,
      "text/csv"
    );
  };
  const renderTableRows = () => {
    return filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.currentDate || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.fromTime && data?.toTime
                  ? `${data?.fromTime}-${data?.toTime}`
                  : "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.sequence || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.adsCode || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.clientName || "-"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.clientName || "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.adsName || "-"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.adsName || "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title={data?.element || "-"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Typography className="admin-table-data-text">
                  {data?.element || "-"}
                </Index.Typography>
              </Index.Tooltip>
            </Index.TableCell>
            {page === "FCT" && (
              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Typography className="admin-table-data-text">
                  {data?.adsSeconds || "-"}
                </Index.Typography>
              </Index.TableCell>
            )}
            {page === "NonFCT" && (
              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Typography className="admin-table-data-text">
                  {data?.noOfTimesPlay || "-"}
                </Index.Typography>
              </Index.TableCell>
            )}
          </Index.TableRow>
        );
      });
  };

  const dataNotFound = () => {
    return <PagesIndex.DataNotFound />;
  };
  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return dataNotFound();
    }
  };
  useEffect(() => {
    if (startDate && endDate) {
      handleGetReports();
    }
  }, [startDate, endDate]);
  return (
    <Index.Box className="admin-dashboard-content admin-user-list-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {page === "FCT" ? "FCT" : "Non FCT"} Slot Schedule List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-modal-input-box admin-date-input-main">
            <Index.Box className="admin-form-group" id="startDate">
              <PagesIndex.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                <PagesIndex.DatePicker
                  open={openCalenderStart}
                  onClose={() => setOpenCalenderStart(false)}
                  className="admin-form-control admin-date-picker-control"
                  value={startDate}
                  minDate={PagesIndex.dayjs(state?.startDate)}
                  maxDate={PagesIndex.dayjs(state?.endDate)}
                  onChange={(date) => {
                    setStartDate(PagesIndex.moment(new Date(date)));
                    setEndDate(null);
                  }}
                  slotProps={{
                    popper: {
                      className: "date-picker-body",
                    },
                    textField: {
                      readOnly: true,
                      onClick: () => setOpenCalenderStart(true),
                    },
                    inputAdornment: {
                      onClick: () => setOpenCalenderStart(true), // This triggers on the calendar icon
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </PagesIndex.LocalizationProvider>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-modal-input-box admin-date-input-main">
            <Index.Box className="admin-form-group" id="startDate">
              <PagesIndex.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                <PagesIndex.DatePicker
                  open={openCalenderEnd}
                  onClose={() => setOpenCalenderEnd(false)}
                  className="admin-form-control admin-date-picker-control"
                  value={endDate}
                  minDate={
                    startDate
                      ? PagesIndex.dayjs(startDate).add(0, "day")
                      : PagesIndex.dayjs(state?.startDate)
                  }
                  maxDate={PagesIndex.dayjs(state?.endDate)}
                  onChange={(date) => {
                    setEndDate(PagesIndex.moment(new Date(date)));
                  }}
                  slotProps={{
                    popper: {
                      className: "date-picker-body",
                    },
                    textField: {
                      readOnly: true,
                      onClick: () => setOpenCalenderEnd(true),
                    },
                    inputAdornment: {
                      onClick: () => setOpenCalenderEnd(true), // This triggers on the calendar icon
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </PagesIndex.LocalizationProvider>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-search-main">
            <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {filteredData?.length > 0 && (
            <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={generateCSV}
              >
                <img
                  src={PagesIndex.Svg.exporticon}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box className={`page-table-main`}>
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            {loading ? (
              <PagesIndex.Loader />
            ) : (
              <Index.Table aria-label="simple table" className="table">
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="8%"
                    >
                      Date
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Slot Timing
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="3%"
                    >
                      Priority
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="8%"
                    >
                      Code
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Client
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Advertisement
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                    >
                      Element
                    </Index.TableCell>
                    {page === "FCT" && (
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="2%"
                      >
                        Duration
                      </Index.TableCell>
                    )}
                    {page === "NonFCT" && (
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="2%"
                      >
                        No. Of Times Play
                      </Index.TableCell>
                    )}
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {renderContent()}
                </Index.TableBody>
              </Index.Table>
            )}
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="admin-pagination-main">
          {filteredData?.length ? (
            <Index.Pagination
              page={currentPage}
              count={Math.ceil(filteredData?.length / 10)}
              onChange={(event, newPage) => setCurrentPage(newPage)}
              variant="outlined"
              shape="rounded"
              className="admin-pagination"
            />
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
